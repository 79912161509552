
@input_pad: 10px;
@input_pad_vert: @input_pad * 2;
@input_height: 48px;

@line_height_pad: 20px;
@default_list_top_pad: 10px;
@default_list_left_pad: 20px;
@blocks_hor_pad: 20px;
@cont_sidebar_pad: 40px;
@cont_vert_pad: 80px;

@bdrs_width: 4px;

@but_bdrs_width: 25px;
@but_bd_width: 0;

@col_mar: 1.7%;

@hover_opacity: 0.8;

@resp_blocks_pad: 65.1%;

@preloader_blog_default_pad: 65.1%;
@preloader_blog_masonry_pad: 100%;
@preloader_blog_timeline_pad: 65.1%;
@preloader_open_post_pad: 48.2%;
@preloader_portfolio_grid_pad: 100%;
@preloader_open_project_pad: 55.8%;
@preloader_posts_slider_pad: 100%;
@preloader_archives_pad: 100%;
@preloader_profiles_placeholder_pad: 100%;

@sidebar_width: 28.5%;
@content_width: 100% - @sidebar_width;

@mid_nav_dropdown_width: 184px;
@mid_nav_dropdown_pad_top: 10px;
@mid_nav_dropdown_pad_bot: 20px;
@mid_nav_dropdown_vert_mar_start: 20px;
@mid_nav_dropdown_vert_mar_end: 10px;
@mid_nav_dropdown_hor_mar_start: 20px;
@mid_nav_dropdown_hor_mar_end: 2px;

@bot_nav_dropdown_width: 184px;
@bot_nav_dropdown_pad_top: 10px;
@bot_nav_dropdown_pad_bot: 20px;
@bot_nav_dropdown_vert_mar_start: 20px;
@bot_nav_dropdown_vert_mar_end: 0px;
@bot_nav_dropdown_hor_mar_start: 20px;
@bot_nav_dropdown_hor_mar_end: 2px;

@top_nav_dropdown_width: 126px;
@top_nav_dropdown_pad_top: 5px;
@top_nav_dropdown_pad_bot: 10px;
@top_nav_dropdown_vert_mar_start: 20px;
@top_nav_dropdown_vert_mar_end: 0px;
@top_nav_dropdown_hor_mar_start: 20px;
@top_nav_dropdown_hor_mar_end: 2px;

@cont_width_def: 980px;
@cont_width_large: 1200px;
@cont_width_full: 100%;
@cont_width_min: 320px;

@input_text: e('input:not([type=button]):not([type=checkbox]):not([type=file]):not([type=hidden]):not([type=image]):not([type=radio]):not([type=reset]):not([type=submit]):not([type=color]):not([type=range])');

@monitor_large: ~'(min-width: 1440px)';
@monitor_medium_large: ~'(min-width: 1025px) and (max-width: 1440px)';
@monitor_medium: ~'(min-width: 1025px)';
@monitor_tablet: ~'(max-width: 1024px)';
@monitor_tablet_950: ~'(max-width: 950px)';
@monitor_tablet_small: ~'(max-width: 768px)';
@monitor_tablet_600: ~'(max-width: 600px)';
@monitor_phone: ~'(max-width: 540px)';
@monitor_phone_small: ~'(max-width: 320px)';

.fl () {
	float:left;
}

.fr () {
	float:right;
}

.dn () {
	display:none;
}

.tal () {
	text-align:left;
}

.tac () {
	text-align:center;
}

.tar () {
	text-align:right;
}

.cl () {
	clear:both;
}

.ovh () {
	overflow:hidden;
}

.m0a () {
	margin:0 auto;
}

.bd (@w:1px; @t:solid) {
	border-width:@w;
	border-style:@t;
}

.bdt (@w:1px; @t:solid) {
	border-top-width:@w;
	border-top-style:@t;
}

.bdb (@w:1px; @t:solid) {
	border-bottom-width:@w;
	border-bottom-style:@t;
}

.bdr (@w:1px; @t:solid) {
	border-right-width:@w;
	border-right-style:@t;
}

.bdl (@w:1px; @t:solid) {
	border-left-width:@w;
	border-left-style:@t;
}

.color_white_opacity (@op:.5) {
	color:rgba(255, 255, 255, @op);
}

.bg_white_opacity (@op:.5) {
	background-color:rgba(255, 255, 255, @op);
}

.fullwidth_in_block () {
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
}

.op (@op:.5) {
	opacity:@op;
}

.display_flex () {
	display:-webkit-flex;
	display:-moz-flex;
	display:-ms-flex;
	display:flex;
}

.display_inline_flex () {
	display:-webkit-inline-flex;
	display:-moz-inline-flex;
	display:-ms-inline-flex;
	display:inline-flex;
}

.flex_direction (@d:row) {
	-webkit-flex-direction:@d;
	-moz-flex-direction:@d;
	-ms-flex-direction:@d;
	flex-direction:@d;
}

.flex_wrap (@d:nowrap) {
	-webkit-flex-wrap:@d;
	-moz-flex-wrap:@d;
	-ms-flex-wrap:@d;
	flex-wrap:@d;
}

.justify_content (@d:flex-start) {
	-webkit-justify-content:@d;
	-moz-justify-content:@d;
	-ms-justify-content:@d;
	justify-content:@d;
}

.align_items (@d:stretch) {
	-webkit-align-items:@d;
	-moz-align-items:@d;
	-ms-align-items:@d;
	align-items:@d;
}

.align_content (@d:stretch) {
	-webkit-align-content:@d;
	-moz-align-content:@d;
	-ms-align-content:@d;
	align-content:@d;
}

.cl_after () {
	content:'.';
	display:block;
	clear:both;
	height:0;
	visibility:hidden;
	overflow:hidden;
}

.valign_after () {
	content:'';
	display:inline-block;
	vertical-align:middle;
	height:100%;
}

.pos_abs (@w:1em; @h:1em; @l:0; @r:0; @t:0; @b:0) {
	width:@w;
	height:@h;
	margin:auto !important;
	position:absolute;
	left:@l;
	right:@r;
	top:@t;
	bottom:@b;
}

.bdbx () {
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}

.ctbx () {
	-webkit-box-sizing:content-box;
	-moz-box-sizing:content-box;
	box-sizing:content-box;
}

.bdrs (@radius:@bdrs_width) {
	-webkit-border-radius:@radius;
	border-radius:@radius;
}

.bxsh (@x:0; @y:0; @blur:5px; @spred:0; @alpha:.05) {
	-webkit-box-shadow:@x @y @blur @spred rgba(0, 0, 0, @alpha);
	box-shadow:@x @y @blur @spred rgba(0, 0, 0, @alpha);
}

.bxsh_white (@x:0; @y:0; @blur:5px; @spred:0; @alpha:.05) {
	-webkit-box-shadow:@x @y @blur @spred rgba(255, 255, 255, @alpha);
	box-shadow:@x @y @blur @spred rgba(255, 255, 255, @alpha);
}

.bxshi (@x:0; @y:0; @blur:5px; @spred:0; @alpha:.05) {
	-webkit-box-shadow:inset @x @y @blur @spred rgba(0, 0, 0, @alpha);
	box-shadow:inset @x @y @blur @spred rgba(0, 0, 0, @alpha);
}

.bxshi_white (@x:0; @y:0; @blur:5px; @spred:0; @alpha:.05) {
	-webkit-box-shadow:inset @x @y @blur @spred rgba(255, 255, 255, @alpha);
	box-shadow:inset @x @y @blur @spred rgba(255, 255, 255, @alpha);
}

.bxsh_none () {
	-webkit-box-shadow:none;
	box-shadow:none;
}

.trans(@trans:0) when not (@trans = 0) {
	-webkit-transition:@trans;
	-moz-transition:@trans;
	-ms-transition:@trans;
	-o-transition:@trans;
	transition:@trans;
}

.arrow_top (@h:5px; @w:5px) {
	border-top:0 solid transparent !important;
	border-left:@w solid transparent !important;
	border-right:@w solid transparent !important;
	border-bottom-width:@h;
	border-bottom-style:solid;
}

.arrow_bottom (@h:5px; @w:5px) {
	border-bottom:0 solid transparent !important;
	border-left:@w solid transparent !important;
	border-right:@w solid transparent !important;
	border-top-width:@h;
	border-top-style:solid;
}

.arrow_left (@h:5px; @w:5px) {
	border-left:0 solid transparent !important;
	border-top:@w solid transparent !important;
	border-bottom:@w solid transparent !important;
	border-right-width:@h;
	border-right-style:solid;
}

.arrow_right (@h:5px; @w:5px) {
	border-right:0 solid transparent !important;
	border-top:@w solid transparent !important;
	border-bottom:@w solid transparent !important;
	border-left-width:@h;
	border-left-style:solid;
}

.arrow_top_left (@h:5px; @w:5px) {
	border-right:@w solid transparent !important;
	border-left:0 solid transparent !important;
	border-bottom:0 solid transparent !important;
	border-top-width:@h;
	border-top-style:solid;
}

.arrow_top_right (@h:5px; @w:5px) {
	border-bottom:@h solid transparent !important;
	border-top:0 solid transparent !important;
	border-left:0 solid transparent !important;
	border-right-width:@w;
	border-right-style:solid;
}

.arrow_bottom_left (@h:5px; @w:5px) {
	border-top:@h solid transparent !important;
	border-right:0 solid transparent !important;
	border-bottom:0 solid transparent !important;
	border-left-width:@w;
	border-left-style:solid;
}

.arrow_bottom_right (@h:5px; @w:5px) {
	border-left:@w solid transparent !important;
	border-top:0 solid transparent !important;
	border-right:0 solid transparent !important;
	border-bottom-width:@h;
	border-bottom-style:solid;
}

.val () {
	vertical-align:middle;
}

.default_list (@w:0; @h:0; @m:0; @bdrs:0) {
	list-style-type:none;
	
	li {
		display:block;
		position:relative;
		
		&:before {
			content:'\eb22';
			font-family:'fontello';
			font-style:normal;
			font-weight:normal;
			speak:none;
			display:inline-block;
			text-decoration:inherit;
			width:1em;
			padding:0;
			margin:0;
			text-align:center;
			font-variant:normal;
			text-transform:none;
			line-height:1em;
			position:absolute;
			top:13px;
			left:-18px;
		}
		
		&:first-child {
			&:before {
				top:3px;
			}
		}
		
		li {
			&:first-child {
				&:before {
					top:13px;
				}
			}
		}
	}
}

.default_blockquote () {
	padding-left:80px;
	padding-right:0;
	position:relative;
	quotes:none;
	margin:0 0 @line_height_pad; /* don't touch */
	
	&:before,
	&:after {
		content:none;
	}

	&::before {
		content:'\201c';
		font-size:120px;
		line-height:130px;
		position:absolute;
		left:25px;
		top:0;
	}
	
	p:last-of-type {
		margin:0;
		padding:0;
	}
	
	cite {
		display:block;
	}
}

.default_table () {
	border-collapse:collapse;
	border-spacing:0;
	width:100%;
	margin-bottom:@line_height_pad; /* don't touch */
	
	caption {
		text-align:center;
		padding:20px;
	}

	td,
	th {
		.bd(1px);
		padding:10px;
	}
	
	/* for gutenberg start */
	&.is-style-stripes {
		border-collapse:collapse;
		border-spacing:0;
		
		tr:nth-child(odd) {
			background-color:transparent !important;
		}
	}
}

.default_button () {
	display:inline-block;
	padding:0 33px;
	position:relative;
	.tac;
	.bd(@but_bd_width);
	.bdrs(@but_bdrs_width);
}

.default_select (@plugin:0) {
	line-height:1em;
	text-indent:0.01px;
	text-overflow:'';
	min-width:100px;
	max-width:100%;
	background-position:100% center;
	background-repeat:no-repeat;
	min-width:100px;
	height:@input_height;
	.bd;
	.bdrs(@bdrs_width);
}

