/**
 * @package 	WordPress
 * @subpackage 	Payday Loans
 * @version 	1.0.0
 * 
 * Design CSS Rules
 * Created by CMSMasters
 * 
 */

/*-------------------------------------------------------------------------

	1.	Globally Applied Styles / body
		1.1. Instagram / #sbi_load
		1.2. Icons
			1.2.1. Global
			1.2.2. Arrows
			1.2.3. Widget Add Info
			1.2.4. Twitter stripe
		1.3. Elements
			1.3.1. Icon Arrow Global Styles
			1.3.2. Pixel Arrow Global Styles
			1.3.3. Post Password Form / .post-password-form
			1.3.4. Prelodaer / .preloader
			1.3.5. Resizable Block / .resizable_block
			1.3.6. Img Wrapper / .cmsmasters_img_wrap
			1.3.7. Img Rollover / .cmsmasters_img_rollover_wrap
			1.3.8. Placeholder Small / .img_placeholder_small
			1.3.9. Video Wrapper / .mejs-container
			1.3.10. Search Bar / .search_bar_wrap
			1.3.10. Social Icons / .social_wrap
			1.3.11. Core Owl Carousel CSS v1.3.2 / .owl-carousel
			1.3.12. Slide Top Button / #slide_top
			1.3.13. Opened Article / .opened-article
			1.3.14. Likes and Comments
				1.3.14.1. Likes / .cmsmasters_likes
				1.3.14.2. Comments / .cmsmasters_comments
			1.3.15. Button
			1.3.16. Items Filter / .cmsmasters_items_filter_wrap
			1.3.17. More Items Loader and Pagination
				1.3.17.1. More Items Loader / .cmsmasters_wrap_more_items
				1.3.17.2. Subpage Pagination / .subpage_nav
				1.3.17.2. Pagination / .cmsmasters_wrap_pagination
			1.3.18. Post Navigation / .post_nav
			1.3.19. Share Posts / .share_posts
			1.3.20. About Author / .about_author
			1.3.21. Single Post Slider / .cmsmasters_single_slider
			1.3.22. Single Pings List / .cmsmasters_pings_list
			1.3.23. Post Comments / .post_comments
			1.3.24. Comment Respond / .comment-respond
	2.	Header / #header
		2.1. Header Top / .header_top
			2.1.1. Header Top Navigation / .top_line_nav
		2.2. Header Mid / .header_mid
			2.1.1. Header Mid Navigation / .mid_nav
		2.3. Header Bot / .header_bot
			2.1.1. Header Bot Navigation / .bot_nav
		2.4. Header Search Form
	3.	Headline Styles
	4.	Bottom + Footer Styles
		4.1. Bottom / #bottom
		4.2. Footer / #footer
			4.2.1. Footer Default / .cmsmasters_footer_default
			4.2.2. Footer Small / .cmsmasters_footer_small
	5.	Blog / .blog
		5.1. Blog Default / .cmsmasters_post_default
		5.2. Blog Masonry / .cmsmasters_post_masonry
		5.3. Blog Timeline / .cmsmasters_post_timeline
		5.4. Blog Post / .cmsmasters_open_post
	6.	Portfolio / .portfolio
		6.1. Portfolio Grid / .cmsmasters_project_grid
		6.2. Portfolio Puzzle / .cmsmasters_project_puzzle
		6.3. Portfolio Open Project / .cmsmasters_open_project
	7.	Posts Slider Styles / .cmsmasters_posts_slider
		7.1. Posts Slider Blog / .cmsmasters_slider_post
		7.2. Posts Slider Portfolio / .cmsmasters_slider_project
	8.	Profiles + Profile Styles / .cmsmasters_profile
		8.1. Profiles Horizontal / .cmsmasters_profile_horizontal
		8.2. Profiles Vertical / .cmsmasters_profile_vertical
		8.3. Profiles Open Profile / .cmsmasters_open_profile
	9.	Shortcodes Styles
		9.1. Divider / .cmsmasters_divider
		9.2. Special Heading / .cmsmasters_heading_wrap
		9.3. Featured Block / .cmsmasters_featured_block
		9.4. Table / .cmsmasters_table
		9.5. Notice / .cmsmasters_notice
		9.6. Icon / .cmsmasters_icon_wrap
		9.7. Icon List / .cmsmasters_icon_list_items
		9.8. Icon Box / .cmsmasters_icon_box
		9.9. Toggles / .cmsmasters_toggles
		9.10. Tabs / .cmsmasters_tabs
		9.11. Stats / .cmsmasters_stats
		9.12. Counters / .cmsmasters_counters
		9.13. CMSMASTERS Button / .cmsmasters_button
		9.14. Contact Form 7 / .wpcf7-form
		9.15. Hover slider / .cmsmasters_hover_slider
		9.16. Content slider / .cmsmasters_content_slider
		9.17. Gallery / .cmsmasters_gallery
		9.18. Quotes / .cmsmasters_quotes_slider
		9.19. Pricing tables / .cmsmasters_pricing_table
		9.20. Google Maps / .google_map
		9.21. Caption / .cmsmasters_img
		9.22. WP Gallery / .gallery
		9.23. Sidebar / .cmsmasters_sidebar
		9.24. Share / .share_wrap
		9.25. Twitter Stripe / .cmsmasters_twitter_wrap
		9.26. Dropcap / .cmsmasters_dropcap
		9.27. Clients / .cmsmasters_clients_slider
	10.	Widgets Styles / .widget
		10.1. Widget Advertisement / .widget_custom_advertisement_entries
		10.2. Widget Lists
			10.2.1. Widget Pages / .widget_pages
			10.2.2. Widget Categories / .widget_categories
			10.2.3. Widget Archive / .widget_archive
			10.2.4. Widget Meta / .widget_meta
			10.2.5. Widget Recent Comments / .widget_recent_comments
			10.2.6. Widget Recent Entries / .widget_recent_entries
		10.3. Widget Calendar / #wp-calendar
		10.4. Widget Contact Form / .widget_custom_contact_form_entries
		10.5. Widget Contact Info / .widget_custom_contact_info_entries
		10.6. Widget Custom Menu / .widget_nav_menu
		10.7. Widget Facebook / .widget_custom_facebook_entries
		10.8. Widget Flickr / .widget_custom_flickr_entries
		10.9. Widget Latest and Popular Projects
			10.9.1. Widget Latest Projects / .widget_custom_popular_projects_entries
			10.9.2. Widget Popular Projects / .widget_custom_latest_projects_entries
		10.10. Widget Posts Tabs / .widget_custom_posts_tabs_entries
		10.11. Widget RSS / .widget_rss
		10.12. Widget Tag Cloud / .widget_tag_cloud
		10.13. Widget Twitter / .widget_custom_twitter_entries
	11. Search + Archives + Sitemap + Error + Other Styles
		11.1. Archives + Search
			11.1.1. Archives / .cmsmasters_archive
			11.1.2. Search / .cmsmasters_search
		11.2. Sitemap / .cmsmasters_sitemap_wrap
		11.3. Error / .error
		11.4. Attachment Page / .cmsmasters_attach_img
		11.5. Other
	12.	Contact Forms Styles
		12.1. Form Builder / .cmsmasters-form-builder
		12.2. Contact Form 7 / .wpcf7
	13.	CSS3 Animations / .csstransitions
		13.1. Custom Animations
		13.2. Global Animations

-------------------------------------------------------------------------*/

@import "general.less";

/*-------------------------------------------------------------------------*/
/*	Globally Applied Styles */
/*-------------------------------------------------------------------------*/

body {
	text-align:left;
}

div {
	text-align:left;
}

p {
	padding:0 0 @line_height_pad;
	margin:0;
}

h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
	padding:0;
	margin:0 0 @line_height_pad;
}

h1 a, 
h2 a, 
h3 a, 
h4 a, 
h5 a, 
h6 a {
	font-family:inherit;
	font-size:inherit;
	font-weight:inherit;
	font-style:inherit;
	line-height:inherit;
}

ul,
ol {
	list-style-position:inside;
	margin-bottom:@line_height_pad;
}

ul {
	.default_list();
}

ol {
	list-style-type:decimal-leading-zero;
}

ul li,
ol li {
	padding:@default_list_top_pad 0 0;
}

ul li:first-child,
ol li:first-child {
  padding-top: 0;
}

ul ul,
ol ul,
ul ol,
ol ol {
	margin:0 0 0 @default_list_left_pad;
	padding:@default_list_top_pad 0 0;
}

.esg-grid {
	ul {
		padding:0;
		margin:0;
		
		li {
			&:before {
				display:none;
			}
		}
	}
}

iframe {
	margin-bottom:@line_height_pad;
}

address {
	margin:0 0 @line_height_pad;
}

pre {
	margin:0 0 @line_height_pad;
}

dd {
	margin:0 0 @line_height_pad;
}

blockquote {
	.default_blockquote();
}

table {
	.default_table();
}

@{input_text},
textarea,
select,
option {
	padding:@input_pad @input_pad_vert;
	max-width:100%;
	.bd(0);
	
}

@{input_text} {
	height:@input_height;
}

@{input_text}:focus,
textarea:focus {
	outline:none;
}

input[type=submit], 
input[type=button], 
button {
	max-width:100%;
	cursor:pointer;
}

select {
	outline:none;
}

option {
	outline:none;
}

.jetpack_subscription_widget #subscribe-email @{input_text},
.widget_wysija @{input_text} {
	width:100% !important;
	padding:@input_pad @input_pad_vert !important;
}

.widget_wysija_cont .wysija-submit {
	display:inline-block !important;
	margin:0 !important;
}

.widget_wysija_cont .wysija-paragraph {
	padding:0;
	width:64%;
	.bdrs(25px 0 0 25px);
	.ovh;
	.fl;
}

.widget_wysija_cont .wysija-submit {
	width:36%;
	padding:0;
	line-height:@input_height !important;
	.bdrs(0 25px 25px 0);
}

.widget_wysija p label {
	padding:13px 20px;
	position:absolute;
}

.widget_wysija.area_opened {
	p label {
		display:none;
	}
}

.gform_wrapper @{input_text},
.gform_wrapper textarea {
	padding:@input_pad @input_pad_vert !important;
}

.gform_wrapper select {
	padding:@input_pad @input_pad_vert !important;
	margin:0 !important;
}

nav {
	display:block;
	height:100%;
	position:relative;
	
	ul { /* clear ul styles */
		margin:0;
		padding:0;
		
		&:after {
			.cl_after;
		}
		
		li {
			margin:0;
			padding:0;
			
			&:before {
				display:none;
			}
		}
	}
	
	> div {
		height:100%;
		
		&:after {
			.valign_after;
		}
	}
	
	> div > ul {
		list-style:none;
		display:inline-block;
		width:100%;
		height:100%;
		vertical-align:middle;
		position:relative;
		z-index:202;
		zoom:1;
		
		ul {
			list-style:none;
			width:@mid_nav_dropdown_width;
			margin:@mid_nav_dropdown_vert_mar_start 0 0;
			padding:@mid_nav_dropdown_pad_top 0 @mid_nav_dropdown_pad_bot;
			visibility:hidden;
			position:absolute;
			left:0;
			top:100%;
			z-index:205;
			.op(0);
			.bd;
			
			.header_top & {
				width:@top_nav_dropdown_width;
				margin:@top_nav_dropdown_vert_mar_start 0 0;
				padding:@top_nav_dropdown_pad_top 0 @top_nav_dropdown_pad_bot;
			}
			
			.header_bot & {
				width:@bot_nav_dropdown_width;
				margin:@bot_nav_dropdown_vert_mar_start 0 0;
				padding:@bot_nav_dropdown_pad_top 0 @bot_nav_dropdown_pad_bot;
			}
			
			ul {
				margin:0 0 0 @mid_nav_dropdown_hor_mar_start;
				left:100%;
				top:-(@mid_nav_dropdown_pad_top + 1);
				
				.header_top & {
					margin:0 0 0 @top_nav_dropdown_hor_mar_start;
					top:-(@top_nav_dropdown_pad_top + 1);
				}
				
				.header_bot & {
					margin:0 0 0 @bot_nav_dropdown_hor_mar_start;
					top:-(@bot_nav_dropdown_pad_top + 1);
				}
			}
		}
		
		li.menu-item-dropdown-right {
			.tar;
			
			* {
				.tar;
			}
			
			> ul {
				left:auto;
				right:0;
				
				ul {
					margin:0 @mid_nav_dropdown_hor_mar_start 0 0;
					left:auto;
					right:100%;
					
					.header_bot & {
						margin:0 @bot_nav_dropdown_hor_mar_start 0 0;
					}
				}
			}
			
			> div.menu-item-mega-container {
				left:auto;
				right:0;
				
				ul {
					right:auto;
				}
			}
		}
		
		li {
			&:hover {
				> ul {
					margin:@mid_nav_dropdown_vert_mar_end 0 0;
					visibility:visible;
					opacity:1;
					
					.header_top & {
						margin:@top_nav_dropdown_vert_mar_end 0 0;
					}
					
					.header_bot & {
						margin:@bot_nav_dropdown_vert_mar_end 0 0;
					}
					
					&:before {
						content:'';
						width:100%;
						height:@mid_nav_dropdown_vert_mar_end;
						position:absolute;
						left:0;
						top:-(@mid_nav_dropdown_vert_mar_end + 1);
						
						.header_top & {
							width:100%;
							height:@top_nav_dropdown_vert_mar_end;
							top:-(@top_nav_dropdown_vert_mar_end + 1);
						}
						
						.header_bot & {
							width:100%;
							height:@bot_nav_dropdown_vert_mar_end;
							top:-(@bot_nav_dropdown_vert_mar_end + 1);
						}
					}
				}
				
				> div.menu-item-mega-container {
					margin:0;
					visibility:visible;
					opacity:1;
					
					.header_bot & {
						margin:@bot_nav_dropdown_vert_mar_end 0 0;
					}
					
					&:before {
						content:'';
						width:100%;
						height:@mid_nav_dropdown_vert_mar_end;
						position:absolute;
						left:0;
						top:-(@mid_nav_dropdown_vert_mar_end + 1);
						
						.header_bot & {
							width:100%;
							height:@bot_nav_dropdown_vert_mar_end;
							top:-(@bot_nav_dropdown_vert_mar_end + 1);
						}
					}
					
					ul {
						visibility:visible;
						opacity:1;
					}
				}
			}
			
			li:hover {
				> ul {
					margin:0 0 0 @mid_nav_dropdown_hor_mar_end;
					
					.header_top & {
						margin:0 0 0 @top_nav_dropdown_hor_mar_end;
					}
					
					.header_bot & {
						margin:0 0 0 @bot_nav_dropdown_hor_mar_end;
					}
					
					&:before {
						width:@mid_nav_dropdown_hor_mar_end;
						height:100%;
						top:0;
						left:-(@mid_nav_dropdown_hor_mar_end + 1);
						
						.header_top & {
							width:@top_nav_dropdown_hor_mar_end;
							height:100%;
							left:-(@top_nav_dropdown_hor_mar_end + 1);
						}
						
						.header_bot & {
							width:@bot_nav_dropdown_hor_mar_end;
							height:100%;
							left:-(@bot_nav_dropdown_hor_mar_end + 1);
						}
					}
				}
			}
			
			&.menu-item-dropdown-right {
				li:hover {
					> ul {
						margin:0 @mid_nav_dropdown_hor_mar_end 0 0;
						
						.header_bot & {
							margin:0 @bot_nav_dropdown_hor_mar_end 0 0;
						}
						
						&:before {
							left:auto;
							right:-(@mid_nav_dropdown_hor_mar_end + 1);
							
							.header_bot & {
								right:-(@bot_nav_dropdown_hor_mar_end + 1);
							}
						}
					}
				}
			}
		}
		
		li {
			display:block;
			margin:0;
			padding:0;
			position:relative;
			z-index:203;
			
			&:hover {
				z-index:204;
			}
		}
		
		a {
			display:block;
			position:relative;
			z-index:204;
		}
		
		.menu-item-mega-description-container {
			display:block;
			padding:10px 15px;
			.ovh;
		}
		
		.nav_title {
			&[class^="cmsmasters-icon-"]:before, 
			&[class*=" cmsmasters-icon-"]:before {
				margin:0 .5em 0 0;
			}
		}
		
		.menu-item-hide-text {
			> a {
				.nav_title {
					&[class^="cmsmasters-icon-"]:before, 
					&[class*=" cmsmasters-icon-"]:before {
						margin:0 .3em;
					}
				}
			}
			
			&:not(.menu-item-icon) {
				> a {
					display:none;
				}
			}
		}
		
		> li {
			.fl;
			.bdr;
			
			&:last-child {
				.bdr(0);
			}
			
			li {
				.bdb;
				
				&:last-child {
					.bdb(0);
				}
			}
		}
		
		li.menu-item-mega ul {
			background-color:transparent;
			border:0;
			width:auto;
			margin:0;
			padding:0;
			position:relative;
			left:auto;
			right:auto;
			top:auto;
			bottom:auto;
			z-index:203;
		}
		
		div.menu-item-mega-container {
			display:block;
			width:auto;
			margin:@mid_nav_dropdown_vert_mar_start 0 0;
			padding:15px 5px;
			visibility:hidden;
			position:absolute;
			left:0;
			top:100%;
			z-index:205;
			.op(0);
			.bd;
			
			.header_bot & {
				margin:@bot_nav_dropdown_vert_mar_start 0 0;
			}
			
			> ul {
				display:table;
				width:100%;
				margin-left:0;
				margin-right:0;
				
				a {
					padding:9px 10px;
				}
				
				> li {
					display:table-cell;
					padding:0 5px;
					.bdb(0);
					
					> a {
						margin-bottom:0;
						padding-top:5px;
						padding-bottom:5px;
					}
					
					> ul {
						width:@mid_nav_dropdown_width;
						margin:0;
						padding:0;
						
						.header_bot & {
							width:@bot_nav_dropdown_width;
						}
					}
					
					&:hover > ul {
						margin:0;
						padding:0;
						
						.header_bot & {
							margin:0;
							padding:0;
						}
					}
					
					a:empty {
						padding:0;
						margin:0;
					}
				}
			}
		}
		
		li.menu-item-mega-fullwidth {
			> div.menu-item-mega-container > ul > li a, 
			> div.menu-item-mega-container > ul > li ul {
				width:auto;
			}
			
			&.menu-item-mega-cols-two > div.menu-item-mega-container > ul > li {
				width:50%;
			}
			
			&.menu-item-mega-cols-three > div.menu-item-mega-container > ul > li {
				width:33.33%;
			}
			
			&.menu-item-mega-cols-four > div.menu-item-mega-container > ul > li {
				width:25%;
			}
			
			&.menu-item-mega-cols-five > div.menu-item-mega-container > ul > li {
				width:20%;
			}
		}
	}
}

.full-width {
	min-width:100%;
}

audio.full-width {
	width:100%;
}

.alignleft, 
.fl {
	.fl;
}

.alignright, 
.fr {
	.fr;
}

.aligncenter {
	.tac;
}

.aligncenter,
.alignnone {
	clear:both;
}

img {
	&.alignleft, 
	&.fl {
		margin-right:1rem;
		margin-bottom:@line_height_pad;
	}
	
	&.alignright, 
	&.fr {
		margin-left:1rem;
		margin-bottom:@line_height_pad;
	}
	
	&.aligncenter {
		margin:0 auto @line_height_pad;
		display:block;
	}
	
	&.alignnone {
		margin-bottom:@line_height_pad;
	}
}

.dn {
	.dn;
}

.tal, 
.ta_left {
	.tal;
}

.tac, 
.ta_center {
	.tac;
}

.tar, 
.ta_right {
	.tar;
}

.cl, 
.clear {
	display:block;
	.cl;
}

.ovh {
	.ovh;
}

.low_case {
	text-transform:lowercase;
}

.up_case {
	text-transform:uppercase;
}

.cmsmasters_responsive_width {
	display:block;
	width:auto;
	visibility:hidden;
}

.cmsmasters_column {
	position:relative;
	min-height:1px;
}

.one_first, 
.one_half, 
.one_third, 
.two_third, 
.one_fourth, 
.three_fourth, 
.one_fifth, 
.one_sixth {
	margin:0 @col_mar;
	position:relative;
	.fl;
}

.one_sixth {
	width:round((100% / 6) - (@col_mar * 2), 2);
	
	.cmsmasters_row_no_margin & {
		width:round((100% / 6), 2);
	}
}

.one_fifth {
	width:round((100% / 5) - (@col_mar * 2), 2);
	
	.cmsmasters_row_no_margin & {
		width:round((100% / 5), 2);
	}
}

.one_fourth {
	width:round((100% / 4) - (@col_mar * 2), 2);
	
	.cmsmasters_row_no_margin & {
		width:round((100% / 4), 2);
	}
}

.one_third {
	width:round((100% / 3) - (@col_mar * 2), 2);
	
	.cmsmasters_row_no_margin & {
		width:round((100% / 3), 2);
	}
}

.one_half {
	width:round((100% / 2) - (@col_mar * 2), 2);
	
	.cmsmasters_row_no_margin & {
		width:round((100% / 2), 2);
	}
}

.two_third {
	width:round(((100% * 2) / 3) - (@col_mar * 2), 2);
	
	.cmsmasters_row_no_margin & {
		width:round(((100% * 2) / 3), 2);
	}
}

.three_fourth {
	width:round(((100% * 3) / 4) - (@col_mar * 2), 2);
	
	.cmsmasters_row_no_margin & {
		width:round(((100% * 3) / 4), 2);
	}
}

.one_first {
	width:round(100% - (@col_mar * 2), 2);
	float:none;
	position:relative;
	.cl;
	
	.cmsmasters_row_no_margin & {
		width:round(100%, 2);
	}
}

.cmsmasters_row_margin {
	&.cmsmasters_row_columns_behavior {
		.display_flex;
	
		&:after {
			content:none;
		}
		
		&.cmsmasters_131313 {
			width:99.99%;
		}
		
		.cmsmasters_column {
			.display_flex;
			
			&:after {
				content:none;
			}
			
			> div {
				width:100%;
			}
		}
	}
}

[data-animation] {
	.op(0);
}

[data-animation].animated {
	.op(1);
}

#page {
	&.cmsmasters_boxed {
		width:@cont_width_def + @blocks_hor_pad;
		margin:0 auto;
		
		#header, 
		#footer {
			width:@cont_width_def + @blocks_hor_pad;
		}
	}
}

#page, 
#main, 
#middle, 
.middle_content, 
#bottom, 
#footer, 
.headline, 
.headline_outer, 
.middle_inner, 
.cmsmasters_row, 
.cmsmasters_row_outer, 
.cmsmasters_row_outer_parent {
	width:100%;
	position:relative;
}

.post-password-form,
.header_top_inner, 
.header_mid_inner, 
.header_bot_inner, 
.content_wrap, 
.headline_inner, 
.cmsmasters_breadcrumbs, 
.bottom_outer, 
.footer_inner, 
.cmsmasters_row_inner {
	position:relative;
	width:@cont_width_def;
	padding:0 @blocks_hor_pad;
	.m0a;
}

.content_wrap .post-password-form {
	width:auto;
	padding:0;
}

.cmsmasters_row {
	&.cmsmasters_row_top_default.cmsmasters_row_bot_default {
		z-index:0;
	}
	
	&.cmsmasters_row_top_left_diagonal:before, 
	&.cmsmasters_row_bot_left_diagonal:after {
		content:'';
		background-color:inherit;
		width:150%;
		height:150px;
		position:absolute;
		left:auto;
		right:0;
		top:0;
		bottom:auto;
		z-index:1;
		-webkit-transform:rotate(2deg);
		-moz-transform:rotate(2deg);
		-ms-transform:rotate(2deg);
		transform:rotate(2deg);
		-webkit-transform-origin:100% 0;
		-moz-transform-origin:100% 0;
		-ms-transform-origin:100% 0;
		transform-origin:100% 0;
	}
	
	&.cmsmasters_row_bot_left_diagonal:after {
		top:auto;
		bottom:0;
		-webkit-transform:rotate(-2deg);
		-moz-transform:rotate(-2deg);
		-ms-transform:rotate(-2deg);
		transform:rotate(-2deg);
	}
	
	&.cmsmasters_row_top_right_diagonal:before, 
	&.cmsmasters_row_bot_right_diagonal:after {
		content:'';
		background-color:inherit;
		width:150%;
		height:150px;
		position:absolute;
		left:0;
		right:auto;
		top:0;
		bottom:auto;
		z-index:1;
		-webkit-transform:rotate(-2deg);
		-moz-transform:rotate(-2deg);
		-ms-transform:rotate(-2deg);
		transform:rotate(-2deg);
		-webkit-transform-origin:0 0;
		-moz-transform-origin:0 0;
		-ms-transform-origin:0 0;
		transform-origin:0 0;
	}
	
	&.cmsmasters_row_bot_right_diagonal:after {
		top:auto;
		bottom:0;
		-webkit-transform:rotate(2deg);
		-moz-transform:rotate(2deg);
		-ms-transform:rotate(2deg);
		transform:rotate(2deg);
	}
	
	&.cmsmasters_row_top_zigzag:before, 
	&.cmsmasters_row_bot_zigzag:after {
		content:'';
		background-size:10px 5px;
		height:5px;
		width:100%;
		position:absolute;
		left:0;
		right:0;
		top:auto;
		bottom:100%;
		z-index:1;
	}
	
	&.cmsmasters_row_bot_zigzag:after {
		top:100%;
		bottom:auto;
		-webkit-transform:rotate(180deg);
		-moz-transform:rotate(180deg);
		-ms-transform:rotate(180deg);
		transform:rotate(180deg);
	}
	
	&.cmsmasters_row_top_triangle:before, 
	&.cmsmasters_row_bot_triangle:after {
		content:'';
		background-color:inherit;
		z-index:1;
		-webkit-transform:rotate(45deg);
		-moz-transform:rotate(45deg);
		-ms-transform:rotate(45deg);
		transform:rotate(45deg);
		.pos_abs(30px, 30px, 0, 0, -15px, auto);
	}
	
	&.cmsmasters_row_bot_triangle:after {
		top:auto;
		bottom:-15px;
	}
	
	.cmsmasters_row_outer_parent {
		padding:.1px 0;
		z-index:2;
	}
	
	.cmsmasters_row_inner {
		&.cmsmasters_row_fullwidth {
			width:100%;
			padding:0 @blocks_hor_pad * 2;
		}
		
		&.cmsmasters_row_no_margin {
			.cmsmasters_row_margin {
				margin:0;
				
				.cmsmasters_column {
					margin:0;
				}
			}
		}
	}
	
	.cmsmasters_row_overlay {
		.fullwidth_in_block;
	}
}

.cmsmasters_row_margin {
	margin:0 -@col_mar - 0.07;
	position:relative;
}

.content_wrap {
	&.r_sidebar, 
	&.l_sidebar, 
	.opened-article, 
	.cmsmasters_tabs {
		.cmsmasters_row {
			.ctbx;
			
			.cmsmasters_row_inner {
				width:100%;
				padding:0;
			}
		}
	}
}

.content_wrap.cmsmasters_set_fullwidth {
	width:100%;
	padding:0;
}

#page, 
#middle, 
.middle_inner, 
.middle_content, 
#bottom, 
.bottom_bg, 
.content_wrap, 
.cmsmasters_row_inner, 
.cmsmasters_row_margin, 
.cmsmasters_column {
	&:after {
		.cl_after;
	}
}

.middle_content {
	padding:50px 0 0;
}

.middle_content:empty {
	.dn;
}

.content {
	width:@content_width;
	padding:@cont_vert_pad 0;
	position:relative;
	.fl;
	
	&.fr {
		.fr;
	}
}

.sidebar {
	width:@sidebar_width;
	padding:@cont_vert_pad 0 @cont_vert_pad (@cont_sidebar_pad + 10px);
	position:relative;
	.fr;
	
	&.fl {
		padding:@cont_vert_pad @cont_sidebar_pad @cont_vert_pad 0;
		.fl;
	}
}

.bottom_bg {
	padding-top:105px;
	padding-bottom:40px;
}

.bottom_inner {
	margin:0 -@col_mar;
	
	aside {
		position:relative;
		float:left;
		margin:0 @col_mar;
	}
}


/***************** Icons ******************/
/* Global */
.cmsmasters_theme_icon_comment:before { content: '\eae4'; }
.cmsmasters_theme_icon_like:before { content: '\eaaf'; }
.cmsmasters_theme_icon_search:before { content: '\eaac'; }
.cmsmasters_theme_icon_cancel:before { content: '\eabc'; }
.cmsmasters_theme_icon_resp_nav:before { content: '\eaba'; }
.cmsmasters_theme_icon_person:before { content: '\e813'; }
.cmsmasters_theme_icon_image:before { content: '\e831'; }
.cmsmasters_theme_icon_check:before { content: '\eabb'; }
.cmsmasters_theme_icon_sticky:before { content: '\e830'; }
.cmsmasters_theme_icon_spinner:before { content: '\e9fd'; }

/* Arrows */
.cmsmasters_theme_icon_slide_prev:before, 
.cmsmasters_prev_arrow span:before { content: '\eb25'; }
.cmsmasters_theme_icon_slide_next:before, 
.cmsmasters_next_arrow span:before { content: '\eb26'; }
.cmsmasters_theme_icon_slide_top:before { content: '\eb27'; }
.cmsmasters_theme_icon_slide_bottom:before { content: '\eb24'; }
.cmsmasters_theme_icon_resp_nav_slide_up:before { content: '\eb27'; }
.cmsmasters_theme_icon_resp_nav_slide_down:before { content: '\eb24'; }

/* Widget Add Info */
.cmsmasters_theme_icon_user_mail:before { content: '\e885'; }
.cmsmasters_theme_icon_user_website:before { content: '\e836'; }
.cmsmasters_theme_icon_user_phone:before { content: '\e805'; }
.cmsmasters_theme_icon_user_address:before { content: '\e895'; }

/* Twitter stripe */
.cmsmasters_theme_icon_user_twitter:before { content: '\ea97'; }

/* Shop */
.cmsmasters_theme_icon_basket:before { content: '\eb02'; }
.cmsmasters_theme_icon_star_empty:before { content: '\e8aa'; }
.cmsmasters_theme_icon_star_full:before { content: '\e8aa'; }

/* event */
.cmsmasters_theme_icon_date:before { content: '\e874'; }
.cmsmasters_theme_icon_time:before { content: '\e880'; }
.cmsmasters_theme_icon_venue:before { content: '\e895'; }
.cmsmasters_theme_icon_money:before { content: '\e829'; }



/***************** Elements ******************/
/* Icon Arrow Global Styles */
.cmsmasters_prev_arrow, 
.cmsmasters_next_arrow {
	display:inline-block;
	width:26px;
	height:26px;
	vertical-align:middle;
	position:relative;
	.tac;
	
	&:after {
		.valign_after;
	}
	
	span {
		display:inline-block;
		width:10px;
		height:22px;
		vertical-align:middle;
		
		&:before {
			font-family:'fontello';
			font-size:22px;
			line-height:22px;
			text-align:center;
			display:block;
			speak:none;
			-webkit-font-smoothing:antialiased;
			-moz-osx-font-smoothing:grayscale;
		}
	}
}


/* Pixel Arrow Global Styles */
.cmsmasters_prev_arrow_pixel,
.cmsmasters_next_arrow_pixel {
	background:#000000;
	.pos_abs(1px, 1px, 0, auto);

	&:before, 
	&:after, 
	span:before, 
	span:after {
		content:'';
		background:transparent;
		position:absolute;
		width:1px;
		.bdt;
		.bdb;
	}
	
	&:before {
		height:3px;
		left:1px;
		top:-1px;
	}
	
	&:after {
		height:5px;
		left:2px;
		top:-2px;
	}
	
	span:before {
		height:7px;
		left:3px;
		top:-3px;
	}
	
	span:after {
		height:9px;
		left:4px;
		top:-4px;
	}
}

.cmsmasters_next_arrow_pixel {
	right:0;
	left:auto;
	
	&:before {
		left:auto;
		right:1px;
	}
	
	&:after {
		left:auto;
		right:2px;
	}
	
	span:before {
		left:auto;
		right:3px;
	}
	
	span:after {
		left:auto;
		right:4px;
	}
}

.cmsmasters_top_arrow_pixel, 
.cmsmasters_bot_arrow_pixel {
	background:#000000;
	.pos_abs(1px, 1px, 0, 0, -4px);

	&:before, 
	&:after, 
	span:before, 
	span:after {
		content:'';
		background:transparent;
		position:absolute;
		height:1px;
		.bdl;
		.bdr;
	}

	&:before {
		width:3px;
		top:1px;
		left:-1px;
	}

	&:after {
		width:5px;
		top:2px;
		left:-2px;
	}

	span:before {
		width:7px;
		top:3px;
		left:-3px;
	}

	span:after {
		width:9px;
		top:4px;
		left:-4px;
	}
}

.cmsmasters_bot_arrow_pixel {
	top:4px;

	&:before {
		top:auto;
		bottom:1px;
	}

	&:after {
		top:auto;
		bottom:2px;
	}

	span:before {
		top:auto;
		bottom:3px;
	}

	span:after {
		top:auto;
		bottom:4px;
	}
}


/* Post Password Form */
.post-password-form {
	padding-top:40px;
	
	p:first-child {
		font-weight:bold;
	}
	
	label {
		display:inline-block;
	}
	
	input[type="password"] {
		display:block;
	}
	
	input[type=submit] {
		display:inline-block;
		vertical-align:bottom;
	}
}


/* Prelodaer */
.preloader {
	display:block;
	width:100%;
	height:0;
	padding-bottom:@resp_blocks_pad;
	position:relative;
	.tac;
	
	.full-width {
		.fullwidth_in_block;
	}
	
	&.highImg {
		height:auto;
		padding:0;
		
		.full-width {
			height:auto;
			position:relative;
			top:auto;
			left:auto;
		}
	}
}


/* Resizable Block */
.resizable_block {
	display:block;
	width:100%;
	height:0;
	padding-bottom:@resp_blocks_pad;
	position:relative;
	.ovh;
	
	.full-width {
		.fullwidth_in_block;
	}
}


/* Img Wrapper */
.cmsmasters_img_wrap {
	display:block;
	position:relative;
	.ovh;

	.img_placeholder {
		width:100%;
		height:100%;
		position:absolute;
		left:0;
		top:0;
		
		&:before {
			font-size:40px;
			.pos_abs;
		}
	}
	
	.highImg {
		.img_placeholder {
			display:block;
			padding-bottom:@resp_blocks_pad;
			position:relative;
		}
	}
}


/* Img Rollover */
.cmsmasters_img_rollover_wrap {
	display:block;
	position:relative;
	.ovh;

	.img_placeholder {
		width:100%;
		height:100%;
		position:absolute;
		left:0;
		top:0;
		
		&:before {
			font-size:40px;
			.pos_abs;
		}
	}
	
	&.highImg {
		.img_placeholder {
			display:block;
			padding-bottom:@resp_blocks_pad;
			position:relative;
		}
	}
	
	.cmsmasters_img_rollover {
		width:100%;
		height:100%;
		position:absolute;
		left:0;
		top:0;
		.tac;
		.op(0);
		
		&:after {
			.valign_after;
		}
		
		.cmsmasters_open_post_link {
			position:relative;
			display:inline-block;
			width:34px;
			height:34px;
			.val;
			
			> span {
				display:block;
				width:100%;
				height:18px;
				position:absolute;
				
				&:before, 
				&:after {
					content:'';
					position:absolute;
					display:inline-block; 
				}
				
				&.top_angle {
					left:-18px;
					top:0;
					
					&:before {
						width:2px;
						height:18px;
						left:50%;
						top:0;
						margin-left:-1px;
					}
					
					&:after {
						height:2px;
						width:18px;
						bottom:0;
						left:0;
					}
				}
				
				&.bot_angle {
					right:-18px;
					bottom:0;
					
					&:before {
						width:2px;
						height:18px;
						left:50%;
						bottom:0;
						margin-left:-1px;
					}
					
					&:after {
						height:2px;
						width:18px;
						top:0;
						right:0;
					}
				}
			}
		}
		
		.cmsmasters_open_link, 
		.cmsmasters_image_link {
			display:block;
			width:100%;
			height:100%;
			position:absolute;
			left:0;
			top:0;
		}
	}
	
	&:hover {
		.cmsmasters_img_rollover {
			.op(1);
			
			.cmsmasters_open_post_link {
				.top_angle {
					left:0;
				}
				
				.bot_angle {
					right:0;
				}
			}
		}
	}
}


/* Placeholder Small */
.img_placeholder_small {
	height:60px;
	width:60px;
	display:block;
	position:relative;
	
	&:before {
		font-size:18px;
		.pos_abs;
	}
}


/* Video Wrapper */
.wp-video-shortcode, 
.mejs-layer {
	max-width:100%;
}

.mejs-container {
	&.wp-video-shortcode, 
	&.wp-audio-shortcode {
		max-width:100%;
	}
}

.cmsmasters_video_wrap {
	width:100%;
	max-width:100%;
	height:0;
	padding-bottom:@resp_blocks_pad;
	overflow:hidden;
	position:relative;
	
	> div {
		width:100% !important;
		height:100%;
		position:absolute;
		left:0;
		top:0;
	}
	
	iframe {
		width:100%;
		max-width:auto;
		height:100%;
		margin-bottom:0;
		position:absolute;
		left:0;
		top:0;
	}
	
	.wp-video-shortcode, 
	.mejs-layer {
		width:100% !important;
		height:100% !important;
		position:absolute;
		left:0;
		top:0;
		
		.mejs-overlay-button {
			margin-top:-50px !important;
		}
	}
}


/* Search Bar */
.search_bar_wrap {
	display:inline-block;
	width:100%;
	position:relative;
	
	.search_field {
		padding:0;
		margin:0;
		.ovh;
		
		input {
			width:100%;
			
			#middle &, 
			#bottom & {
				padding-left:20px;
				padding-right:48px;
			}
		}
	}
	
	.search_button {
		padding:0;
		margin:0;
		.pos_abs(50px, 100%, auto, 0);
		.ovh;
		
		button {
			background:none !important;
			display:block;
			width:100%;
			height:100%;
			padding:0;
			margin:0;
			cursor:pointer;
			position:relative;
			.bdrs(0);
			.bd(0);
			.bxsh_none;
			
			&:before {
				font-size:18px;
				.pos_abs;
			}
		}
	}
}


/* Social Icons */
.social_wrap {
	display:block;
	height:100%;
	position:relative;
	.ovh;
	
	.social_wrap_inner {
		height:100%;
		
		&:after {
			.valign_after;
		}
	}
	
	ul {
		list-style:none;
		display:inline-block;
		margin:0;
		padding:0;
		vertical-align:middle;
	}
	
	li {
		display:inline-block;
		margin:0 5px 0 0;
		padding:0;
		position:relative;
		vertical-align:middle;
		
		&:last-child {
			margin:0;
		}
		
		&:before {
			display:none;
		}
	}
	
	a {
		display:block;
		width:30px;
		height:30px;
		position:relative;
		.bdrs(50%);
		
		&:before {
			.pos_abs;
		}
	}
}


/* Core Owl Carousel CSS v1.3.2 */
.cmsmasters_owl_slider:not(.owl-carousel) {
	> div {
		width:0;
		height:0;
		visibility:hidden;
	}
}

.owl-carousel {
	width:100%;
	position:relative;
	-ms-touch-action:pan-y;
	.dn;
	
	.owl-wrapper-outer {
		width:100%;
		position:relative;
		.ovh;
	
		&.autoHeight {
			-webkit-transition:height .5s ease-in-out;
			-moz-transition:height .5s ease-in-out;
			-ms-transition:height .5s ease-in-out;
			-o-transition:height .5s ease-in-out;
			transition:height .5s ease-in-out;
		}
	
		.owl-wrapper {
			position:relative;
			.dn;
			
			&:after {
				content:'.';
				line-height:0;
				display:block;
				height:0;
				visibility:hidden;
				.cl;
			}
		
			.owl-item {
				.fl;
			}
		}
		
		.owl-wrapper, 
		.owl-item {
			-webkit-backface-visibility:hidden;
			-moz-backface-visibility:hidden;
			-ms-backface-visibility:hidden;
			-webkit-transform:translate3d(0,0,0);
			-moz-transform:translate3d(0,0,0);
			-ms-transform:translate3d(0,0,0);
		}
	}
	
	.owl-controls {
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
		-webkit-tap-highlight-color:rgba(0, 0, 0, 0);
	}
}

.owl-pagination {
	display:block;
	width:100%;
	position:absolute;
	left:0;
	bottom:0;
	.tac;
	
	.owl-page {
		display:inline-block;
		width:10px;
		height:10px;
		margin:0 5px 1px;
		text-indent:-9999px;
		cursor:pointer;
		.bdrs(50%);
		
		&:hover, 
		&.active {
			outline:none;
		}
		
		&.active {
			cursor:default;
		}
	}
}

.owl-buttons {
	display:block;
	width:100%;
	position:absolute;
	left:0;
	top:0;
	.tac;
	
	> div {
		display:inline-block;
		padding:0 3px;
		cursor:pointer;
		position:relative;
	}
}


/* Slide Top Button */
#slide_top {
	text-align:center;
	width:50px;
	height:50px;
	position:fixed;
	bottom:50px;
	right:20px;
	z-index:9998;
	.dn;
	
	&:before {
		font-size:20px;
		line-height:50px;
	}
	
	> span {
		display:inline-block;
		text-indent:-9999px;
	}
}


/* Opened Article */
.opened-article {
	.middle_content & {
		&.portfolio, 
		&.profiles {
			margin-top:-50px;
		}
	}
}


/* Likes and Comments */
.cmsmasters_likes, 
.cmsmasters_comments {
	a {
		cursor:pointer;
		display:inline-block;
		vertical-align:middle;
		
		&:before {
			font-size:16px;
			display:inline-block;
			vertical-align:middle;
		}
		
		&.active {
			cursor:default;
		}
		
		span {
			margin:0 0 0 5px;
			display:inline-block;
			vertical-align:middle;
		}
	}
}

.cmsmasters_comments a:before {
	font-size:18px;
}


/* Button */
.button, 
input[type=submit], 
input[type=button], 
button, 
.page #fbuilder fieldset .pbNext,
.page #fbuilder fieldset .pbPrevious,
.page #fbuilder fieldset .reset-button {
}


/* Items Filter */
.cmsmasters_items_filter_wrap {
	padding:0 0 45px;
	position:relative;
	.ovh;
	
	.cmsmasters_items_filter {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_items_sort_block {
		display:block;
		position:relative;
		.fr;
		.ovh;
	}
	
	.cmsmasters_items_sort_but {
		display:block;
		margin:0 0 0 4px;
		padding:5px 30px 5px 15px;
		text-transform:none;
		.bdrs(0);
		.fl;
		
		&:before {
			font-size:13px;
			line-height:13px;
			position:absolute;
			right:12px;
			top:50%;
			margin-top:-6px;
		}
		
		&.reversed:before {
			-webkit-transform:rotate(180deg);
			-moz-transform:rotate(180deg);
			-ms-transform:rotate(180deg);
			transform:rotate(180deg);
		}
	}
	
	.cmsmasters_items_filter_block {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_items_filter_but {
		display:none !important;
		cursor:pointer;
		.fr;
		
		&:before {
			content:none;
		}
		
		&.current {
			position:relative;
		}
	}
	
	.cmsmasters_items_filter_list {
		margin:0;
		padding:0 20px 0 0;
		.ovh;
		
		li {
			display:inline-block;
			margin:0 4px 4px 0;
			padding:0;
			.ovh;
			
			&:before {
				content:none;
			}
			
			a {
				display:inline-block;
				padding:5px 15px;
				text-transform:none;
				cursor:pointer;
				.bdrs(0);
			}
			
			&.current a {
				cursor:default;
			}
		}
	}
}


/* More Items Loader and Pagination */
.cmsmasters_wrap_more_items {
	width:100%;
	position:relative;
	.ovh;
	
	&.cmsmasters_loading {
		min-height:60px;
		
		&:before {
			font-size:30px;
			.pos_abs(1em, 1em, 0, 0, auto, 0);
		}
	}
}

.cmsmasters_wrap_items_loader {
	padding:0;
	margin:20px 0;
	.tac;
	.ovh;
	
	.cmsmasters_items_loader {
		display:inline-block;
	}
}

div.subpage_nav {
	position:relative;
	
	> strong {
		font-weight:bold;
		display:inline-block;
		padding:0 5px 0 0;
		vertical-align:middle;
	}
	
	> span,
	> a {
		display:inline-block;
		padding:0 5px;
		vertical-align:middle;
	}
}

.cmsmasters_wrap_pagination {
	padding:0;
	margin:40px 0 20px 0;
	.ovh;
	.tac;
	
	ul {
		display:block;
		position:relative;
		padding:12px 60px;
		margin:0;
		.tac;
		
		li {
			display:inline-block;
			position:static;
			margin:0;
			padding:0;
			
			&:before {
				content:none;
			}
			
			.page-numbers {
				line-height:36px !important;
				display:block;
				min-width:36px;
				height:36px;
				position:relative;
				
				&.prev,
				&.next {
					position:absolute;
					top:14px;
					
					> span {
						font-size:20px;
					}
				}
				
				&.prev {
					left:20px;
				}
				
				&.next {
					right:20px;
				}
				
				&.current:before {
					content:'';
					position:absolute;
					top:-12px;
					left:50%;
					margin-left:-9px;
					.arrow_bottom(9px; 9px);
				}
			}
		}
	}
}


/* Post Navigation */
.post_nav {
	padding:17px 25px;
	margin-top:40px;
	position:relative;
	.ovh;
	
	&:before {
		content:'';
		position:absolute;
		top:0;
		left:50%;
		margin-left:-6px;
		.arrow_bottom(12px; 12px);
	}
	
	> span {
		display:block;
		position:relative;
		
		a {
			display:inline-block;
			padding:4px 0 3px 0;
			position:relative;
			z-index:2;
		}
		
		> span {
			margin:auto !important;
			position:absolute;
			top:0;
		}
		
		&.cmsmasters_prev_post {
			padding-left:0;
			padding-right:20px;
			.fl;
			
			a {
				padding-left:40px;
				padding-right:0;
			}
			
			> span {
				left:1px;
				right:auto;
			}
		}
		
		&.cmsmasters_next_post {
			padding-left:20px;
			padding-right:0;
			.fr;
			
			a {
				padding-left:0;
				padding-right:40px;
			}
			
			> span {
				left:auto;
				right:1px;
			}
		}
	}
}


/* Share Posts */
.share_posts {
	padding:50px 0 0;
	position:relative;
	.ovh;
	
	.share_posts_title {
		position:relative;
	}
	
	.share_posts_inner {
		position:relative;
		padding-top:5px;
		.ovh;
	}
	
	a {
		margin:0 25px 0 0;
		position:relative;
		
		&:last-child {
			margin-left:0;
			margin-right:0;
		}
	}
}


/* About Author */
.about_author {
	padding:45px 0 0;
	position:relative;
	
	.about_author_inner {
		padding:10px 0;
		.ovh;
	}
	
	.about_author_avatar {
		margin:0 30px 0 0;
		.bdrs(50%);
		.fl;
		.ovh;
		
		img {
			outline:none;
		}
	}
	
	.about_author_cont {
		position:relative;
		.ovh;
		
		p {
			padding:0 0 5px;
		}
	}
	
	.about_author_cont_title {
		margin:0 0 15px;
	}
}


/* Single Post Slider */
.cmsmasters_single_slider {
	padding:30px 0 0;
	position:relative;
	.ovh;
	
	.preloader {
		padding-bottom:@preloader_posts_slider_pad;
		
		&.highImg {
			height:auto;
			padding:0;
		}
	}
	
	.cmsmasters_owl_slider {
		outline:none;
		
		.owl-buttons {
			position:static;
			width:0;
			height:0;
			
			> div {
				display:block;
				width:44px;
				height:44px;
				margin:0;
				position:absolute;
				top:50%;
				margin-top:-22px;
				.ovh;
				.tac;
				
				&.owl-prev {
					left:15px;
				}
				
				&.owl-next {
					right:15px;
				}
				
				> span {
					display:block;
					height:44px;
					width:auto;
				}
			}
		}
	}
	
	.cmsmasters_post_date {
		position:absolute;
		width:60px;
		height:60px;
		padding:11px 12px;
		top:20px;
		right:0;
		z-index:1;
		
		.published {
			display:block;
		}
		
		.cmsmasters_day {
			display:block;
		}
		
		.cmsmasters_mon {
			display:block;
		}
	}
	
	.cmsmasters_img_wrap {
		> a:before {
			content:'';
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			z-index:1;
			.op(0);
		}
		
		> a:hover:before {
			.op(1);
		}
	}
	
	.cmsmasters_single_slider_title {
		margin:0 0 30px;
	}
	
	.cmsmasters_single_slider_inner {
		margin:0 -15px;
		
		.owl-buttons {
			padding:0 10px;
		}
	}
	
	.cmsmasters_single_slider_item {
		min-width:260px;
		padding:0 15px;
		position:relative;
	}
	
	.cmsmasters_single_slider_item_outer {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_single_slider_item_inner {
		padding:20px;
		position:relative;
		.ovh;
	}
	
	.cmsmasters_single_slider_item_title {
		margin:0;
		padding:0;
	}
}


/* Single Pings List */
.cmsmasters_pings_list {
	padding:45px 0 0;
	position:relative;
	.ovh;
	
	.pingslist {
		.pingback {
			position:relative;
			padding:8px 50px 8px 0;
			.bdb;
			
			&:last-child {
				.bdb(0);
			}
			
			.comment-body {
				display:inline;
				
				.edit-link {
					.pos_abs(auto,auto,auto,0,0,0);
					
					&:after {
						.valign_after;
					}
					
					.comment-edit-link {
						display:inline-block;
						vertical-align:middle;
					}
				}
			}
		}
	}
}


/* Post Comments */
.post_comments {
	padding:45px 0 0;
	position:relative;
	
	&:after {
		.cl_after;
	}
	
	.post_comments_title {
		margin:0;
	}
}

.project_navi {
	padding:20px 0;
	.ovh;
}

.commentlist {
	list-style:none;
	margin:0;
	padding:0;
	
	&:after {
		.cl_after;
	}
	
	.children {
		list-style:none;
		margin:0;
		padding:0 0 0 20px;
	}
	
	.comment {
		margin:0;
		padding:35px 0 0;
		
		&:before {
			content:none;
		}
		
		&:after {
			.cl_after;
		}
	}
}

.cmsmasters_comment_item_info_wrap {
	.ovh;
}

.cmsmasters_comment_item {
	position:relative;
	
	&:after {
		.cl_after;
	}
	
	.cmsmasters_comment_item_date {
		.ovh;
		.fr;
	}
	
	.cmsmasters_comment_item_avatar {
		display:inline-block;
		vertical-align:middle;
		margin-right:20px;
		
		img {
			.bdrs(50%);
		}
	}
	
	.cmsmasters_comment_item_cont {
		position:relative;
		display:inline-block;
		vertical-align:middle;
		.ovh;
	}
	
	.cmsmasters_comment_item_info_left {
		padding-right:20px;
		.ovh;
	}
	
	.cmsmasters_comment_item_cont_info {
		position:relative;
		.ovh;
		
		> a {
			display:inline-block;
			margin-right:10px;
			
			&:last-child {
				margin:0;
			}
		}
	}
	
	.cmsmasters_comment_item_title {
		margin:0;
		padding:0;
	}
	
	.cmsmasters_comment_item_content {
		position:relative;
		padding:35px 30px;
		margin:0 0 20px;
		
		&:before {
			content:'';
			position:absolute;
			bottom:-8px;
			left:22px;
			.arrow_bottom(8px; 8px);
		}
		
		> *:last-child {
			margin-bottom:0;
			padding-bottom:0;
		}
	}
}


/* Comment Respond */
.comment-respond {
	padding:50px 0 0;
	position:relative;
	
	&:after {
		.cl_after;
	}
	
	textarea {
		display:block;
		min-height:140px;
	}
	
	p {
		padding-bottom:10px;
	}
	
	.comment-reply-title {
		position:relative;
		
		small {
			.fr;
		}
	}
	
	.comment-form {
		position:relative;
		
		&:after {
			.cl_after;
		}
	}
	
	.comment-notes, 
	.logged-in-as {
		padding-bottom:20px;
		.cl;
	}
	
	.comment-form-author, 
	.comment-form-email {
		padding-right:10px;
		clear:left;
		.fl;
	}
	
	.comment-form-cookies-consent {
		float:none;
		.cl;
		
		input, 
		label {
			display:inline-block;
			vertical-align:middle;
		}
		
		label {
			margin-left:5px;
		}
	}
	
	.form-submit {
		padding-top:20px;
		.cl;
	}
}


.nocomments {
	margin:50px 0 0;
	position:relative;
	.ovh;
}


/*-------------------------------------------------------------------------*/
/*	Header + Centered + Right + Fixed Versions
/*-------------------------------------------------------------------------*/

/***************** Header ******************/
#header {
	width:100%;
	position:absolute;
	z-index:9999;
	-webkit-box-shadow:0px 0px 2px 2px rgba(0,0,0,0.1);
	-moz-box-shadow:0px 0px 2px 2px rgba(0,0,0,0.1);
	box-shadow:0px 0px 2px 2px rgba(0,0,0,0.1);
}


/***************** Header Top ******************/
.header_top {
	outline:none;
	
	.header_top_but {
		width:100%;
		height:40px;
		position:relative;
		cursor:pointer;
		.dn;
		
		&.closed {
			border-color:transparent;
		}
		
		> span {
			font-size:10px;
			line-height:10px;
			.pos_abs;
		}
	}
	
	.header_top_outer {
		height:100%;
		position:relative;
	}
	
	.header_top_inner {
		height:100%;
		
		&:after {
			.cl_after;
		}
	}
	
	.header_top_right {
		height:100%;
		.fr;
	}
	
	.social_wrap {
		outline:none;
		
		li {
			margin:0;
		}
		
		a {
			font-size:12px;
			line-height:12px;
			width:23px;
			height:23px;
		}
	}
	
	.top_nav_wrap {
		display:block;
		height:100%;
	}
	
	.responsive_top_nav {
		font-size:20px;
		width:100%;
		height:40px;
		position:relative;
		.dn;
		.m0a;
		
		&:before {
			.pos_abs;
		}
	}
	
	.header_top_left {
		height:100%;
		padding:0 20px 0 0;
		.ovh;
		
		&:after {
			.valign_after;
		}
	}
	
	.meta_wrap {
		display:inline-block;
		vertical-align:middle;
		position:relative;
		
		> * {
			margin:0 35px 0 0;
			display:inline-block;
			
			&:last-child {
				margin:0;
			}
			
			&[class^="cmsmasters-icon-"]:before, 
			&[class*=" cmsmasters-icon-"]:before, 
			&[class^="cmsmasters_theme_icon_"]:before, 
			&[class*=" cmsmasters_theme_icon_"]:before {
				margin-left:0;
				font-size:20px;
				margin-right:0.5em;
				vertical-align:top;
			}
		}
		
		> p {
			padding:0;
		}
		
		.adr {
			white-space:nowrap;
		}
	}
}


/***************** Header Top Navigation ******************/
.top_line_nav {
	z-index:205;
	
	> li {
		height:100%;
		
		> a {
			height:100%;
			padding:0 20px;
			
			&:after {
				.valign_after;
			}
			
			.nav_item_wrap {
				display:inline-block;
				.val;
			}
		}
		
		&.menu-item-has-children {
			> a {
				padding-right:35px;
				
				&:before {
					content:'\eb2e';
					font-family:fontello;
					font-size:12px;
					line-height:12px;
					position:absolute;
					right:20px;
					top:50%;
					margin-top:-7px;
				}
			
				&:hover:before {
					-webkit-transform:rotate(90deg);
					-moz-transform:rotate(90deg);
					-ms-transform:rotate(90deg);
					transform:rotate(90deg);
				}
			}
			
			&.current-menu-item a:before,
			&.current-menu-ancestor a:before {
				-webkit-transform:rotate(90deg);
				-moz-transform:rotate(90deg);
				-ms-transform:rotate(90deg);
				transform:rotate(90deg);
			}
		}
		
		ul {
			-webkit-box-shadow:0px 4px 2px -2px rgba(0, 0, 0, 0.12);
			-moz-box-shadow:0px 4px 2px -2px rgba(0, 0, 0, 0.12);
			box-shadow:0px 4px 2px -2px rgba(0, 0, 0, 0.12);
			
			a {
				padding:3px 20px;
			}
		}
	}
}


/***************** Header Mid ******************/
.header_mid {
	.cl;
	
	.header_mid_outer {
		height:100%;
		position:relative;
	}
	
	.header_mid_inner {
		height:100%;
		
		&:after {
			.cl_after;
		}
	}
	
	.logo_wrap {
		height:100%;
		position:relative;
		.fl;
		
		.enable_header_centered & {
			float:none;
			.m0a;
		}
		
		&:after {
			.valign_after;
		}
		
		.logo_text_wrap {
			display:inline-block;
			vertical-align:middle;
		}
		
		.title {
			display:block;
			line-height:1em;
		}
		
		.title_text {
			padding:0 0 0 3px;
		}
		
		.logo {
			display:inline-block;
			width:auto;
			vertical-align:middle;
			
			img {
				max-height:100%;
				.pos_abs(auto, auto, 0, auto);
				
				.enable_header_centered & {
					left:0;
					right:0;
				}
			}
			
			.logo_retina {
				.dn;
			}
		}
	}
	
	.resp_mid_nav_wrap {
		height:100%;
		margin:0 0 0 20px;
		position:relative;
		.dn;
		.fl;
		.ovh;
		
		.resp_mid_nav_outer {
			height:100%;
			
			&:after {
				.valign_after;
			}
		}
		
		.resp_mid_nav {
			font-size:14px;
			line-height:14px;
			display:inline-block;
			vertical-align:middle;
			width:28px;
			height:28px;
			position:relative;
			
			&:before {
				.pos_abs;
			}
			
			&:hover, 
			&.active {
				outline:none;
			}
		}
	}
	
	.search_resp_nav_wrap {
		height:100%;
		.fr;
	}
	
	.search_wrap {
		padding:0 0 0 15px;
		position:relative;
		height:100%;
		.bdl;
		.fl;
		
		.search_wrap_inner {
			height:100%;
			
			&:after {
				.valign_after;
			}
		}
		
		.search_bar_wrap {
			display:inline-block;
			width:23px;
			vertical-align:middle;
			margin:0 0 3px 0;
			padding:0;
			.ovh;
			
			.search_field {
				position: fixed;
				left:0;
				bottom:0;
				top:0;
				right:0;
				height:100%;
				opacity:0;
				z-index: 9998;
				visibility:hidden;
				
				input[type=search] {
					padding-left:10%;
					padding-right:10%;
					position: fixed;
					top:50%;
					height:100px;
					width:100%;
					margin-top:-50px;
					.tac;
					.bd(0);
				}
				
				input[type=search]:focus::-webkit-input-placeholder {
					color:transparent;
				}	
				input[type=search]:focus::-moz-placeholder {
					color:transparent;
				}
				input[type=search]:focus:-moz-placeholder {
					color:transparent;
				}
				input[type=search]:focus:-ms-input-placeholder {
					color:transparent;
				}
			}
			
			.search_button {
				width:23px;
				height:23px;
				position:relative;
				margin:0 !important;
				.fr;
				
				button {
					outline:none;
					
					&:before {
						font-size:18px;
					}
				}
				
				.cmsmasters_cross:before, 
				.cmsmasters_cross:after {
					content:'';
					position:absolute;
					background:#fff;
					-webkit-transform:rotate(45deg);
					-moz-transform:rotate(45deg);
					-ms-transform:rotate(45deg);
					transform:rotate(45deg);
				}
				
				.cmsmasters_cross:before {
					width:3px;
					height:100%;
				}
				
				.cmsmasters_cross:after {
					width:100%;
					left:0;
					height:3px;
					margin-top:-2px;
				}
			}
		}
		
		&.search_opened {
			.search_bar_wrap {
				overflow:visible;
				
				.search_field {
					opacity:1;
					visibility: visible;
				}
				
				.search_button {
					position:fixed;
					top:30px;
					right:30px;
					z-index:9999;
					
					button {
						opacity:0;
						
						&.cmsmasters_bfade {
							opacity: 1;
						}
					}
				}
			}
		}
	}
	
	.social_wrap {
		outline:none;
		
		.social_wrap_inner {
			.tar;
		}
		
		li {
			margin-left:0;
			margin-right:3px;
			
			&:last-child {
				margin-left:0;
				margin-right:0;
			}
		}
		
		a {
			font-size:16px;
			line-height:16px;
			width:34px;
			height:34px;
		}
	}
	
	.slogan_wrap {
		height:100%;
		display:block;
		position:relative;
		.ovh;
		
		.slogan_wrap_inner {
			height:100%;
			padding:0 0 0 20px;
			.tar;
			
			&:after {
				.valign_after;
			}
		}
		
		.slogan_wrap_text {
			display:inline-block;
			vertical-align:middle;
			
			> * {
				margin:0 10px 0 0;
				display:inline-block;
				
				&:last-child {
					margin:0;
				}
				
				&[class^="cmsmasters-icon-"]:before, 
				&[class*=" cmsmasters-icon-"]:before, 
				&[class^="cmsmasters_theme_icon_"]:before, 
				&[class*=" cmsmasters_theme_icon_"]:before {
					margin-left:0;
					margin-right:0.5em;
					vertical-align:middle;
				}
			}
			
			> p {
				padding:0;
			}
			
			.adr {
				white-space:nowrap;
			}
		}
	}
	
	.mid_nav_wrap {
		height:100%;
		position:relative;
		.fr;
	}
	
	.enable_header_bottom & {
		.search_wrap {
			.bdl(0);
		}
	}
}

.header_mid_scroll nav > div > ul > li:hover > ul {
	margin:0;
}

.admin-bar .header_mid .search_wrap.search_opened .search_bar_wrap .search_button {
	top:62px;
}


/***************** Header Bot ******************/
.header_bot {
	.cl;
	
	.header_bot_outer {
		height:100%;
		position:relative;
		.bdt;
	}
	
	.header_bot_inner {
		height:100%;
		
		&:after {
			.cl_after;
		}
		
		.enable_header_centered & {
			.tac;
		}
	}
	
	.resp_bot_nav_wrap {
		height:100%;
		position:relative;
		.dn;
		.ovh;
		
		.resp_bot_nav_outer {
			height:100%;
			
			&:after {
				.valign_after;
			}
		}
		
		.resp_bot_nav {
			font-size:14px;
			line-height:14px;
			display:inline-block;
			vertical-align:middle;
			width:28px;
			height:28px;
			position:relative;
			
			&:before {
				.pos_abs;
			}
			
			&:hover, 
			&.active {
				outline:none;
			}
		}
	}
	
	.bot_nav_wrap {
		height:100%;
		position:relative;
		.fl;
		
		.enable_header_right & {
			.fr;
		}
		
		.enable_header_centered & {
			display:inline-block;
			float:none;
		}
	}
}


/***************** Header Main Navigation ******************/
.bot_nav, 
.mid_nav {
	display:inline-block;
    width:auto;
    vertical-align:middle;
    position:relative;
	
	> li {
		height:100%;
		margin:0;
		
		> a {
			height:100%;
			padding:0 20px;
			
			&:after {
				.valign_after;
			}
			
			.nav_item_wrap {
				display:inline-block;
				.val;
			}
			
			.nav_tag {
				display:block;
				padding:0 10px;
				margin:0 0 0 10px;
				font-size:10px;
				line-height:20px;
				.fr;
			}
		}
		
		> ul {
			&:after {
				content: '';
				position: absolute;
				border: 7px solid transparent;
				left:20px;
				top:-13px;
				display:none;
				.bdb(8px);
			}
		}
		
		&:hover {
			> ul:after {
				visibility:visible;
				display:block;
			}
		}
	}
	
	.sub-menu,
	.menu-item-mega-container {
		-webkit-box-shadow:0px 4px 2px -2px rgba(0, 0, 0, 0.12);
		-moz-box-shadow:0px 4px 2px -2px rgba(0, 0, 0, 0.12);
		box-shadow:0px 4px 2px -2px rgba(0, 0, 0, 0.12);
	}
	
	.menu-item-mega-container .sub-menu {
		box-shadow:none;
	}
	
	ul {
		ul {
			z-index:204;
			
			li:first-child {
				position:relative;
				
				&:before {
					content:'';
					margin-top:-6px;
					top:50%;
					left:-14px;
					position:absolute;
					border:6px solid transparent;
					display:none;
					.bdr(6px);
					.bdl(0);
				}
		
				&:hover:before {
					display:block;
				}
			}
		}
		
		li {
			margin:0 -1px;
		}
	
		li:hover {
			ul li:first-child {
				&:before {
					display:block;
				}
			}
		}
	
		a {
			padding:9px 20px;
		}
	
		.nav_tag {
			padding:0 10px;
			margin:0 0 0 10px;
			font-size:10px;
			line-height:20px;
			.fr;
		}
	}
	
	.menu-item-mega-container ul ul li:first-child:before {
		display:none;
		content:none;
	}
	
	.nav_subtitle {
		display:block;
		margin-top:-2px;
	}
}


/*-------------------------------------------------------------------------*/
/*	Content + Sidebar Styles
/*-------------------------------------------------------------------------*/

body .headline {
	background-color:transparent;
}

.cmsmasters_breadcrumbs_wrapper {
	.bdb;

	.cmsmasters_breadcrumbs {
		position:relative;
		.tar;
		.ovh;
		
		.cmsmasters_breadcrumbs_inner {
			display:inline-block;
			padding-top:14px;
			padding-bottom:14px;
			vertical-align:middle;
			.ovh;
			
			a:hover,
			span {
				.op(0.5);
			}
		}
	}
}



.headline_outer {
	background-position:top center;
	position:relative;
	z-index:1;
	
	&.cmsmasters_headline_disabled {
		outline:none;
	}
	
	.headline_color {
		width:100%;
		height:100%;
		position:absolute;
		left:0;
		top:0;
		z-index:-1;
	}
	
	.headline_inner {
		padding:0 @blocks_hor_pad;
		position:relative;
		z-index:1;
		.ovh;
		
		.headline_aligner {
			display:inline-block;
			vertical-align:middle;
		}
		
		.headline_text {
			display:inline-block;
			vertical-align:middle;
			position:relative;
			
			&:before {
				line-height:1em;
				.pos_abs;
			}
			
			.entry-title {
				word-wrap:break-word;
				margin:0;
			}
			
			.entry-subtitle {
				margin:5px 0 0;
			}
		}
		
		&.align_left {
			.tal;
			
			.headline_text {
				.tal;
				
				&:before {
					right:auto;
				}
			}
		}
		
		&.align_right {
			.tar;
			
			.headline_text {
				.tar;
				
				&:before {
					left:auto;
				}
			}
		}
		
		&.align_center {
			.tac;
			
			.headline_text {
				.tac;
				
				&:before {
					bottom:auto;
				}
				
				&.headline_icon {
					> *:first-child {
						padding-top:15px;
					}
				}
			}
		}
	}
}

.content_wrap {
	display:block;
	min-width:inherit;
}



/*-------------------------------------------------------------------------*/
/*	Bottom + Footer Styles
/*-------------------------------------------------------------------------*/

/***************** Bottom ******************/
.bottom_inner {
	.widgettitle {
		margin-bottom:35px;
		position:relative;
	}
}


/***************** Footer ******************/
#footer {
	.ovh;
}

.footer_inner {
	position:relative;
	.ovh;
}


/***************** Footer Default ******************/
.cmsmasters_footer_default {
	.footer_inner {
		padding-top:100px;
		padding-bottom:70px;
		
		> div:first-child {
			padding-top:0;
		}
	}
	
	.footer_logo_wrap {
		position:relative;
		.tac;
		.ovh;
		
		.footer_logo {
			display:inline-block;
			
			> .footer_logo_retina {
				.dn;
			}
		}
	}
	
	.footer_custom_html_wrap {
		padding:40px 0 0;
		position:relative;
		.tac;
		.ovh;
	}
	
	.footer_custom_html {
		display:inline-block;
		.ovh;
		.tac;
	}
	
	.footer_nav_wrap {
		padding:25px 0 0;
		position:relative;
		.ovh;
		
		div {
			.tac;
		}
	}
	
	.footer_nav {
		display:inline-block;
		width:auto;
		
		> li {
			margin:0 13px;
			.bd(0);
			
			ul {
				display:none !important;
			}
		}
	}
	
	.social_wrap {
		padding:40px 0 0;
		
		.social_wrap_inner {
			.tac;
		}
		
		li {
			margin:0 1px;
		}
		
		a {
			font-size:18px;
			line-height:18px;
			width:34px;
			height:34px;
		}
	}
	
	.footer_copyright {
		display:block;
		padding:27px 0;
		.tac;
	}
}


/***************** Footer Small ******************/
.cmsmasters_footer_small {
	.footer_inner {
		padding-top:26px;
		padding-bottom:26px;
	}
	
	.footer_nav_wrap {
		position:relative;
		.fr;
		.ovh;
	}
	
	.footer_nav {
		> li {
			margin:0 13px;
			.bd(0);
			
			ul {
				display:none !important;
			}
		}
	}
	
	.social_wrap {
		.fr;
		
		li {
			margin:0;
		}
		
		a {
			font-size:12px;
			line-height:12px;
			width:23px;
			height:23px;
		}
	}
	
	.footer_custom_html_wrap {
		position:relative;
		.fr;
		.ovh;
	}
	
	.footer_copyright {
		display:block;
		padding:2px 20px 2px 0;
		position:relative;
		.ovh;
	}
}



/*-------------------------------------------------------------------------*/
/*	Blog (Default, Masonry, Timeline) + Post Styles
/*-------------------------------------------------------------------------*/

/***************** Blog ******************/
.blog {
	position:relative;
	
	&:after {
		.cl_after;
	}
	
	.post {
		width:100%;
		max-width:100%;
		
		.preloader {
			padding-bottom:@preloader_blog_default_pad;
			
			&.highImg {
				height:auto;
				padding-bottom:0;
			}
		}
		
		.cmsmasters_video_wrap, 
		.resizable_block {
			padding-bottom:@preloader_blog_default_pad;
		}
	}
	
	&.columns {
		margin:-40px -20px 0;
		
		.post {
			min-width:260px;
			padding:40px 20px 0;
			.fl;
			.op(0);
			
			&.shortcode_animated {
				.op(1);
			}
			
			.preloader {
				padding-bottom:@preloader_blog_masonry_pad;
				
				&.highImg {
					height:auto;
					padding-bottom:0;
				}
			}
			
			.resizable_block {
				padding-bottom:@preloader_blog_masonry_pad;
			}
		}
	}
	
	&.timeline {
		&:before {
			content:'';
			.pos_abs(1px, 100%);
		}
		
		.post {
			width:50%;
			overflow:visible;
			.fl;
			.op(0);
			
			&.cmsmasters_timeline_left {
				.cmsmasters_timeline_margin {
					margin-right:26%;
				}
			}
			
			&.cmsmasters_timeline_right {
				.cmsmasters_timeline_margin {
					margin-left:26%;
				}
			}
			
			&.shortcode_animated {
				.op(1);
			}
			
			.preloader {
				padding-bottom:@preloader_blog_timeline_pad;
				
				&.highImg {
					height:auto;
					padding-bottom:0;
				}
			}
			
			.cmsmasters_video_wrap, 
			.resizable_block {
				padding-bottom:@preloader_blog_timeline_pad;
			}
		}
	}
	
	.owl-pagination {
		width:auto;
		height:10px;
		margin:0 auto;
		line-height:10px;
		left:0;
		right:0;
		top:auto;
		bottom:15px;
	}
}


/***************** Blog Default ******************/
.cmsmasters_post_default {
	padding:60px 0 0;
	position:relative;
	.ovh;
	
	&.sticky {
		.cmsmasters_sticky {
			display:block;
			position:relative;
			height:42px;
			width:100px;
			font-size:20px;
			line-height:20px;
			
			&:before {
				.pos_abs;
			}
		}
	}
	
	&:first-child {
		padding-top:0;
	}
	
	.cmsmasters_post_info {
		width:100px;
		.fl;
		.ovh;
	}
	
	.cmsmasters_post_date {
		display:block;
		height:100px;
		width:100px;
		padding:22px 25px;
		
		.published {
			display:block;
		}
		
		.cmsmasters_day {
			display:block;
		}
		
		.cmsmasters_mon {
			display:block;
		}
	}
	
	.cmsmasters_post_cont_img_wrap {
		margin:0 0 35px;
	}
	
	.cmsmasters_post_cont_inner_wrap {
		margin-left:60px;
	}
	
	.cmsmasters_post_cont_img_wrap + .cmsmasters_post_cont_inner_wrap {
		margin:0;
	}
	
	.cmsmasters_post_info_bot {
		padding:9px 10px;
		.ovh;
	}
	
	.cmsmasters_post_likes {
		min-width:50%;
		.fl;
		.ovh;
	}
	
	.cmsmasters_post_comments {
		min-width:50%;
		padding-left:4px;
		.fl;
		.ovh;
	}
	
	.cmsmasters_post_cont {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_img_wrap {
		.ovh;
	}
	
	.cmsmasters_owl_slider {
		outline:none;
		
		figure {
			.ovh;
		}
	}
	
	.cmsmasters_audio {
		margin:35px 0 5px 0
	}
	
	.cmsmasters_video_wrap {
		outline:none;
	}
	
	.cmsmasters_post_header {
		padding:0 0 3px;
		
		.cmsmasters_post_title {
			word-wrap:break-word;
			margin:0;
		}
	}
	
	.cmsmasters_post_cont_info {
		.ovh;
		
		> span {
			position:relative;
		}
		
		.cmsmasters_post_author + .cmsmasters_post_category {
			padding-left:12px;
			
			&:before {
				content:'|';
				position:absolute;
				top:0;
				left:4px;
			}
		}
	}
	
	.cmsmasters_post_content {
		padding:25px 0;
		.ovh;
		
		p {
			margin:0;
			padding:0;
		}
	}
	
	.cmsmasters_post_footer {
		.ovh;
	}
	
	.cmsmasters_post_read_more {
		outline:none;
	}
}


/***************** Blog Masonry ******************/
.cmsmasters_post_masonry {
	position:relative;
	
	.cmsmasters_post_cont {
		position:relative;
		padding-bottom:20px;
		.ovh;
		
		.cmsmasters_post_date + * {
			padding-top:20px;
		}
		
		.cmsmasters_post_date + .cmsmasters_audio,
		.cmsmasters_post_date + .cmsmasters_video_wrap {
			padding-top:0;
		}
		
		> *:last-child {
			margin-bottom:0;
			padding-bottom:0;
		}
	}
	
	.cmsmasters_post_date {
		display:block;
		width:60px;
		height:60px;
		padding:11px 12px;
		margin:0 0 0 30px;
		.fr;
		.ovh;
		
		.published {
			display:block;
		}
		
		.cmsmasters_day {
			display:block;
		}
		
		.cmsmasters_mon {
			display:block;
		}
	}
	
	.cmsmasters_date_img_wrap {
		position:relative;
		margin-bottom:20px;
		
		figure {
			.ovh;
		}
		
		.cmsmasters_post_date {
			margin:0;
			float:none;
			position:absolute;
			top:20px;
			right:0;
			z-index:2;
		}
	}
	
	.cmsmasters_img_wrap {
		> a:before {
			content:'';
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			z-index:1;
			.op(0);
		}
		
		> a:hover:before {
			.op(1);
		}
	}
	
	&.format-video {
		.cmsmasters_img_wrap {
			margin-top:20px;
		}
	}
	
	.cmsmasters_video_wrap {
		margin-top: 20px;
	}
	
	.cmsmasters_audio {
		margin-top: 20px;
	}
	
	.cmsmasters_post_header {
		padding:0 20px 3px 20px;
		
		.cmsmasters_post_title {
			margin:0;
		}
	}
	
	.cmsmasters_post_cont_info {
		padding:0 20px;
		.ovh;
		
		> span {
			display:block;
		}
		
		.cmsmasters_post_author + .cmsmasters_post_category {
			margin-top:-3px;
		}
	}
	
	.cmsmasters_post_content {
		padding:20px 20px 15px 20px;
		.ovh;
		
		p {
			margin:0;
			padding:0;
		}
	}
	
	.cmsmasters_post_footer {	
		padding:0 20px;
		.ovh;
		
		> span {
			outline:none;
		}
	}
	
	.cmsmasters_post_footer_inner {
		margin-left:20px;
		.fr;
		
		.cmsmasters_post_likes + .cmsmasters_post_comments {
			margin-left:13px;
		}
	}
	
	.cmsmasters_post_read_more {
		outline:none;
	}
}


/***************** Blog Timeline ******************/
.cmsmasters_post_timeline {
	position:relative;
	
	.cmsmasters_post_info {
		display:block;
		position:absolute;
		z-index:1;
	}
	
	&.cmsmasters_timeline_left {
		.cmsmasters_post_info {
			left:auto;
			right:-37px;
			
			.cmsmasters_post_date {
				&:before {
					left:-9px;
					.arrow_left(9px; 9px);
				}
			}
		}
	}
	
	&.cmsmasters_timeline_right {
		.cmsmasters_post_info {
			left:-38px;
			right:auto;
			
			.cmsmasters_post_date {
				&:before {
					right:-9px;
					.arrow_right(9px; 9px);
				}
			}
		}
	}
	
	.cmsmasters_post_date {
		display:block;
		width:75px;
		height:75px;
		.ovh;
		
		&:before {
			content:'';
			position:absolute;
			top:50%;
			margin-top:-9px;
		}
		
		.published {
			padding:8px 14px;
			display:block;
		}
		
		.cmsmasters_day {
			display:block;
		}
		
		.cmsmasters_mon {
			display:block;
		}
	}
	
	.cmsmasters_post_cont {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_img_wrap {
		> a:before {
			content:'';
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			z-index:1;
			.op(0);
		}
		
		> a:hover:before {
			.op(1);
		}
	}
	
	.cmsmasters_post_cont_inner {
		padding:30px;
		
		> *:last-child {
			margin-bottom:0;
			padding-bottom:0;
		}
	}
	
	figure {
		.ovh;
	}
	
	.cmsmasters_video_wrap {
		outline:none;
	}
	
	.cmsmasters_audio {
		outline:none;
	}
	
	.cmsmasters_post_header {
		padding:0 0 3px 0;
		
		.cmsmasters_post_title {
			margin:0;
		}
	}
	
	.cmsmasters_post_cont_info {
		.ovh;
		
		> span {
			display:block;
		}
		
		.cmsmasters_post_author + .cmsmasters_post_category {
			margin-top:-3px;
		}
	}
	
	.cmsmasters_post_content {
		padding:25px 0 15px 0;
		.ovh;
		
		p {
			margin:0;
			padding:0;
		}
	}
	
	.cmsmasters_post_footer {
		.ovh;
		
		> span {
			outline:none;
		}
		
		.cmsmasters_post_footer_inner {
			margin-left:20px;
			.fr;
			
			.cmsmasters_post_likes + .cmsmasters_post_comments {
				margin-left:13px;
			}
		}
	}
	
	.cmsmasters_post_read_more {
		outline:none;
	}
}


/***************** Blog Post ******************/
.cmsmasters_open_post {
	.ovh;
	
	.preloader {
		padding-bottom:@preloader_open_post_pad;
		
		&.highImg {
			height:auto;
			padding:0;
		}
	}
	
	.cmsmasters_video_wrap, 
	.resizable_block {
		padding-bottom:@preloader_open_post_pad;
	}
	
	> div:first-child {
		margin-top:0;
	}
	
	.cmsmasters_open_post_info {
		width:100px;
		.fl;
		.ovh;
	}
	
	.cmsmasters_post_date {
		display:block;
		height:100px;
		width:100px;
		padding:22px 25px;
		
		.published {
			display:block;
		}
		
		.cmsmasters_day {
			display:block;
		}
		
		.cmsmasters_mon {
			display:block;
		}
	}
	
	.cmsmasters_open_post_info_bot {
		padding:9px 10px;
		.ovh;

		&:empty {
			display:none;
		}
	}
	
	.cmsmasters_post_likes {
		min-width:50%;
		.fl;
		.ovh;
	}
	
	.cmsmasters_post_comments {
		min-width:50%;
		padding-left:4px;
		.fl;
		.ovh;
	}
	
	.cmsmasters_open_post_wrap {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_open_post_inner {
		margin-left:60px;
	}
	
	.cmsmasters_post_cont_img_wrap + .cmsmasters_open_post_inner {
		margin-left:0;
		
		> * {
			padding-top:20px;
		}
	}

	.cmsmasters_post_cont_img_wrap {
		padding:0 0 20px;
	}
	
	.cmsmasters_img_wrap {
		.ovh;
	}
	
	.cmsmasters_owl_slider {
		outline:none;
		
		figure {
			.ovh;
		}
	}
	
	.cmsmasters_video_wrap {
		outline:none;
	}
	
	.cmsmasters_audio {
		padding-top:10px;
		outline:none;
	}
	
	.cmsmasters_post_header {
		padding:0 0 5px;
		
		.cmsmasters_post_title {
			margin:0;
		}
	}
	
	.cmsmasters_post_cont_info {
		.ovh;
		
		> span {
			position:relative;
		}
	}
	
	.cmsmasters_post_author + .cmsmasters_post_category {
		padding-left:12px;
		
		&:before {
			content:'|';
			position:absolute;
			top:0;
			left:4px;
		}
	}
	
	.cmsmasters_post_content {
		padding:25px 0 35px 0;
		.ovh;
		
		> *:last-child {
			margin-bottom:0;
			padding-bottom:0;
		}
	}
	
	.cmsmasters_post_tags {
		> a {
			display:inline-block;
			margin:4px 4px 0 0;
			padding:4px 13px;
		}
	}
}



/*-------------------------------------------------------------------------*/
/*	Portfolio (Grid, Puzzle) + Project Styles
/*-------------------------------------------------------------------------*/

/***************** Portfolio ******************/
.portfolio {
	position:relative;
	
	&:after {
		.cl_after;
	}
	
	.cmsmasters_project_grid,
	.cmsmasters_project_puzzle {
		min-width:260px;
		.fl;
		
		.preloader {
			padding-bottom:@preloader_portfolio_grid_pad;
			
			&.highImg {
				height:auto;
				padding-bottom:0;
			}
		}
	}
	
	&.large_gap {
		margin:0 -20px;
		
		.project {
			padding-left:20px;
			padding-right:20px;
			padding-bottom:40px;
		}
	}
	
	&.small_gap {
		padding:1px 1px 0;
		.ctbx;
		
		.project {
			overflow:visible;
			
			.project_outer {
				margin-left:0;
				margin-right:1px;
				margin-bottom:1px;
			}
		}
	}
	
	&.grid.perfect .project {
		figure {
			.op(0);
		}
		
		&.shortcode_animated {
			figure {
				.op(1);
			}
		}
	}
	
	&.grid.masonry .project, 
	&.puzzle .project {
		.op(0);
		
		&.shortcode_animated {
			.op(1);
		}
	}
	
	&.cmsmasters_5 .project {
		max-width:20%;
	}
	
	&.cmsmasters_4 .project {
		max-width:25%;
	}
	
	&.cmsmasters_3 .project {
		max-width:33%;
	}
	
	&.cmsmasters_2 .project {
		max-width:50%;
	}
	
	&.isotope.cmsmasters_5 .project, 
	&.isotope.cmsmasters_4 .project, 
	&.isotope.cmsmasters_3 .project, 
	&.isotope.cmsmasters_2 .project {
		max-width:none;
	}
}


/***************** Portfolio Grid ******************/
.cmsmasters_project_grid {
	position:relative;
	
	.project_outer {
		position:relative;
		.ovh;
		
		&:hover {
			.cmsmasters_img_rollover {
				.op(1);
				
				.cmsmasters_open_post_link {
					.top_angle {
						left:0;
					}
					
					.bot_angle {
						right:0;
					}
				}
			}
			
			.cmsmasters_project_header, 
			.cmsmasters_project_cont_info {
				a:hover {
					.op(@hover_opacity);
				}
			}
			
			.cmsmasters_project_footer {
				a.active:before,
				a:hover:before {
					.op(@hover_opacity);
				}
			}
		}
	}
	
	.project_inner {
		position:relative;
		padding:25px 20px;
		
		&:before {
			content:'';	
			position:absolute;
			left:50%;
			top:-9px;
			margin-left:-9px;
			.arrow_top(9px; 9px);
		}
		
		> *:last-child {
			padding-bottom:0;
		}
	}
	
	.cmsmasters_project_header {
		padding:0;
		.tac;
		
		.cmsmasters_project_title {
			margin:0;
		}
	}
	
	.cmsmasters_project_cont_info {
		.ovh;
		.tac;
	}
	
	.cmsmasters_project_content {
		padding:15px 0;
		.ovh;
		.tac;
		
		p {
			margin:0;
			padding:0;
		}
	}
	
	.cmsmasters_project_footer {
		.ovh;
		.tac;
		
		> span {
			outline:none;
			margin:0 7px;
		}
	}
}


/***************** Portfolio Puzzle ******************/
.cmsmasters_project_puzzle {
	position:relative;
	
	.project_outer {
		position:relative;
		.ovh;
		
		&:hover {
			img {
				outline:none;
			}
			
			.cmsmasters_img_rollover {
				.op(1);
			}
			
			.project_inner {
				margin-top:0;
				.op(1);
			}
		}
		
		.cmsmasters_img_rollover {
			> a {
				.dn;
			}
		}
	}
	
	.project_inner_wrap {
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		top:0;
		right:0;
		.tac;
		
		&:after {
			.valign_after;
		}
	}
	
	.project_inner {
		padding:20px;
		display:inline-block;
		margin-top:20px;
		.op(0);
		.val;
		
		> * {
			.tac;
		}
	}
	
	.cmsmasters_project_header {
		padding:0;
		
		.cmsmasters_project_title {
			margin:0;
		}
		
		a:hover {
			.op(@hover_opacity);
		}
	}
	
	.cmsmasters_project_cont_info {
		.ovh;
		
		a:hover {
			.op(@hover_opacity);
		}
	}
	
	.cmsmasters_project_footer {
		padding-top:15px;
		.ovh;
		
		> span {
			margin:0 7px;
		}
		
		a.active:before,
		a:hover:before {
			.op(@hover_opacity);
		}
	}
}


/***************** Portfolio Open Project ******************/
.cmsmasters_open_project {
	padding:0 0 40px;
	position:relative;
	.ovh;
	
	.preloader {
		padding-bottom:@preloader_open_project_pad;
		
		&.highImg {
			height:auto;
			padding:0;
		}
	}
	
	.cmsmasters_video_wrap, 
	.resizable_block {
		padding-bottom:@preloader_open_project_pad;
	}
	
	.owl-pagination {
		width:auto;
		position:absolute;
		left:auto;
		right:10px;
		top:10px;
		bottom:auto;
	}
	
	.cmsmasters_project_likes, 
	.cmsmasters_project_comments {
		outline:none;
	}
	
	.cmsmasters_project_header {
		padding:35px 0 0;
		.ovh;
		.cl;
		
		.cmsmasters_project_title {
			margin:0;
		}
	}
	
	.project_content {
		width:100%;
		padding:@cont_vert_pad 0 0;
		.ovh;
		
		&.with_sidebar {
			width:@content_width;
			.fl;
		}
		
		.project_gallery_row {
			margin:0 -@col_mar;
			position:relative;
			
			figure {
				.ovh;
			}
			
			.one_third, 
			.one_half, 
			.one_first {
				margin-bottom:(@col_mar * 2);
			}
			
			.one_third, 
			.one_half {
				.preloader {
					padding-bottom:@preloader_portfolio_grid_pad;
					
					&.highImg {
						height:auto;
						padding:0;
					}
				}
			}
		}
		
		> .cmsmasters_img_wrap {
			.ovh;
		}
		
		> .cmsmasters_owl_slider {
			outline:none;
			
			figure {
				.ovh;
			}
		}
		
		> .cmsmasters_video_wrap {
			outline:none;
		}
	}
	
	.cmsmasters_project_content {
		padding-top:20px;
		position:relative;
		.ovh;
	}
	
	.project_sidebar {
		width:@sidebar_width;
		padding:@cont_vert_pad 0 0 (@cont_sidebar_pad + 10px);
		.fr;
		
		> div {
			outline:none;
			margin:0 0 45px;
			
			&:last-child {
				margin:0;
			}
		}
	}
	
	.project_details, 
	.project_features {
		position:relative;
		.ovh;
	}
	
	.project_details_title, 
	.project_features_title {
		margin:0 0 20px;
		padding:0;
	}
	
	.project_details_item, 
	.project_features_item {
		position:relative;
		margin:0 0 10px;
		.ovh;
	}
	
	.project_details_item_title, 
	.project_features_item_title {
		max-width:50%;
		.fl;
		.ovh;
	}
	
	.project_details_item_desc, 
	.project_features_item_desc {
		padding:0 0 0 15px;
		word-wrap:break-word;
		.tar;
		.ovh;
	}
}



/*-------------------------------------------------------------------------*/
/*	Posts Slider Styles
/*-------------------------------------------------------------------------*/

/***************** Posts Slider ******************/
.cmsmasters_posts_slider {
	margin:0 -20px;
	position:relative;
	.ovh;
	
	.owl-buttons {
		position:static;
		width:0;
		height:0;
		
		> div {
			display:block;
			width:44px;
			height:44px;
			margin:0;
			position:absolute;
			top:50%;
			margin-top:-22px;
			.ovh;
			.tac;
			
			&.owl-prev {
				left:20px;
			}
			
			&.owl-next {
				right:20px;
			}
			
			> span {
				display:block;
				height:44px;
				width:auto;
			}
		}
	}
	
	.preloader {
		padding-bottom:@preloader_posts_slider_pad;
		
		&.highImg {
			height:auto;
			padding:0;
		}
	}
	
	.cmsmasters_slider_post, 
	.cmsmasters_slider_project {
		width:100%;
		min-width:260px;
		max-width:100%;
		padding:0 20px 20px;
		.fl;
	}
}


/***************** Posts Slider Blog ******************/
.cmsmasters_slider_post {
	position:relative;
	
	.cmsmasters_slider_post_outer {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_slider_post_date_img_wrap {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_slider_post_date {
		position:absolute;
		width:60px;
		height:60px;
		padding:11px 12px;
		top:20px;
		right:0;
		z-index:1;
		
		.published {
			display:block;
		}
		
		.cmsmasters_day {
			display:block;
		}
		
		.cmsmasters_mon {
			display:block;
		}
	}
	
	.cmsmasters_slider_post_inner {
		position:relative;
		padding:20px;
		.ovh;
	}
	
	.cmsmasters_slider_post_header {
		padding:0 0 3px;
		
		.cmsmasters_slider_post_title {
			margin:0;
		}
	}
	
	.cmsmasters_slider_post_cont_info {
		.ovh;
		
		> span {
			display:block;
		}
		
		.cmsmasters_slider_post_author + .cmsmasters_slider_post_category {
			margin-top:-3px;
		}
	}
	
	.cmsmasters_slider_post_content {
		padding:20px 0;
		.ovh;
		
		p {
			margin:0;
			padding:0;
		}
	}
	
	.cmsmasters_slider_post_footer {
		.ovh;
		
		> span {
			outline:none;
		}
	}
	
	.cmsmasters_slider_post_footer_inner {
		margin-left:20px;
		.fr;
		
		.cmsmasters_slider_post_likes + .cmsmasters_slider_post_comments {
			margin-left:13px;
		}
	}
	
	.cmsmasters_slider_post_read_more {
		outline:none;
	}
}


/***************** Posts Slider Portfolio ******************/
.cmsmasters_slider_project {
	position:relative;
	
	.cmsmasters_slider_project_outer {
		position:relative;
		.ovh;
		
		&:hover {
			.cmsmasters_img_rollover {
				.op(1);
				
				.cmsmasters_open_post_link {
					.top_angle {
						left:0;
					}
					
					.bot_angle {
						right:0;
					}
				}
			}
			
			.cmsmasters_slider_project_header, 
			.cmsmasters_slider_project_cont_info {
				a:hover {
					.op(@hover_opacity);
				}
			}
			
			.cmsmasters_slider_project_footer {
				a.active:before,
				a:hover:before {
					.op(@hover_opacity);
				}
			}
		}
	}
	
	.cmsmasters_slider_project_inner {
		padding:25px 20px;
		position:relative;
		
		&:before {
			content:'';	
			position:absolute;
			left:50%;
			top:-9px;
			margin-left:-9px;
			.arrow_top(9px; 9px);
		}
		
		> *:last-child {
			padding-bottom:0;
		}
	}
	
	.cmsmasters_slider_project_header {
		padding:0;
		.tac;
		
		.cmsmasters_slider_project_title {
			margin:0;
		}
	}
	
	.cmsmasters_slider_project_cont_info {
		.ovh;
		.tac;
	}
	
	.cmsmasters_slider_project_content {
		padding:15px 0;
		.ovh;
		.tac;
		
		p {
			margin:0;
			padding:0;
		}
	}
	
	.cmsmasters_slider_project_footer {
		.ovh;
		.tac;
		
		> span {
			outline:none;
			margin:0 7px;
		}
	}
}



/*-------------------------------------------------------------------------*/
/*	Profiles + Profile Styles
/*-------------------------------------------------------------------------*/

/***************** Profiles ******************/
.cmsmasters_profile {
	position:relative;
	.ovh;
	
	&.horizontal {
		margin:-40px -@col_mar 0;
		
		.cmsmasters_profile_horizontal {
			margin-top:40px;
		}
	}
}


/***************** Profiles Horizontal ******************/
.cmsmasters_profile_horizontal {
	position:relative;
	
	.profile_outer {
		position:relative;
		.ovh;
		
		&:hover {
			.profile_social_icons_list {
				top:0;
				visibility:visible;
				.op(1);
			}
			
			.cmsmasters_img_wrap > a:before {
				.op(1);
			}
		}
	}
	
	.profile_img_wrapper {
		position:relative;
	}
	
	.cmsmasters_img_wrap {
		.tac;
		.ovh;
		
		> a {
			&:before {
				content:'';
				position:absolute;
				top:0;
				bottom:0;
				right:0;
				left:0;
				.op(0);
			}
		}
		
		img {
			width:100%;
			outline:none;
		}
	}
	
	.img_placeholder {
		position:relative;
		padding-bottom:@preloader_profiles_placeholder_pad;
		
		&:before {
			font-size:40px;
			.pos_abs;
		}
	}
	
	.profile_inner {
		position:relative;
		padding:25px 20px;
		
		&:before {
			content:'';	
			position:absolute;
			left:50%;
			top:-8px;
			margin-left:-8px;
			.arrow_top(8px; 8px);
		}
		
		> *:last-child {
			padding-bottom:0;
		}
	}
	
	.cmsmasters_profile_header {
		padding:0 0 25px 0;
		.tac;
		
		.cmsmasters_profile_title {
			margin:0;
			
			a:hover {
				opacity:@hover_opacity;
			}
		}
		
		.cmsmasters_profile_subtitle {
			margin:0;
			padding-top:3px;
		}
	}
	
	.cmsmasters_profile_content {
		position:relative;
		.tac;
		.ovh;
		
		p {
			margin:0;
			padding:0;
		}
	}
	
	.profile_social_icons {
		display:block;
		width:100%;
		height:100%;
		margin:auto !important;
		position:absolute;
		left:0;
		right:0;
		top:0;
		bottom:0;
		.tac;
	}
	
	.profile_social_icons_list {
		padding:0 20px;
		display:block;
		width:100%;
		height:26px;
		margin:auto !important;
		position:absolute;
		left:0;
		right:0;
		top:20px;
		bottom:0;
		visibility:hidden;
		.tac;
		.op(0);
		
		li {
			display:inline-block;
			vertical-align:middle;
			margin:0 2px;
			padding:0;
			position:relative;
			
			&:before {
				display:none;
			}
		}
		
		a {
			font-size:16px;
			line-height:16px;
			display:block;
			width:26px;
			height:26px;
			position:relative;
			
			&:before {
				.pos_abs;
			}
		}
	}
}


/***************** Profiles Vertical ******************/
.cmsmasters_profile_vertical {
	margin:0 0 50px;
	position:relative;
	
	&:last-child {
		margin:0;
	}
	
	.profile_outer {
		padding:0;
		position:relative;
		.ovh;
	}
	
	.cmsmasters_img_wrap {
		display:inline-block;
		width:22.5%;
		vertical-align:top;
		
		> a {
			&:before {
				content:'';
				position:absolute;
				top:0;
				bottom:0;
				right:0;
				left:0;
				.op(0);
			}
		}
		
		&:hover {
			> a:before {
				.op(1);
			}
		}
		
		img {
			outline:none;
		}
	}
	
	.profile_inner {
		display:block;
		width:100%;
	}
	
	.cmsmasters_img_wrap + .profile_inner {
		display:inline-block;
		width:77.5%;
		padding:0 0 0 40px;
		vertical-align:top;
	}
	
	.cmsmasters_profile_header {
		padding:0;
		
		.cmsmasters_profile_title {
			margin:0;
		}
		
		.cmsmasters_profile_subtitle {
			margin:0;
			padding-top:3px;
		}
	}
	
	.cmsmasters_profile_content {
		position:relative;
		padding:25px 0;
		.ovh;
		
		p {
			margin:0;
			padding:0;
		}
	}
	
	.profile_social_icons {
		position:relative;
		padding-top:15px;
		.ovh;
	}
	
	.profile_social_icons_list {
		list-style:none;
		display:block;
		margin:0;
		padding:0;
		
		li {
			display:inline-block;
			margin:0 10px 0 0;
			padding:0;
			position:relative;
			vertical-align:middle;
			
			&:before {
				display:none;
			}
		}
		
		a {
			font-size:18px;
			line-height:18px;
			display:block;
			width:26px;
			height:26px;
			position:relative;
			
			&:before {
				.pos_abs;
			}
		}
	}
}


/***************** Profiles Open Profile ******************/
.cmsmasters_open_profile {
	padding:0 0 40px;
	position:relative;
	.ovh;
	
	.cmsmasters_profile_likes, 
	.cmsmasters_profile_comments {
		outline:none;
	}
	
	.cmsmasters_profile_header {
		padding:35px 0 0;
		margin-bottom:-40px;
		.ovh;
		.cl;
		
		.cmsmasters_profile_title {
			margin:0;
		}
		
		.cmsmasters_profile_subtitle {
			margin:5px 0 0;
		}
	}
	
	.profile_content {
		width:100%;
		padding:@cont_vert_pad 0 0;
		.ovh;
		
		&.with_sidebar {
			width:@content_width;
			.fl;
		}
	}
	
	.cmsmasters_profile_content {
		position:relative;
		.ovh;
	}
	
	.profile_sidebar {
		width:@sidebar_width;
		padding:@cont_vert_pad 0 0 50px;
		.fr;
		
		> div {
			outline:none;
			margin:0 0 45px;
			
			&:last-child {
				margin:0;
			}
		}
	}
	
	.profile_details, 
	.profile_features {
		position:relative;
		.ovh;
	}
	
	.profile_details_title, 
	.profile_features_title {
		margin:0 0 20px;
		padding:0;
	}
	
	.profile_details_item, 
	.profile_features_item {
		position:relative;
		margin:0 0 10px;
		.ovh;
	}
	
	.profile_details_item_title, 
	.profile_features_item_title {
		max-width:50%;
		.fl;
		.ovh;
	}
	
	.profile_details_item_desc, 
	.profile_features_item_desc {
		padding:0 0 0 15px;
		word-wrap:break-word;
		.tar;
		.ovh;
	}
	
	.profile_social_icons {
		position:relative;
		.ovh;
	}
	
	.profile_social_icons_title {
		margin:0;
		padding:0 0 30px 0;
	}
	
	.profile_social_icons_list {
		list-style:none;
		display:block;
		margin:0;
		padding:0;
		
		li {
			display:inline-block;
			vertical-align:middle;
			margin:0 10px 0 0;
			padding:0;
			position:relative;
			
			&:last-child {
				margin:0;
			}
			
			&:before {
				display:none;
			}
		}
		
		a {
			font-size:18px;
			line-height:18px;
			display:block;
			width:26px;
			height:26px;
			position:relative;
			
			&:before {
				.pos_abs;
			}
		}
	}
}



/*-------------------------------------------------------------------------*/
/*	Shortcodes Styles */
/*-------------------------------------------------------------------------*/

/***************** Divider ******************/
hr {
	border:0;
	border-bottom-style:solid;
	margin:(@line_height_pad * 2) 0;
}

hr, 
.cmsmasters_widget_divider,
.cmsmasters_divider {
	background-color:transparent;
	border-bottom-width:1px;
	width:100%;
	height:1px;
	position:relative;
	.cl;
}

.cmsmasters_widget_divider {
	margin:0 @col_mar (@line_height_pad * 2);
	width:round(100% - (@col_mar * 2), 2);
}

.cmsmasters_widget_divider {
	&.solid {
		border-bottom-style:solid;
	}
	
	&.dashed {
		border-bottom-style:dashed;
	}
	
	&.dotted {
		border-bottom-style:dotted;
	}
	
	&.transparent {
		border-bottom-width:0;
	}
}

.cmsmasters_divider {
	&.cmsmasters_divider_width_short {
		width:20%;
		
		&.cmsmasters_divider_pos_center {
			left:40%;
		}
		
		&.cmsmasters_divider_pos_right {
			left:80%;
		}
	}
	
	&.cmsmasters_divider_width_medium {
		width:40%;
		
		&.cmsmasters_divider_pos_center {
			left:30%;
		}
		
		&.cmsmasters_divider_pos_right {
			left:60%;
		}
	}
	
	&.cmsmasters_divider_width_long {
		width:100%;
	}
}


/***************** Special Heading ******************/
.cmsmasters_heading_wrap {
	position:relative;
	overflow:hidden;
	
	.cmsmasters_heading_divider {
		display:inline-block;
		height:2px;
		position:absolute;
		top:50%;
	}
	
	.cmsmasters_heading_divider_left_wrap, 
	.cmsmasters_heading_divider_right_wrap {
		display:inline-block;
		width:0;
	}
	
	&.cmsmasters_heading_divider_short {
		.cmsmasters_heading_divider {
			width:10%;
		}
		
		.cmsmasters_heading_divider_left {
			margin-left:-10%;
			margin-right:0;
		}
	}
	
	&.cmsmasters_heading_divider_medium {
		.cmsmasters_heading_divider {
			width:20%;
		}
		
		.cmsmasters_heading_divider_left {
			margin-left:-20%;
			margin-right:0;
		}
	}
	
	&.cmsmasters_heading_divider_long {
		.cmsmasters_heading_divider {
			width:100%;
		}
		
		.cmsmasters_heading_divider_left {
			margin-left:-100%;
			margin-right:0;
		}
	}
	
	&.cmsmasters_heading_divider_short, 
	&.cmsmasters_heading_divider_medium, 
	&.cmsmasters_heading_divider_long {
		.cmsmasters_heading {
			max-width:80%;
		}
	}
	
	.cmsmasters_heading {
		display:inline-block;
		margin:0;
		
		&:before, 
		a:before {
			margin-left:0;
			margin-right:0.5em;
		}
	}
}


/***************** Featured Block ******************/
.cmsmasters_featured_block {
	position:relative;
	.ovh;
	
	.featured_block_inner {
		width:100%;
		padding:20px 20px 0;
		position:relative;
		.ovh;
	}
}


/***************** Table ******************/
.cmsmasters_wrap_table {
	overflow:auto;
}

.cmsmasters_table {
	width:100%;
	
	caption {
		outline:none;
	}
	
	tr {
		td, 
		th {
			padding:14px 30px;
			
			&.cmsmasters_table_cell_aligncenter {
				text-align:center;
			}
			
			&.cmsmasters_table_cell_alignleft {
				text-align:left;
			}
			
			&.cmsmasters_table_cell_alignright {
				text-align:right;
			}
		}
		
		th {
			padding:20px 30px;
		}
		
		&.cmsmasters_table_row_footer {
			td {
				padding:20px 30px;
			}
		}
	}
	
	tbody tr {
		.bdb;
		
		&:last-child {
			.bdb(0);
		}
	}
}


/***************** Notice ******************/
.cmsmasters_notice {
	display:block;
	width:100%;
	padding:0 20px;
	margin-bottom:@line_height_pad;
	position:relative;
	
	&:after {
		.cl_after;
	}
	
	&[class*=" cmsmasters-icon-"], 
	&[class*=" cmsmasters_theme_icon_"] {
		padding-left:80px;
		padding-right:48px;
	}
	
	&:before {
		font-size:32px;
		.pos_abs(1em, 1em, 20px, auto);
	}
	
	.notice_content {
		position:relative;
		padding:@line_height_pad 0 0;
	}
	
	.notice_close {
		font-size:12px;
		line-height:12px;
		display:block;
		.pos_abs(28px, 28px, auto, 10px, 10px, auto);
		.bdrs(50%);
		
		&:before {
			.pos_abs;
		}
	}
	
	&.cmsmasters_notice_success {
		color:#878787;
		background-color:#e9fdc8;
		
		&:before {
			color:#ffffff;
		}
	}
	
	&.cmsmasters_notice_error {
		color:#878787;
		background-color:#ffebe5;
		
		&:before {
			color:#ffffff;
		}
	}
	
	&.cmsmasters_notice_info {
		color:#878787;
		background-color:#e6f1ff;
		
		&:before {
			color:#ffffff;
		}
	}
	
	&.cmsmasters_notice_warning {
		color:#878787;
		background-color:#fff2d2;
		
		&:before {
			color:#ffffff;
		}
	}
	
	&.cmsmasters_notice_download {
		color:#878787;
		background-color:#f9fecb;
		
		&:before {
			color:#ffffff;
		}
	}
}


/***************** Icon ******************/
.cmsmasters_icon_wrap {
	.cmsmasters_simple_icon {
		border-style:solid;
		display:inline-block;
	}
}


/***************** Icon List ******************/
.cmsmasters_icon_list_items {
	list-style:none;
	padding:0;
	margin:0;
	position:relative;
	
	.cmsmasters_icon_list_item {
		&:before {
			left:50px;
			right:auto;
		}
		
		.cmsmasters_icon_list_icon {
			width:100px;
			height:100px;
			margin:1px;
			position:relative;
			
			&:before {
				font-size:32px;
				line-height:80px;
			}
			
			&:after {
				content:'';
				border-width:10px;
				border-style:solid;
				width:102px;
				height:102px;
				position:absolute;
				top:-1px;
				left:-1px;
			}
		}
		
		.cmsmasters_icon_list_item_title {
			word-wrap:break-word;
			margin-bottom:10px;
		}
	}
	
	&.cmsmasters_color_type_icon {
		.cmsmasters_icon_list_item {
			.ovh;
		}
	}
	
	&.cmsmasters_icon_list_pos_right {
		.cmsmasters_icon_list_item {
			&:before {
				left:auto;
				right:50px;
			}
		}
	}
	
	&.cmsmasters_icon_list_type_list {
		padding-left:30px;
		
		.cmsmasters_icon_list_item {
			&:before {
				left:-32px;
			}
		}
	}
	
	&.cmsmasters_icon_list_type_block {
		padding:0;
		margin:0;
		
		.cmsmasters_icon_list_item {
			display:block;
			width:100%;
			padding:0 0 50px;
			position:relative;
			.ovh;
			
			&:before {
				content:'';
				display:block;
				width:1px;
				height:0;
				padding:0;
				position:absolute;
				top:40px;
				z-index:1;
				.op(0);
			}
			
			&:after {
				.cl_after;
			}
			
			&:last-child {
				padding-bottom:0;
				margin-bottom:0;
				
				&:before {
					.dn;
				}
			}
			
			&.cmsmasters_icon_type_image {
				.cmsmasters_icon_list_icon {
					background-position:center center;
					background-repeat:no-repeat;
					background-size:cover;
				}
			}
			
			.cmsmasters_icon_list_icon {
				display:block;
				text-align:center;
				z-index:2;
				-webkit-transform:scale(.5);
				-moz-transform:scale(.5);
				transform:scale(.5);
				.op(.3);
			}
			
			&.shortcode_animated {
				&:before {
					height:100%;
					.op(1);
				}
				
				.cmsmasters_icon_list_icon {
					.op(1);
					-webkit-transform:scale(1);
					-moz-transform:scale(1);
					transform:scale(1);
				}
			}
		}
		
		.cmsmasters_icon_list_item_content {
			.ovh;
		}
		
		&.cmsmasters_icon_list_pos_left {
			.cmsmasters_icon_list_item_content {
				.tal;
				
				.cmsmasters_icon_list_item_text {
					.tal;
				}
			}
			
			.cmsmasters_icon_list_icon_wrap {
				padding-right:30px;
				.fl;
			}
		}
		
		&.cmsmasters_icon_list_pos_right {
			.cmsmasters_icon_list_item_content {
				.tar;
				
				.cmsmasters_icon_list_item_text {
					.tar;
				}
			}
			
			.cmsmasters_icon_list_icon_wrap {
				padding-left:30px;
				.fr;
			}
		}
	}
}


/***************** Icon Box ******************/
.cmsmasters_icon_box {
	border-style:solid;
	border-color:transparent;
	position:relative;
	
	&:before, 
	.icon_box_heading:before {
		border-style:solid;
		text-align:center;
	}
	
	&.box_icon_type_image, 
	&.box_icon_type_number {
		&:before {
			content:'';
			background-position:center center;
			background-repeat:no-repeat;
			background-size:cover;
		}
		
		&.cmsmasters_icon_heading_left {
			&:before {
				content:none;
			}
			
			.icon_box_heading {
				&:before {
					content:'\00a0';
					background-position:center center;
					background-repeat:no-repeat;
					background-size:cover;
					display:inline-block;
					.tac;
				}
			}
		}
	}
	
	&.cmsmasters_icon_top {
		&:before {
			font-size:0;
			position:absolute;
			left:50%;
			top:30px;
			-webkit-transform:scale(0);
			-moz-transform:scale(0);
			transform:scale(0);
			.op(0);
		}
		
		&.shortcode_animated {
			&:before {
				-webkit-transform:scale(1);
				-moz-transform:scale(1);
				transform:scale(1);
				.op(1);
			}
		}
		
		.icon_box_inner {
			.tac;
			
			.icon_box_text {
				.tac;
			}
		}
	}
	
	&.cmsmasters_icon_box_top {
		border-width:1px;
		padding:20px 20px 30px;
		
		&:before {
			font-size:0;
			position:absolute;
			left:50%;
			top:35px;
			-webkit-transform:scale(0);
			-moz-transform:scale(0);
			transform:scale(0);
			.op(0);
		}
		
		&[class*=" cmsmasters-icon-"], 
		&[class*=" cmsmasters_theme_icon_"] {
			padding-top:120px;
		}
		
		&.shortcode_animated {
			&:before {
				-webkit-transform:scale(1);
				-moz-transform:scale(1);
				transform:scale(1);
				.op(1);
			}
		}
		
		.icon_box_inner {
			.tac;
			
			.icon_box_text {
				.tac;
			}
		}
	}
	
	&.cmsmasters_icon_box_left {
		border-width:1px;
		padding:20px 40px;
		
		&:before {
			font-size:24px;
			line-height:45px;
			width:45px;
			height:45px;
			margin:0;
			position:absolute;
			left:0;
			top:50%;
			-webkit-transform:scale(0);
			-moz-transform:scale(0);
			transform:scale(0);
			.tac;
			.op(0);
		}
		
		&.shortcode_animated {
			&:before {
				-webkit-transform:scale(1);
				-moz-transform:scale(1);
				transform:scale(1);
				.op(1);
			}
		}
	}
	
	&.cmsmasters_icon_box_left_top {
		padding-left:60px;
		
		&:before {
			font-size:24px;
			line-height:45px;
			width:45px;
			height:45px;
			margin:0;
			position:absolute;
			left:0;
			top:0;
			-webkit-transform:scale(0);
			-moz-transform:scale(0);
			transform:scale(0);
			.tac;
			.op(0);
		}
		
		&.shortcode_animated {
			&:before {
				-webkit-transform:scale(1);
				-moz-transform:scale(1);
				transform:scale(1);
				.op(1);
			}
		}
	}
	
	&.cmsmasters_icon_heading_left {
		h1, 
		h2, 
		h3, 
		h4, 
		h5, 
		h6 {
			margin-bottom:10px;
			
			&:before {
				margin-bottom:10px;
				margin-left:0;
				margin-right:15px;
				vertical-align:inherit;
				-webkit-transform:scale(0);
				-moz-transform:scale(0);
				transform:scale(0);
				.op(0);
			}
		}
		
		&.shortcode_animated {
			h1, 
			h2, 
			h3, 
			h4, 
			h5, 
			h6 {
				&:before {
					-webkit-transform:scale(1);
					-moz-transform:scale(1);
					transform:scale(1);
					.op(1);
				}
			}
		}
	}
	
	.icon_box_text {
		margin-bottom:10px;
	}
}


/***************** Toggles ******************/
.cmsmasters_toggles {
	position:relative;
	
	.cmsmasters_toggles_filter {
		padding:0 0 15px;
		position:relative;
		
		a {
			cursor:pointer;
			
			&.current_filter {
				cursor:default;
			}
		}
	}
	
	.cmsmasters_toggle_wrap {
		position:relative;
	}
	
	.cmsmasters_toggle_title {
		position:relative;
		
		&:before {
			content:'';
			display:block;
			position:absolute;
			bottom:0;
			top:auto;
			left:50%;
			margin-right:-10px;
			z-index:1;
			.arrow_bottom(10px; 10px);
		}
		
		a {
			display:block;
			position:relative;
			z-index:2;
		}
	}
	
	.cmsmasters_toggle_plus {
		display:block;
		z-index:1;
		.pos_abs(12px, 12px);
		
		span {
			display:block;
			.pos_abs(12px, 2px);
			
			&.cmsmasters_toggle_plus_vert {
				width:2px;
				height:12px;
			}
		}
	}
	
	.cmsmasters_toggle {
		.dn;
	}
	
	.cmsmasters_toggle_inner {
		position:relative;
		padding:30px;
		.ovh;
		
		> *:last-child {
			margin-bottom:0;
			padding-bottom:0;
		}
	}
	
	.cmsmasters_toggle_wrap.current_toggle {
		> .cmsmasters_toggle_title {
			&:before {
				bottom:-10px;
			}	
			
			.cmsmasters_toggle_plus_vert {
				height:0;
			}
		}
		
		> .cmsmasters_toggle {
			display:block;
		}
	}
	
	&.toggles_mode_toggle {
		.cmsmasters_toggle_wrap {
			margin:0 0 10px;
			
			&:last-child {
				margin:0;
			}
		}
		
		.cmsmasters_toggle_title a {
			padding:15px 20px 15px 60px;
		}
		
		.cmsmasters_toggle_plus {
			left:30px;
			right:auto;
		}
	}
	
	&.toggles_mode_accordion {
		.cmsmasters_toggle_wrap {
			margin:0 0 1px;
			outline:none;
		}
		
		.cmsmasters_toggle_title a {
			padding:15px 0 15px 60px;
		}
		
		.cmsmasters_toggle_plus {
			left:30px;
			right:auto;
		}
	}
}


/***************** Tabs ******************/
.cmsmasters_tabs {
	position:relative;
	
	&:after {
		.cl_after;
	}
	
	.cmsmasters_tabs_list {
		list-style:none;
		margin:0;
		padding:0;
		position:relative;
		
		&:after {
			.cl_after;
		}
	}
		
	.cmsmasters_tabs_list_item {
		display:block;
		margin:0;
		padding:0;
		
		&:before {
			.dn;
		}
		
		a {
			display:block;
			padding:15px 30px;
			position:relative;
			cursor:pointer;
			
			&[class^="cmsmasters-icon-"], 
			&[class^="cmsmasters_theme_icon_"] {
				padding:15px 20px 15px 65px;
			}
			
			&:before {
				font-size:21px;
				line-height:20px;
				.pos_abs(1em, 1em, 30px, auto);
			}
		}
		
		&.current_tab, 
		&:hover {
			a {
				outline:none;
			}
		}
	}
	
	.cmsmasters_tabs_wrap {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_tab {
		.dn;
		
		&:after {
			.cl_after;
		}
		
		&.active_tab {
			display:block;
		}
	}
	
	.cmsmasters_tab_inner {
		position:relative;
		.ovh;
		
		> *:last-child {
			margin-bottom:0;
			padding-bottom:0;
		}
	}
	
	&.tabs_mode_tab {
		.cmsmasters_tabs_list {
			display:table;
			width:100%;
		}
	
		.cmsmasters_tabs_list_item {
			display:table-cell;
			.bdr;
			
			&:before {
				content:'';
				display:block;
				position:absolute;
				top:auto;
				bottom:0;
				left:50%;
				margin-left:-15px;
				z-index:-1;
				.arrow_bottom(15px; 15px);
			}
			
			&.current_tab {
				&:before {
					bottom:-15px;
				}
			}
			
			&:last-child {
				margin:0;
				.bdr(0);
			}
			
			a {
				padding:30px 10px;
				.tac;
				
				&:before {
					bottom:auto;
					font-size:38px;
					line-height:50px;
					width:50px;
					height:50px;
					top:35px;
					left:50%;
					margin-left:-25px !important;
				}
			
				&[class^="cmsmasters-icon-"], 
				&[class^="cmsmasters_theme_icon_"] {
					padding:95px 10px 30px 10px;
				}
			}
		}
		
		.cmsmasters_tab_inner {
			padding:65px 0 0;
			.tac;
		}
	}
	
	&.tabs_mode_tour {
		display:table;
		
		.cmsmasters_tabs_list {
			display:table-cell;
			width:31%;
			vertical-align:top;
		}
		
		.cmsmasters_tabs_list_item {
			margin:0 0 1px;
				
			&:before {
				content:'';
				display:block;
				position:absolute;
				right:0;
				left:auto;
				top:50%;
				margin-top:-13px;
				z-index:-1;
				border-right:0 solid transparent;
				border-top:13px solid transparent;
				border-bottom:13px solid transparent;
				border-left-width:13px;
				border-left-style:solid;
			}
			
			&.current_tab {
				&:before {
					right:-13px;	
				}
			}
			
			&:last-child {
				margin:0;
			}
		}
		
		.cmsmasters_tabs_wrap {
			display:table-cell;
			width:69%;
			vertical-align:top;
		}
		
		.cmsmasters_tab_inner {
			padding:0 0 0 40px;
		}
		
		&.tabs_pos_right {
			direction:rtl;
			
			.cmsmasters_tabs_list {
				text-align:right;
				direction:ltr;
			}
			
			.cmsmasters_tabs_list_item {
				&:before {
					right:auto;
					left:0;
					border-left:0 solid transparent;
					border-top:13px solid transparent;
					border-bottom:13px solid transparent;
					border-right-width:13px;
					border-right-style:solid;
				}
				
				&.current_tab {
					&:before {
						right:auto;
						left:-13px;	
					}
				}
			
				a {
					&[class^="cmsmasters-icon-"], 
					&[class^="cmsmasters_theme_icon_"] {
						padding:15px 65px 15px 30px;
					}
					
					&:before {
						left:auto;
						right:30px;
					}
				}
			}
			
			.cmsmasters_tabs_wrap {
				direction:ltr;
			}
			
			.cmsmasters_tab_inner {
				padding:0 40px 0 0;
				.tar;
			}
		}
	}
}


/***************** Stats ******************/
.cmsmasters_stats {
	position:relative;
	.tac;
	
	&:after {
		.cl_after;
	}
	
	.cmsmasters_stat_wrap {
		.cmsmasters_stat {
			position:relative;
			
			.cmsmasters_stat_inner {
				display:block;
				position:relative;
			}
		}
	}
	
	&.stats_mode_bars {
		.cmsmasters_stat_wrap {
			.cmsmasters_stat_container {
				width:100%;
				height:220px;
				margin:0;
				position:relative;
			}
			
			.cmsmasters_stat {
				width:0;
				.ovh;
				
				.cmsmasters_stat_inner {
					.cmsmasters_stat_title {
						position:absolute;
						left:15px;
						top:10px;
						padding-right:33px;
						
						&[class^="cmsmasters-icon-"], 
						&[class*=" cmsmasters-icon-"], 
						&[class^="cmsmasters_theme_icon_"], 
						&[class*=" cmsmasters_theme_icon_"] {
							&:before {
								font-size:22px;
								margin:0;
								position:absolute;
								right:0;
								bottom:0;
							}
						}
					}
				}
			}
			
			canvas {
				.dn;
			}
		}
		
		&.stats_type_vertical {
			margin:0 -@col_mar;
			
			.cmsmasters_stat_wrap {
				.cmsmasters_stat_container {
					height:227px;
				}
			
				.cmsmasters_stat {
					width:100%;
					height:0;
					position:absolute;
					bottom:0;
					left:0;
					
					.cmsmasters_stat_inner {
						height:100%;
						padding:15px 20px;
						.tac;
						
						&:before {
							font-size:24px;
							line-height:30px;
							margin:0;
							.fr;
						}
						
						.cmsmasters_stat_title_counter_wrap {
							width:100%;
							padding:0 0 13px;
							position:absolute;
							left:0;
							bottom:100%;
							.ovh;
						}
						
						.cmsmasters_stat_title {
							display:block;
							padding:0 10px 0 0;
							position:static;
							.ovh;
						}
						
						.cmsmasters_stat_counter_wrap {
							.fl;
						}
					}
				}
				
				.cmsmasters_stat_title {
					padding:0 20px;
					display:block;
				}
				
				.cmsmasters_stat_subtitle {
					padding:0 20px;
					display:block;
				}
				
				> span:first-of-type {
					padding-top:15px;
				}
				
				> span:last-of-type {
					padding-bottom:15px;
				}
			}
		}
		
		&.stats_type_horizontal {
			.cmsmasters_stat_wrap {
				padding-left:60px;
				padding-bottom:25px;
				position:relative;
				
				&:last-child {
					padding-bottom:0;
				}
				
				&:before {
					content:'';
					display:block;
					height:10px;
					position:absolute;
					top:30px;
					left:60px;
					right:0;
				}
				
				.cmsmasters_stat {
					min-height:40px;
					
					.cmsmasters_stat_inner {
						width:100%;
						height:10px;
						position:absolute;
						bottom:0;
						left:0;
						
						&:before, 
						.cmsmasters_stat_title {
							left:0;
							top:auto;
							bottom:16px;
						}
						
						&[class^="cmsmasters-icon-"], 
						&[class*=" cmsmasters-icon-"], 
						&[class^="cmsmasters_theme_icon_"], 
						&[class*=" cmsmasters_theme_icon_"] {
							.cmsmasters_stat_title {
								left:30px;
							}
						}
					}
				}
				
				.cmsmasters_stat_counter_wrap {
					position:absolute;
					left:0;
					top:2px;
					max-width:50px;
					.ovh;
				}
				
				.cmsmasters_stat_subtitle {
					display:inline-block;
					padding:12px 0 0;
				}
			}
		}
	}
	
	&.stats_mode_circles {
		margin-top:-40px;
		
		.cmsmasters_stat_wrap {
			display:inline-block;
			float:none;
			margin:0;
			padding:40px 10px 0;
			vertical-align:top;
			.tac;
			
			&.one_fifth {
				width:20%;
			}
			
			&.one_fourth {
				width:25%;
			}
			
			&.one_third {
				width:33.3%;
			}
			
			&.one_half {
				width:50%;
			}
			
			&.one_first {
				width:100%;
			}
			
			.cmsmasters_stat {
				display:inline-block;
				width:200px;
				height:200px;
				margin:0 0 25px;
				
				.cmsmasters_stat_inner {
					width:174px;
					height:174px;
					padding-top:60px;
					position:absolute;
					left:13px;
					top:13px;
					z-index:2;
					.tac;
					.bdrs(50%);
					
					&:before {
						font-size:36px;
						line-height:36px;
					}
					
					.cmsmasters_stat_counter_wrap {
						display:block;
						.tac;
					}
				}
				
				canvas {
					width:200px !important;
					height:200px !important;
					position:relative;
					z-index:1;
					.bdrs(50%);
				}
				
				&.stat_has_titleicon {
					.cmsmasters_stat_inner {
						padding-top:40px;
					}
				}
			}
			
			.cmsmasters_stat_title {
				display:block;
				padding:0;
				.tac;
			}
			
			.cmsmasters_stat_subtitle {
				display:block;
				padding:15px 0 0;
				.tac;
			}
		}
	}
}


/***************** Counters ******************/
.cmsmasters_counters {
	position:relative;
	.tac;
	
	&:after {
		.cl_after;
	}
	
	canvas {
		.dn;
	}
	
	.cmsmasters_counter_wrap {
		display:inline-block;
		padding:0 2px;
		margin:0;
		float:none;
		vertical-align:top;
		.tac;
		
		&.one_fifth {
			width:20%;
		}
		
		&.one_fourth {
			width:25%;
		}
		
		&.one_third {
			width:33.3%;
		}
		
		&.one_half {
			width:50%;
		}
		
		&.one_first {
			width:100%;
		}
		
		.cmsmasters_counter {
			min-height:100px;
			padding:0 20px;
			margin:0 0 @line_height_pad;
			position:relative;
			.ovh;
			
			.cmsmasters_counter_inner {
				display:block;
				margin:15px 0;
				position:relative;
				
				&:before {
					border-style:solid;
					position:absolute;
					left:0;
					top:0;
				}
				
				.cmsmasters_counter_title_wrap {
					display:block;
				}
				
				.cmsmasters_counter_title {
					display:block;
					padding:0 0 15px;
				}
				
				.cmsmasters_counter_counter_wrap {
					display:inline-block;
				}
			}
			
			&.counter_has_icon {
				.cmsmasters_counter_inner {
					&:before {
						margin-left:0;
						margin-right:0;
					}
				}
			}
			
			&.counter_has_image {
				.cmsmasters_counter_inner {
					&:before {
						background-position:center center;
						background-repeat:no-repeat;
						background-size:cover;
					}
				}
			}
		}
		
		.cmsmasters_counter_subtitle {
			display:block;
		}
	}
	
	&.counters_type_vertical {
		.cmsmasters_counter_wrap {
			.cmsmasters_counter {
				.tac;
				
				.cmsmasters_counter_inner {
					.tac;
					
					&:before {
						left:50%;
					}
					
					.cmsmasters_counter_counter_wrap {
						padding:10px 0 0;
					}
					
					.cmsmasters_counter_title {
						padding:7px 0 15px;
					}
				}
			}
			
			.cmsmasters_counter_subtitle {
				display:block;
				.tac;
			}
		}
	}
	
	&.counters_type_horizontal {
		.cmsmasters_counter_wrap {
			.cmsmasters_counter {
				.cmsmasters_counter_inner {
					&:before {
						margin:auto !important;
						left:0;
						right:auto;
						top:0;
						bottom:0;
					}
					
					&:after {
						.valign_after;
					}
					
					.cmsmasters_counter_title_wrap {
						display:inline-block;
						vertical-align:middle;
					}
				}
			}
		}
	}
}


/***************** CMSMASTERS Button ******************/
.cmsmasters_button {
	display:inline-block;
	padding:0 33px;
	cursor:pointer;
	position:relative;
	.bd(0);
	.bdrs(25px);
	
	&:before {
		line-height:inherit !important;
		position:relative;
		z-index:10;
	}
	
	span {
		position:relative;
		z-index:10;
	}
	
	&.cmsmasters_but_clear_styles {
		background:none;
		border:0;
		margin:0;
		padding:0 @line_height_pad;
		.ovh;
		.bdrs(0);
		.bxsh_none;
		
		&:hover {
			margin:0;
			.bxsh_none;
		}
		
		&:after {
			content:'';
			position:absolute;
		}
	}
	
	&.cmsmasters_but_bg_hover {
		.bd;
	}
	
	&.cmsmasters_but_bg_slide_left {
		.bd;
		
		&:after {
			width:0;
			height:100%;
			left:0;
			top:0;
		}
		
		&:hover {
			&:after {
				width:100%;
			}
		}
	}
	
	&.cmsmasters_but_bg_slide_right {
		.bd;
		
		&:after {
			width:0;
			height:100%;
			right:0;
			top:0;
		}
		
		&:hover {
			&:after {
				width:100%;
			}
		}
	}
	
	&.cmsmasters_but_bg_slide_top {
		.bd;
		
		&:after {
			width:100%;
			height:0;
			left:0;
			top:0;
		}
		
		&:hover {
			&:after {
				height:100%;
			}
		}
	}
	
	&.cmsmasters_but_bg_slide_bottom {
		.bd;
		
		&:after {
			width:100%;
			height:0;
			left:0;
			bottom:0;
		}
		
		&:hover {
			&:after {
				height:100%;
			}
		}
	}
	
	&.cmsmasters_but_bg_expand_vert {
		.bd;
		
		&:after {
			width:0;
			height:105%;
			left:50%;
			top:50%;
			.op(0);
			-webkit-transform:translateX(-50%) translateY(-50%);
			-moz-transform:translateX(-50%) translateY(-50%);
			-o-transform:translateX(-50%) translateY(-50%);
			-ms-transform:translateX(-50%) translateY(-50%);
			transform:translateX(-50%) translateY(-50%);
		}
		
		&:hover {
			&:after {
				width:90%;
				.op(1);
			}
		}
	}
	
	&.cmsmasters_but_bg_expand_hor {
		.bd;
		
		&:after {
			width:105%;
			height:0;
			left:50%;
			top:50%;
			.op(0);
			-webkit-transform:translateX(-50%) translateY(-50%);
			-moz-transform:translateX(-50%) translateY(-50%);
			-o-transform:translateX(-50%) translateY(-50%);
			-ms-transform:translateX(-50%) translateY(-50%);
			transform:translateX(-50%) translateY(-50%);
		}
		
		&:hover {
			&:after {
				height:75%;
				.op(1);
			}
		}
	}
	
	&.cmsmasters_but_bg_expand_diag {
		.bd;
		
		&:after {
			width:500%;
			height:0;
			left:50%;
			top:50%;
			.op(0);
			-webkit-transform:translateX(-50%) translateY(-50%) rotate(45deg);
			-moz-transform:translateX(-50%) translateY(-50%) rotate(45deg);
			-ms-transform:translateX(-50%) translateY(-50%) rotate(45deg);
			transform:translateX(-50%) translateY(-50%) rotate(45deg);
		}
		
		&:hover {
			&:after {
				height:170%;
				.op(1);
			}
		}
	}
	
	&.cmsmasters_but_shadow {
		padding-bottom:3px;
		.bxshi(0, -3px, 0, 0, .2);
		
		&:hover {
			padding-bottom:0;
			margin-top:3px;
			.bxshi(0, 0, 0, 0, .02);
		}
	}
	
	&.cmsmasters_but_icon_dark_bg, 
	&.cmsmasters_but_icon_light_bg, 
	&.cmsmasters_but_icon_divider, 
	&.cmsmasters_but_icon_inverse {
		&:before {
			margin:auto !important;
			position:absolute;
			left:0;
			top:0;
		}
		
		&:after {
			content:'';
			background-color:rgba(0, 0, 0, .2);
			height:100%;
			position:absolute;
			left:0;
			top:0;
		}
	}
	
	&.cmsmasters_but_icon_dark_bg, 
	&.cmsmasters_but_icon_light_bg, 
	&.cmsmasters_but_icon_divider {
		&:hover {
			&:before {
				-webkit-transform:scale(1.3);
				-moz-transform:scale(1.3);
				-ms-transform:scale(1.3);
				-o-transform:scale(1.3);
				transform:scale(1.3);
			}
		}
	}
	
	&.cmsmasters_but_icon_light_bg {
		&:after {
			background-color:rgba(255, 255, 255, .2);
		}
	}
	
	&.cmsmasters_but_icon_divider {
		&:after {
			background-color:transparent;
			.bdr;
		}
	}
	
	&.cmsmasters_but_icon_inverse {
		.bd;
	
		&:after {
			background-color:transparent;
		}
	}
	
	&.cmsmasters_but_icon_slide_left, 
	&.cmsmasters_but_icon_slide_right {
		padding-left:(@line_height_pad * 2);
		padding-right:(@line_height_pad * 2);
		.bd;
	}
	
	&.cmsmasters_but_icon_slide_left {
		&:before {
			.pos_abs((@line_height_pad * 2), auto, -(@line_height_pad * 2), auto);
		}
		
		&:hover {
			&:before {
				left:0;
			}
		}
	}
	
	&.cmsmasters_but_icon_slide_right {
		&:before {
			.pos_abs((@line_height_pad * 2), auto, auto, -(@line_height_pad * 2));
		}
		
		&:hover {
			&:before {
				right:0;
			}
		}
	}
	
	&.cmsmasters_but_icon_hover_slide_left, 
	&.cmsmasters_but_icon_hover_slide_right, 
	&.cmsmasters_but_icon_hover_slide_top, 
	&.cmsmasters_but_icon_hover_slide_bottom {
		.bd;
		
		&:before {
			.pos_abs(100%, auto);
		}
		
		span {
			display:block;
			position:relative;
		}
	}
	
	&.cmsmasters_but_icon_hover_slide_left {
		&:before {
			left:-200%;
		}
	
		&:hover {
			&:before {
				left:0;
			}
			
			span {
				-webkit-transform:translateX(200%);
				-moz-transform:translateX(200%);
				-ms-transform:translateX(200%);
				-o-transform:translateX(200%);
				transform:translateX(200%);
			}
		}
	}
	
	&.cmsmasters_but_icon_hover_slide_right {
		&:before {
			right:-200%;
		}
	
		&:hover {
			&:before {
				right:0;
			}
			
			span {
				-webkit-transform:translateX(-200%);
				-moz-transform:translateX(-200%);
				-ms-transform:translateX(-200%);
				-o-transform:translateX(-200%);
				transform:translateX(-200%);
			}
		}
	}
	
	&.cmsmasters_but_icon_hover_slide_top {
		&:before {
			top:-200%;
		}
	
		&:hover {
			&:before {
				top:0;
			}
			
			span {
				-webkit-transform:translateY(200%);
				-moz-transform:translateY(200%);
				-ms-transform:translateY(200%);
				-o-transform:translateY(200%);
				transform:translateY(200%);
			}
		}
	}
	
	&.cmsmasters_but_icon_hover_slide_bottom {
		&:before {
			top:200%;
		}
	
		&:hover {
			&:before {
				top:0;
			}
			
			span {
				-webkit-transform:translateY(-200%);
				-moz-transform:translateY(-200%);
				-ms-transform:translateY(-200%);
				-o-transform:translateY(-200%);
				transform:translateY(-200%);
			}
		}
	}
}

.cmsmasters_paypal_donations > form:hover + .cmsmasters_button {
	&.cmsmasters_but_clear_styles {
		margin:0;
		-webkit-box-shadow:none;
		box-shadow:none;
	}

	&.cmsmasters_but_bg_slide_left:after {
		width:100%;
	}

	&.cmsmasters_but_bg_slide_right:after {
		width:100%;
	}

	&.cmsmasters_but_bg_slide_top:after {
		height:100%;
	}

	&.cmsmasters_but_bg_slide_bottom:after {
		height:100%;
	}

	&.cmsmasters_but_bg_expand_vert:after {
		width:90%;
		opacity:1;
	}

	&.cmsmasters_but_bg_expand_hor:after {
		height:75%;
		opacity:1;
	}

	&.cmsmasters_but_bg_expand_diag:after {
		height:170%;
		opacity:1;
	}

	&.cmsmasters_but_shadow {
		padding-bottom:0;
		top:3px;
		-webkit-box-shadow:inset 0 0 0 0 rgba(0, 0, 0, 0.02);
		box-shadow:inset 0 0 0 0 rgba(0, 0, 0, 0.02);
	}

	&.cmsmasters_but_icon_dark_bg:before,
	&.cmsmasters_but_icon_light_bg:before,
	&.cmsmasters_but_icon_divider:before {
		-webkit-transform:scale(1.3);
		-moz-transform:scale(1.3);
		-ms-transform:scale(1.3);
		-o-transform:scale(1.3);
		transform:scale(1.3);
	}

	&.cmsmasters_but_icon_slide_left:before {
		left:0;
	}

	&.cmsmasters_but_icon_slide_right:before {
		right:0;
	}

	&.cmsmasters_but_icon_hover_slide_left:before {
		left:0;
	}

	&.cmsmasters_but_icon_hover_slide_left span {
		-webkit-transform:translateX(200%);
		-moz-transform:translateX(200%);
		-ms-transform:translateX(200%);
		-o-transform:translateX(200%);
		transform:translateX(200%);
	}

	&.cmsmasters_but_icon_hover_slide_right:before {
		right:0;
	}

	&.cmsmasters_but_icon_hover_slide_right span {
		-webkit-transform:translateX(-200%);
		-moz-transform:translateX(-200%);
		-ms-transform:translateX(-200%);
		-o-transform:translateX(-200%);
		transform:translateX(-200%);
	}

	&.cmsmasters_but_icon_hover_slide_top:before {
		top:0;
	}

	&.cmsmasters_but_icon_hover_slide_top span {
		-webkit-transform:translateY(200%);
		-moz-transform:translateY(200%);
		-ms-transform:translateY(200%);
		-o-transform:translateY(200%);
		transform:translateY(200%);
	}

	&.cmsmasters_but_icon_hover_slide_bottom:before {
		top:0;
	}

	&.cmsmasters_but_icon_hover_slide_bottom span {
		-webkit-transform:translateY(-200%);
		-moz-transform:translateY(-200%);
		-ms-transform:translateY(-200%);
		-o-transform:translateY(-200%);
		transform:translateY(-200%);
	}
}


/***************** Contact Form 7 ******************/
.wpcf7-form {
	@{input_text},
	select, 
	textarea,
	option {
		min-width:100px;
		width:100%;
	}
}


/***************** Hover slider ******************/
.cmsmasters_hover_slider {
	position:relative;
	.ovh;
	
	li:before {
		.dn;
	}
	
	.cmsmasters_hover_slider_items {
		list-style:none;
		width:100%;
		height:1px;
		padding:0 0 @resp_blocks_pad;
		margin:0;
		position:relative;
		
		li {
			list-style:none;
			padding:0;
			width:100%;
			height:100%;
			visibility:hidden;
			position:absolute;
			left:0;
			top:0;
			.op(0);
		}
		
		.cmsmasters_hover_slider_full_img {
			width:100%;
			max-width:100%;
			height:100%;
			max-height:100%;
		}
		
		img {
			display:block;
			width:100%;
			max-width:100%;
			height:100%;
			max-height:100%;
			position:absolute;
		}
	}
	
	.cmsmasters_hover_slider_thumbs {
		font-size:0;
		line-height:0;
		margin:0 -5px;
		padding:0;
		word-spacing:-1px;
		
		li {
			word-spacing:normal;
			display:inline-block;
			display:-moz-inline-stack;
			padding:10px 5px 0;
			vertical-align:top;
			
			&.shortcode_animated a {
				-webkit-transform:scale(1);
				-moz-transform:scale(1);
				transform:scale(1);
				.op(1);
			}
		}
		
		a {
			display:block;
			position:relative;
			-webkit-transform:scale(.5);
			-moz-transform:scale(.5);
			transform:scale(.5);
			.ovh;
			.op(.3);
			
			&:before {
				content:'';
				display:block;
				width:100%;
				height:100%;
				position:absolute;
				top:0;
				left:0;
			}
		}
		
		img {
			display:block;
			width:100px;
			height:auto;
		}
	}
}

.js_body .cmsmasters_hover_slider_items > li > figure.cmsmasters_hover_slider_full_img img {
	position:absolute;
}


/***************** Content slider ******************/
.cmsmasters_content_slider {
	margin-bottom:20px;
	position:relative;
	.ovh;
	
	img {
		width:100%;
	}
	
	.owl-buttons {
		position:static;
		width:0;
		height:0;
		
		> div {
			display:block;
			width:36px;
			height:100%;
			margin:0;
			position:absolute;
			top:0;
			.ovh;
			
			&:before {
				content:'';
				width:100%;
				height:100%;
				position:absolute;
				left:0;
				top:0;
			}
			
			&.owl-prev {
				left:0;
				right:auto;
			}
			
			&.owl-next {
				left:auto;
				right:0;
			}
			
			> span {
				background:transparent;
				display:block;
				width:100%;
				height:100%;
				.bd(0);
				.bdrs(0);
				.bxsh_none;
			}
		}
	}
	
	.owl-pagination {
		bottom:5px;
	}
}


/***************** Gallery ******************/
.cmsmasters_gallery {
	margin:0 0 0 -10px;
	padding:0;
	
	.cmsmasters_gallery_item {
		min-width:260px;
		padding:0 0 10px 10px;
		.fl;
		.op(0);
		
		&:before {
			display:none;
		}
		
		img {
			display:block;
			width:100%;
		}
		
		&.shortcode_animated {
			.op(1);
		}
		
		&.cmsmasters_caption {
			figure {
				display:block;
				padding:20px 20px 15px;
			}
			
			figcaption {
				padding:15px 0 0;
				.tac;
			}
		}
	}
}


.ilightbox-thumbnails.dark .ilightbox-thumbnails-grid .ilightbox-thumbnail img {
	image-rendering:auto;
}

/***************** Quotes ******************/
.cmsmasters_quotes_slider {
	padding:0 0 50px;
	position:relative;
	.ovh;
	
	.quote_placeholder {
		display:block;
		width:100px;
		height:100px;
		margin:0 auto 20px;
		.tac;
		
		&:before {
			content:'\201c';
			display:block;
			height:100px;
			font-size:120px;
			line-height:200px;
		}
	}
	
	.owl-buttons {
		visibility:hidden;
		.dn;
	}
	
	.cmsmasters_quote {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_quote_inner {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_quote_image {
		margin:0 0 45px;
		.tac;
		.ovh;
		
		img {
			width:100px;
			.bdrs(50%);
		}
	}
	
	.cmsmasters_quote_header {
		padding:0 0 3px;
		.ovh;
	}
	
	.cmsmasters_quote_title {
		margin:0;
		.tac;
	}
	
	.cmsmasters_quote_subtitle_wrap {
		.tac;
		.ovh;
	}
	
	.cmsmasters_quote_subtitle {
		display:inline-block;
		margin:0;
	}
	
	.cmsmasters_quote_site {
		display:inline-block;
	}
	
	.cmsmasters_quote_subtitle + .cmsmasters_quote_site:before {
		content:'\002D';
		display:inline-block;
		margin:0 4px;
	}
	
	.cmsmasters_quote_content {
		position:relative;
		padding-bottom:50px;
		.tac;
		.ovh;
		
		> *:last-child {
			margin-bottom:0;
			padding-bottom:0;
		}
	}
}

.cmsmasters_quotes_grid {
	position:relative;
	.ovh;
	
	.cmsmasters_quotes_vert {
		width:100%;
		height:100%;
		position:absolute;
		top:0;
		z-index:1;
		
		span {
			display:block;
			width:1px;
			height:100%;
			position:absolute;
			top:0;
		}
		
		.quote_four& {
			&:before {
				left:25%;
				right:auto;
			}
			
			&:after {
				left:75%;
				right:auto;
			}
			
			span {
				left:50%;
				right:auto;
			}
		}
		
		.quote_three& {
			&:before {
				left:33.33%;
				right:auto;
			}
			
			&:after {
				left:66.66%;
				right:auto;
			}
			
			span {
				.dn;
			}
		}
		
		.quote_two& {
			&:before, 
			&:after {
				content:none;
			}
			
			span {
				left:50%;
				right:auto;
			}
		}
		
		.quote_one& {
			.dn;
		}
	}
	
	.cmsmasters_quotes_list {
		position:relative;
		margin:0 -(@col_mar / 2);
		z-index:2;
		
		&:first-of-type {
			padding-top:0;
		}
		
		&:last-of-type {
			padding-bottom:20px;
		}
	}
	
	.cmsmasters_quote {
		width:(100% - @col_mar);
		position:relative;
		margin:(@col_mar / 2);
		.fl;
		
		.quote_four& {
			width:(25% - @col_mar);
		}
		
		.quote_three& {
			width:(33.33% - @col_mar);
		}
		
		.quote_two& {
			width:(50% - @col_mar);
		}
		
		.quote_one& {
			float:none;
		}
	}
	
	.cmsmasters_quote_inner {
		padding:30px;
		position:relative;
		.ovh;
	}
	
	.cmsmasters_quote_img_info_wrap {
		.ovh;
		
		.quote_four&, 
		.quote_three& {
			padding:0;
		}
	}
	
	.cmsmasters_quote_image {
		display:inline-block;
		padding:0 20px 0 0;
		vertical-align:middle;
		.ovh;
		
		.quote_four&, 
		.quote_three& {
			padding:0 0 20px;
			display:block;
			.tac;
		}
		
		img {
			width:60px;
			.bdrs(50%);
		}
	}
	
	.cmsmasters_quote_info_wrap {
		display:inline-block;
		vertical-align:middle;
		padding:0 0 0 80px;
		
		.quote_four&, 
		.quote_three& {
			padding:0;
			display:block;
			.tac;
		}
	}
	
	.cmsmasters_quote_image + .cmsmasters_quote_info_wrap {
		padding:0;
	}
	
	.cmsmasters_quote_header {
		padding:0;
		.ovh;
	}
	
	.cmsmasters_quote_title {
		margin:0;
	}
	
	.cmsmasters_quote_subtitle_wrap {
		.ovh;
		
		.quote_four&, 
		.quote_three& {
			.tac;
		}
	}
	
	.cmsmasters_quote_subtitle {
		display:inline-block;
		margin:0;
	}
	
	.cmsmasters_quote_site {
		display:inline-block;
	}
	
	.cmsmasters_quote_subtitle + .cmsmasters_quote_site:before {
		content:'\002D';
		display:inline-block;
		margin:0 4px;
	}
	
	.cmsmasters_quote_content {
		margin:0;
		position:relative;
		padding:10px 0 30px 80px;
		.ovh;
		
		&:before {
			content:'\201c';
			display:block;
			position:absolute;
			height:100px;
			width:30px;
			font-size:102px;
			line-height:125px;
			top:0;
			left:15px;
		}
		
		.quote_four&, 
		.quote_three& {
			padding:50px 0 30px 0;
			.tac;
			
			&:before {
				left:50%;
				margin-left:-15px;
			}
		}
		
		> *:last-child {
			margin-bottom:0;
			padding-bottom:0;
		}
	}
}


/***************** Pricing tables ******************/
.cmsmasters_pricing_table {
	margin:0 -20px;
	position:relative;
	.ovh;
	
	.cmsmasters_pricing_item {
		display:block;
		padding:0 20px 20px;
		position:relative;
		.fl;
		.ovh;
		
		.pricing_four& {
			width:25%;
		}
		
		.pricing_three& {
			width:33.33%;
		}
		
		.pricing_two& {
			width:50%;
		}
		
		.pricing_one& {
			float:none;
		}
	}
	
	.cmsmasters_pricing_item_inner {
		position:relative;
		.ovh;
	}
	
	.cmsmasters_pricing_item_head {
		position:relative;
		padding:35px 15px;
		
		&:before {
			content:'';
			position:absolute;
			bottom:-10px;
			left:50%;
			margin-left:-10px;
			.arrow_bottom(10px; 10px);
			border-top-color:red;
		}
	}
	
	.cmsmasters_pricing_info_wrap {
		padding:30px 10px 25px;
		.ovh;
	}
	
	.pricing_title {
		margin:0;
		padding:0 0 25px;
		.tac;
	}
	
	.cmsmasters_price_wrap {
		word-spacing:-5px;
		.ovh;
		.tac;
		
		.cmsmasters_period {
			word-spacing:0;
		}
	}
	
	.cmsmasters_price {
		display:inline-block;
	}
	
	.cmsmasters_currency, 
	.cmsmasters_coins {
		display:inline-block;
	}
	
	.cmsmasters_period {
		display:block;
		.tac;
	}
	
	.feature_list {
		list-style:none;
		margin:0;
		padding:40px 10px 30px;
		.tac;
		
		li {
			margin:0;
			padding:3px 0;
			
			&:before {
				content:none;
			}
		}
	}
	
	.cmsmasters_pricing_but_wrap {
		padding:0 0 40px;
		.tac;
		.ovh;
	}
	
	.cmsmasters_pricing_info_wrap + .cmsmasters_pricing_but_wrap {
		padding-top:40px;
	}
}


/***************** Google Maps ******************/
.resizable_block .google_map {
	position:absolute;
	width:100%;
	left:0;
	top:0;
	height:100%;
}

.google_map img {
	max-width:none;
}


/***************** Caption ******************/
.cmsmasters_img {
	display:inline-block;
	max-width:100%;
	
	&.cmsmasters_image_l {
		margin-right:@line_height_pad;
		.fl;
	}

	&.cmsmasters_image_r {
		margin-left:@line_height_pad;
		.fr;
	}
	
	&.with_caption {
		padding:20px 20px 0 20px;
	}
	
	.cmsmasters_img_caption {
		padding:15px 0;
		.tac;
	}
}

.wp-caption {
	max-width:100%;
	padding:0;
	margin-bottom:@line_height_pad;
	
	&.aligncenter {
		margin-left:auto;
		margin-right:auto;
	}
	
	&.alignleft {
		margin-right:1rem;
	}
	
	&.alignright {
		margin-left:1rem;
	}
	
	a {
		display:block;
	}
	
	.wp-caption-text {
		word-wrap:break-word;
		padding:15px;
		.tac;
	}
}


/***************** WP Gallery ******************/
.gallery {
	position:relative;
	margin:0 -@col_mar 20px;
	
	img {
		width:100%;
		border:none;
	}
	
	.gallery-item {
		position:relative;
		float:left;
		margin:0 @col_mar 20px;
		
		.wp-caption-text {
			word-wrap:break-word;
			padding-top:10px;
		}
	}
	
	&.gallery-columns-1 .gallery-item {
		width:round(100% - (@col_mar * 2), 3);
		position:relative;
		float:none;
		clear:both;
	}
	
	&.gallery-columns-2 .gallery-item {
		width:round((100% / 2) - (@col_mar * 2), 3);
	}
	
	&.gallery-columns-3 .gallery-item {
		width:round((100% / 3) - (@col_mar * 2), 3);
	}
	
	&.gallery-columns-4 .gallery-item {
		width:round((100% / 4) - (@col_mar * 2), 3);
	}
	
	&.gallery-columns-5 .gallery-item {
		width:round((100% / 5) - (@col_mar * 2), 3);
	}
	
	&.gallery-columns-6 .gallery-item {
		width:round((100% / 6) - (@col_mar * 2), 3);
	}
	
	&.gallery-columns-7 .gallery-item {
		width:round((100% / 7) - (@col_mar * 2), 3);
	}
	
	&.gallery-columns-8 .gallery-item {
		width:round((100% / 8) - (@col_mar * 2), 3);
	}
	
	&.gallery-columns-9 .gallery-item {
		width:round((100% / 9) - (@col_mar * 2), 3);
	}
}

.gallery:after, 
.gallery-item:after {
	.cl_after;
}

.gallery-caption {
	outline:none;
}


/***************** Sidebar ******************/
.cmsmasters_sidebar {
	margin:0 -@col_mar;
	
	aside {
		margin:0 @col_mar;
		float:left;
	}
}

.sidebar_layout_14141414 {
	aside {
		width:round((100% / 4) - (@col_mar * 2), 2);
	}
}

.sidebar_layout_141412 {
	> aside:first-child, 
	> aside:first-child + aside, 
	> div.cl + aside, 
	> div.cl + aside + aside, 
	> div.cmsmasters_widget_divider + aside, 
	> div.cmsmasters_widget_divider + aside + aside {
		width:round((100% / 4) - (@col_mar * 2), 2);
	}
	
	> aside:first-child + aside + aside, 
	> div.cl + aside + aside + aside, 
	> div.cmsmasters_widget_divider + aside + aside + aside {
		width:round((100% / 2) - (@col_mar * 2), 2);
	}
}

.sidebar_layout_141214 {
	> aside:first-child, 
	> aside:first-child + aside + aside, 
	> div.cl + aside, 
	> div.cl + aside + aside + aside, 
	> div.cmsmasters_widget_divider + aside, 
	> div.cmsmasters_widget_divider + aside + aside + aside {
		width:round((100% / 4) - (@col_mar * 2), 2);
	}
	
	> aside:first-child + aside, 
	> div.cl + aside + aside, 
	> div.cmsmasters_widget_divider + aside + aside {
		width:round((100% / 2) - (@col_mar * 2), 2);
	}
}

.sidebar_layout_121414 {
	> aside:first-child, 
	> div.cl + aside, 
	> div.cmsmasters_widget_divider + aside {
		width:round((100% / 2) - (@col_mar * 2), 2);
	}
	
	> aside:first-child + aside, 
	> aside:first-child + aside + aside, 
	> div.cl + aside + aside, 
	> div.cl + aside + aside + aside, 
	> div.cmsmasters_widget_divider + aside + aside, 
	> div.cmsmasters_widget_divider + aside + aside + aside {
		width:round((100% / 4) - (@col_mar * 2), 2);
	}
}

.sidebar_layout_131313 {
	aside {
		width:round((100% / 3) - (@col_mar * 2), 2);
	}
}

.sidebar_layout_3414 {
	> aside:first-child, 
	> div.cl + aside, 
	> div.cmsmasters_widget_divider + aside {
		width:round(((100% * 3) / 4) - (@col_mar * 2), 2);
	}
	
	> aside:first-child + aside, 
	> div.cl + aside + aside, 
	> div.cmsmasters_widget_divider + aside + aside {
		width:round((100% / 4) - (@col_mar * 2), 2);
	}
}

.sidebar_layout_1434 {
	> aside:first-child, 
	> div.cl + aside, 
	> div.cmsmasters_widget_divider + aside {
		width:round((100% / 4) - (@col_mar * 2), 2);
	}
	
	> aside:first-child + aside, 
	> div.cl + aside + aside, 
	> div.cmsmasters_widget_divider + aside + aside {
		width:round(((100% * 3) / 4) - (@col_mar * 2), 2);
	}
}

.sidebar_layout_2313 {
	> aside:first-child, 
	> div.cl + aside, 
	> div.cmsmasters_widget_divider + aside {
		width:round(((100% * 2) / 3) - (@col_mar * 2), 2);
	}
	
	> aside:first-child + aside, 
	> div.cl + aside + aside, 
	> div.cmsmasters_widget_divider + aside + aside {
		width:round((100% / 3) - (@col_mar * 2), 2);
	}
}

.sidebar_layout_1323 {
	> aside:first-child, 
	> div.cl + aside, 
	> div.cmsmasters_widget_divider + aside {
		width:round((100% / 3) - (@col_mar * 2), 2);
	}
	
	> aside:first-child + aside, 
	> div.cl + aside + aside, 
	> div.cmsmasters_widget_divider + aside + aside {
		width:round(((100% * 2) / 3) - (@col_mar * 2), 2);
	}
}

.sidebar_layout_1212 {
	aside {
		width:round((100% / 2) - (@col_mar * 2), 2);
	}
}

.sidebar_layout_11 {
	aside {
		width:round(100% - (@col_mar * 2), 2);
	}
}


/***************** Share ******************/
.cmsmasters_sharing {
	.ovh;
}

.share_wrap {
	float:left;
	margin-bottom:20px;
	margin-right:20px;
	
	&:last-child {
		margin-right:0;
	}
	
	.social_vertical & {
		float:none;
		margin-right:0;
	}
}


/***************** Twitter Stripe ******************/
.cmsmasters_twitter_wrap {
	position:relative;
	.ovh;
	
	.owl-pagination {
		visibility:hidden;
		.dn;
	}
	
	.owl-buttons {
		width:auto;
		top:0;
		left:0;
		bottom:0;
	}
	
	.twr_icon {
		.dn;
	}
	
	.cmsmasters_twitter {
		position:relative;
	}
	
	.cmsmasters_twitter_item {
		padding:0 0 0 130px;
		position:relative;
	}
	
	.published {
		padding:0 0 15px;
		
		&:before {
			content:'\ea97';
			font-size:30px;
			line-height:30px;
			font-family:fontello;
			display:inline-block;
			padding-right:20px;
			.val;
		}
	}
	
	.cmsmasters_twitter_item_content {
		display:block;
	}
}


/***************** Dropcap ******************/
.cmsmasters_dropcap {
	margin:5px 33px 5px 23px;
	line-height:50px;
	.fl;
	
	&.type2 {
		width:50px;
		height:50px;
		margin:5px 25px 5px 0;
		.bdrs(50%);
		.tac;
	}
}


/***************** Clients ******************/
.cmsmasters_clients_slider {
	margin:0;
	padding:30px 0;
	
	.owl-pagination, 
	.owl-buttons {
		width:auto;
		left:auto;
	}
	
	.owl-pagination {
		right:-3px;
	}
	
	.owl-buttons {
		right:-10px;
	}
}

.cmsmasters_clients_item {
	line-height:180px;
	height:180px;
	vertical-align:middle;
	position:relative;
	.tac;
	
	img {
		display:inline-block;
		padding:0 20px;
		max-width:100%;
		max-height:100%;
		height:auto;
		width:auto;
		vertical-align:middle;
	}
	
	a {
		display:block;
		line-height:180px;
	}
	
	&:hover {
		outline:none;
	}
}


.cmsmasters_clients_grid {
	overflow:hidden;
	
	&.clients_five .cmsmasters_clients_item {
		width:20%;
	}
	
	&.clients_four .cmsmasters_clients_item {
		width:25%;
	}
	
	&.clients_three .cmsmasters_clients_item {
		width:33.33%;
	}
	
	&.clients_two .cmsmasters_clients_item {
		width:50%;
	}
	
	.cmsmasters_clients_items {
		height:auto;
	}
	
	.cmsmasters_clients_item {
		margin-bottom:20px;
		float:left;
		
		img {
			-webkit-transform:scale(.5);
			-moz-transform:scale(.5);
			transform:scale(.5);
			.op(.1);
		}
		
		&.shortcode_animated {
			img {
				-webkit-transform:scale(1);
				-moz-transform:scale(1);
				transform:scale(1);
				.op(1);
			}
		}
	}
	
	&.clients_one .cmsmasters_clients_item:last-child,
	&.clients_one .cmsmasters_clients_item {
		width:100%;
		float:none;
		height:auto;
		line-height:0;
	}
	
	&.clients_two .cmsmasters_clients_item:nth-child(2n+1),
	&.clients_three .cmsmasters_clients_item:nth-child(3n+1),
	&.clients_four .cmsmasters_clients_item:nth-child(4n+1),
	&.clients_five .cmsmasters_clients_item:nth-child(5n+1) {
		clear:both;
	}
}



/*-------------------------------------------------------------------------*/
/*	Widgets Styles
/*-------------------------------------------------------------------------*/

.widget {
	position:relative;
	padding:0 0 (@line_height_pad * 2);
	
	.widgettitle {
		margin-bottom:25px;
	}
	
	table {
		background:transparent;
	}
}


/***************** Widget Advertisement ******************/
.widget_custom_advertisement_entries {
	.widgettitle {
		margin-bottom:25px;
	}
	
	.adv_image_wrap {
		margin:-5px;
		overflow:hidden;
		
		.adv_widget_image {
			width:125px;
			height:125px;
			float:left;
			margin:5px;
		}
	}
}


/***************** Widget Lists ******************/
.widget_pages, 
.widget_text, 
.widget_categories, 
.widget_archive, 
.widget_meta, 
.widget_recent_comments, 
.widget_recent_entries {
	ul {
		margin:0;
		padding:0;
		
		ul {
			margin:0 0 -4px;
			padding:4px 0 0 10px;
		}
		
		li {
			margin:0;
			padding:4px 0;
			
			&:before {
				content:none;
			}
			
			a {
				outline:none;
			}
		}
	}
	
	.post-date {
		display:block;
		margin:-3px 0 0;
	}
}

.widget_categories, 
.widget_archive {
	.screen-reader-text {
		.dn;
	}
}


/***************** Widget Calendar ******************/
#wp-calendar {
	border-spacing:0;
	width:auto;
	min-width:200px;
	
	caption {
		text-align:left;
		padding:0 0 10px;
	}
	
	td, 
	th {
		padding:4px 0;
		.bd(0);
	}
	
	tfoot {
		a {
			display:inline-block;
			padding-top:10px;
		}
	}
}


/***************** Widget Contact Form ******************/
.widget_custom_contact_form_entries {
	@{input_text},
	textarea,
	select {
		width:100%;
	}
	
	.form_info.submit_wrap {
		padding-top:0;
	}
}


/***************** Widget Contact Info ******************/
.widget_custom_contact_info_entries {
	position:relative;
	.ovh;
	
	> span, 
	.adress_wrap {
		display:block;
		margin:15px 0 0 2px;
		padding:0 0 0 40px;
		position:relative;
		
		&:first-child {
			margin:0;
		}
		
		&:before {
			font-size:19px;
			line-height:19px;
			text-align:center;
			position: absolute;
			left:0;
			right:auto;
			top:0;
			bottom:0;
			padding:2px 0;
		}
	}
	
	.adress_wrap {
		span {
			display:block;
		}
	}
}


/***************** Widget Custom Menu ******************/
.widget_nav_menu {
	position:relative;
	.ovh;
	
	ul {
		margin:0;
		padding:0;
		
		li {
			margin:0;
			padding:0;
			
			&:before {
				content:none;
			}
			
			a {
				display:block;
			}
		}
	}
	
	.menu {
		position:relative;
		margin-left:10px;
		
		li {
			a {
				padding:15px 20px;
				margin-bottom:1px;
			}
			
			li {
				a {
					padding-left:40px;
				}
				
				li {
					a {
						padding-left:60px;
					}
					
					li {
						a {
							padding-left:80px;
						}
					}
				}
			}
			
			&.current_page_item:before {
				content:'';
				display:block;
				position:absolute;
				left:-14px;
				right:auto;
				top:50%;
				margin-top:-7px;
				border-left:0 solid transparent;
				border-top:7px solid transparent;
				border-bottom:7px solid transparent;
				border-right-width:7px;
				border-right-style:solid;
				
				.l_sidebar & {
					left:auto;
					right:-14px;
					border-right:0 solid transparent;
					border-top:7px solid transparent;
					border-bottom:7px solid transparent;
					border-left-width:7px;
					border-left-style:solid;
				}
			}
		}
		
		.l_sidebar & {
			margin-left:0;
			margin-right:10px;
		}
	}
}


/***************** Widget Facebook ******************/
.widget_custom_facebook_entries iframe {
	margin-bottom:0;
}


/***************** Widget Flickr ******************/
.widget_custom_flickr_entries {
	.wrap {
		margin:0 -7px;
	}
	
	.more_button {
		display:none;
	}
	
	.flickr_badge_image {
		margin:0 7px 18px;
		.fl;
	}
}


/***************** Widget Latest and Popular Projects ******************/
.widget_custom_popular_projects_entries,
.widget_custom_latest_projects_entries {
	position:relative;
	
	.preloader {
		padding-bottom:@preloader_posts_slider_pad;
	}
	
	.widgettitle {
		padding:0 70px 0 0;
	}
	
	.widget_custom_projects_entries_slides {
		padding:40px 0 0;
	}
	
	.widgettitle + .widget_custom_projects_entries_slides {
		padding:0;
		
		.owl-buttons {
			width:auto;
			left:auto;
			right:0;
			top:-50px;
		}
	}
	
	.cmsmasters_prev_arrow, 
	.cmsmasters_next_arrow {
		width:16px;
		height:24px;
		
		> span {
			width:10px;
			height:16px;
			
			&:before {
				font-size:16px;
				line-height:16px;
			}
		}
	}
	
	.cmsmasters_slider_project {
		padding:0 1px 2px;
	}
}


/***************** Widget Posts Tabs ******************/
.widget_custom_posts_tabs_entries {
	position:relative;
	.ovh;
	
	.cmsmasters_tabs {
		position:relative;
		
		.cmsmasters_tabs_list {
			display:table;
			width:100%;
		}
		
		.cmsmasters_tabs_list_item {
			width:33%;
			margin:0;
			padding:0;
			float:none;
			
			&:before {
				z-index:0;
				margin-left:-7px;
				border-left:7px solid transparent !important;
				border-right:7px solid transparent !important;
				border-top-width:7px;
			}
			
			&.current_tab:before {
				bottom:-7px;
			}
			
			a {
				padding:10px 5px;
				outline:none;
			}
		}
		
		.cmsmasters_tabs_wrap {
			margin:30px 0 0;
			position:relative;
			.ovh;
			
			> .cmsmasters_tab:first-child {
				display:block;
			}
		}
		
		.cmsmasters_tab {
			ul {
				margin:0;
				padding:0;
			}
			
			li {
				margin:20px 0 0;
				padding:0;
				position:relative;
				.ovh;
				
				&:first-child {
					margin:0;
				}
				
				&:before {
					content:none;
				}
				
				p {
					margin:0;
					padding:0;
				}
			}
			
			&.tab_latest, 
			&.tab_popular {
				li:after {
					.valign_after;
					height:60px;
				}
			}
			
			&.tab_comments {
				li {
					margin:15px 0 0;
					
					&:first-child {
						margin:0;
					}
				
					> p {
						margin:0;
						padding:10px 0 0;
					}
				}
				
				.published {
					display:block;
					margin-top:-4px;
				}
			}
		}
		
		.cmsmasters_lpr_tabs_img {
			margin-right:20px;
			z-index:1;
			.ovh;
			.pos_abs(60px, 60px, 0, auto);
		}
		
		.cmsmasters_lpr_tabs_cont {
			display:inline-block;
			vertical-align:middle;
			padding:0 0 0 80px;
			position:relative;
			.ovh;
			
			> a {
				outline:none;
			}
			
			> .published {
				margin-top:-2px;
				display:block;
			}
		}
	}
}


/***************** Widget RSS ******************/
.widget_rss {
	img {
		margin:-3px 0 0;
	}
	
	ul {
		margin:0;
		padding:0;
		
		li {
			margin:0;
			padding:15px 0 0;
			
			&:first-child {
				padding:0;
			}
			
			&:before {
				content:none;
			}
			
			.rsswidget {
				outline:none;
			}
			
			.rss-date {
				display:block;
			}
			
			.rssSummary {
				padding:10px 0 0;
				.ovh;
			}
			
			cite {
				display:block;
				padding:7px 0 0;
			}
		}
	}
}


/***************** Widget Tag Cloud ******************/
.widget_tag_cloud {
	position:relative;
	.ovh;
	
	a {
		line-height:1em;
		margin:0 4px 4px 0;
		padding:4px 10px;
		.fl;
	}
}


/***************** Widget Twitter ******************/
.widget_custom_twitter_entries {
	position:relative;
	.ovh;
	
	ul {
		margin:0;
		padding:0;
		
		li {
			position:relative;
			
			&:last-child {
				margin:0;
			}
			
			&:before {
				content:none;
			}
		}
	}
	
	.tweet_time {
		display:block;
		padding:0 0 0 35px;
		position:relative;
		
		&:before {
			font-size:22px;
			line-height:22px;
			.pos_abs(1em, 1em, 0, auto);
		}
	}
	
	.tweet_text {
		display:block;
		padding:15px 0 10px 0;
		.ovh;
	}
}



/*-------------------------------------------------------------------------*/
/*	Search + Archives + Sitemap + Error + Other Styles */
/*-------------------------------------------------------------------------*/

/***************** Archives + Search ******************/
.cmsmasters_search, 
.cmsmasters_archive {
	position:relative;
	.ovh;
	
	.content_wrap.fullwidth & {
		padding:50px 0 0;
	}
	
	> .about_author {
		padding:0 0 50px;
	}
	
	> .cmsmasters_wrap_pagination {
		margin:50px 0 0;
	}
	
	.cmsmasters_archive_item_type {
		padding-bottom:5px;
	}
}

.cmsmasters_archive_type {
	padding:50px 0 0;
	.ovh;
	
	&:first-of-type {
		padding-top:0;
	}
	
	.preloader {
		padding-bottom:@preloader_archives_pad;
		
		&.highImg {
			height:auto;
			padding:0;
		}
	}
	
	.cmsmasters_archive_item_img_wrap {
		margin:0 40px 0 0;
		width:26%;
		.fl;
		
		.cmsmasters_img_wrap {
			.ovh;
			
			a:before {
				content:'';
				position:absolute;
				top:0;
				bottom:0;
				right:0;
				left:0;
				z-index:1;
				.op(0);
			}
			
			a:hover:before {
				.op(1);
			}
		}
	}
	
	.cmsmasters_archive_item_cont_wrap {
		.ovh;
	}
	
	.cmsmasters_archive_item_type {
		.ovh;
	}
	
	.cmsmasters_archive_item_header {
		padding:0 0 25px;
	}
	
	.cmsmasters_archive_item_title {
		word-wrap:break-word;
		margin:0;
	}
	
	.cmsmasters_archive_item_content {
		padding:0 0 20px;
		position:relative;
		.ovh;
		
		p {
			padding:0;
		}
	}
	
	.cmsmasters_archive_item_info {
		padding:5px 0 0;
		position:relative;
		.ovh;
		
		&:before {
			content:'';
			width:100%;
			height:1px;
			position:absolute;
			left:0;
			top:0;
		}
	}
	
	.cmsmasters_archive_item_date_wrap, 
	.cmsmasters_archive_item_user_name + .cmsmasters_archive_item_category {
		padding-left:12px;
		position:relative;
	
		&:before {
			content:'|';
			position:absolute;
			top:0;
			left:4px;
		}
	}
}


/***************** Sitemap ******************/
.middle_content .cmsmasters_sitemap_wrap {
	padding:0 0 50px;
}
.cmsmasters_sitemap_wrap {
	position:relative;
	.ovh;
	
	> h1 {
		margin:0 0 35px;
	}

	ul, 
	ol, 
	li {
		margin:0;
		padding:0;
		position:relative;
		.ovh;
		
		&:before {
			content:none;
		}
	}
	
	.cmsmasters_divider {
		.dn;
	}
	
	.cmsmasters_sitemap {
		padding:0;
		margin:0;
		.ovh;
		
		> li {
			width:100%;
			position:relative;
			margin-bottom:40px;
			padding-bottom:40px;
			
			&:before {
				content:'';
				display:block;
				width:100%;
				height:1px;
				position:absolute;
				left:0;
				top:auto;
				bottom:0;
			}
			
			> a {
				display:inline-block;
			}
			
			> ul {
				width:100%;
				padding-top:15px;
				.ovh;
				
				> li {
					width:20%;
					padding-top:8px;
					padding-right:20px;
					.fl;
					
					&:nth-child(6), 
					&:nth-child(10), 
					&:nth-child(14), 
					&:nth-child(18), 
					&:nth-child(22), 
					&:nth-child(26), 
					&:nth-child(30), 
					&:nth-child(34), 
					&:nth-child(38), 
					&:nth-child(42), 
					&:nth-child(46) {
						clear:both;
					}
					
					&.menu-item-has-children {
						> a {
							display:inline-block;
							margin-top:5px;
						}
					}
					
					> ul {
						padding-top:25px;
					
						ul {
							padding-top:10px;
						}
					
						li {
							margin-left:20px;
							padding-top:10px;
							
							&:first-child {
								padding-top:0;
							}
						}
						
						> li {
							margin-left:0;
						}
					}
				}
			}
		}
	}
	
	.cmsmasters_sitemap_category {
		padding:0 0 20px;
		margin:0 0 40px;
		.ovh;
		
		&:before {
			content:'';
			display:block;
			width:100%;
			height:1px;
			position:absolute;
			left:0;
			top:auto;
			bottom:0;
		}
		
		> li {
			width:20%;
			padding-right:20px;
			margin-bottom:20px;
			.fl;
			
			&:nth-child(6), 
			&:nth-child(10), 
			&:nth-child(14), 
			&:nth-child(18), 
			&:nth-child(22), 
			&:nth-child(26), 
			&:nth-child(30), 
			&:nth-child(34), 
			&:nth-child(38), 
			&:nth-child(42), 
			&:nth-child(46) {
				clear:both;
			}
			
			> ul {
				padding-top:20px;
			
				ul {
					padding-top:10px;
				}
			
				li {
					margin-left:20px;
					padding-top:10px;
					
					&:first-child {
						padding-top:0;
					}
				}
				
				> li {
					margin-left:0;
				}
			}
		}
	}
	
	.cmsmasters_sitemap_archive {
		padding:0 0 20px;
		margin:0 0 40px;
		.ovh;
		
		&:before {
			content:'';
			display:block;
			width:100%;
			height:1px;
			position:absolute;
			left:0;
			top:auto;
			bottom:0;
		}
		
		> li {
			width:20%;
			margin-bottom:20px;
			padding-right:20px;
			.fl;
			
			&:nth-child(6), 
			&:nth-child(10), 
			&:nth-child(14), 
			&:nth-child(18), 
			&:nth-child(22), 
			&:nth-child(26), 
			&:nth-child(30), 
			&:nth-child(34), 
			&:nth-child(38), 
			&:nth-child(42), 
			&:nth-child(46) {
				clear:both;
			}
		}
	}
}


/***************** Error ******************/
.error {
	.ovh;
	
	.error_inner {
		padding:165px 0 150px 0;
	}
	
	.error_title {
		font-size:300px;
		font-weight:600;
		line-height:1em;
		margin:0;
		padding:0 0 10px;
		.tac;
	}
	
	.error_subtitle {
		text-align:center;
		margin:0;
		padding:0;
	}
}

.error_cont {
	padding:60px 0;
	.tac;
	
	.search_bar_wrap {
		display:inline-block;
		width:360px;
		max-width:100%;
		margin:0 20px;
		.tac;
	}
	
	.error_button_wrap {
		margin:0 20px;
		padding:22px 0 30px 0;
		.tac;
		
		> a {
			padding:0 70px;
		}
	}
}


/***************** Attachment Page ******************/
.cmsmasters_attach_img {
	padding:40px 0;
	position:relative;
	.ovh;
	
	.cmsmasters_attach_img_info {
		padding:0 0 20px;
		margin:0 0 30px;
		position:relative;
		.ovh;
	}
	
	.cmsmasters_attach_img_edit {
		margin:0;
		padding:0;
		.fr;
	}
	
	.cmsmasters_attach_img_meta {
		margin:0;
		padding:0;
		.ovh;
	}
}


/***************** Other ******************/
#main {
	.with_frm_style .frm_form_fields input, 
	#wpcontent .with_frm_style .frm_form_fields select, 
	.with_frm_style .frm_form_fields select, 
	.with_frm_style .frm_form_fields textarea {
		padding:@input_pad @input_pad_vert;
	}
}

#lang_sel ul {
	overflow:visible;
}

#lang_sel_footer {
	display:none;
}


/***************** PayPal Donations ******************/
.cmsmasters_paypal_donations {
	display:inline-block;
	position:relative;
	
	> form {
		> .paypal-donations {
			input[type="image"] {
				display:block;
				z-index:100;
				.pos_abs(100%, 100%);
				.op(0);
			}
			
			> img {
				.dn;
			}
		}
	}
	
	.cmsmasters_button {
		display:block;
		position:relative;
		z-index:10;
	}
}



/*-------------------------------------------------------------------------*/
/*	Contact Forms Styles
/*-------------------------------------------------------------------------*/

.cmsmasters-form-builder {
	margin:0 -@col_mar;
	
	label {
		padding-bottom:3px !important;
	}
	
	small.db {
		padding-top:0 !important;
	}
	
	.success_box {
		margin:0 @col_mar 10px;
		width:round(100% - (@col_mar * 2), 2);
	}
	
	.form_info {
		margin:0 @col_mar;
		padding-bottom:15px !important;
		
		&.submit_wrap {
			padding-top:10px;
		}
	}
	
	.check_parent {
		padding:1px 0 !important;
	}
	
	[class*="one_"] input[type="text"],
	[class*="one_"] select,
	[class*="one_"] textarea {
		width:100%;
	}
}

select[multiple] {
	height:auto;
}

span.wpcf7-list-item {
	display:block;
}

.wpcf7-list-item-label, 
#fbuilder input[type="checkbox"] + .field_before, 
#fbuilder input[type="radio"] + .field_before, 
body .cmsmasters-form-builder .check_parent input[type="checkbox"] + label, 
body .cmsmasters-form-builder .check_parent input[type="radio"] + label {
	padding:5px 0 0 35px;
	position:relative;
	cursor:pointer;
	.fl;
}

.wpcf7-list-item label {
	position:relative;
	display:block;
	overflow:hidden;
	padding:5px 0;
}

#fbuilder input[type="checkbox"],
#fbuilder input[type="radio"],
.cmsmasters-form-builder .check_parent input[type="checkbox"], 
.cmsmasters-form-builder .check_parent input[type="radio"],
.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="checkbox"],
.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="radio"] {
	.fl;
	.op(0);
	.pos_abs(1em, 1em, 0, auto);
}

#fbuilder input[type="checkbox"] + .field_before:before,
#fbuilder input[type="radio"] + .field_before:before, 
.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="checkbox"] + span.wpcf7-list-item-label:before, 
.cmsmasters-form-builder .check_parent input[type="checkbox"] + label:before,
.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="radio"] + span.wpcf7-list-item-label:before, 
.cmsmasters-form-builder .check_parent input[type="radio"] + label:before {
	content:'';
	.pos_abs(17px, 17px, 0px, auto);
}

#fbuilder input[type="radio"] + .field_before:before, 
.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="radio"] + span.wpcf7-list-item-label:before, 
.cmsmasters-form-builder .check_parent input[type="radio"] + label:before {
	.bdrs(50%);
}

#fbuilder input[type="radio"] + .field_before:after, 
#fbuilder input[type="checkbox"] + .field_before:after,
.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="checkbox"] + span.wpcf7-list-item-label:after, 
.cmsmasters-form-builder .check_parent input[type="checkbox"] + label:after,
.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="radio"] + span.wpcf7-list-item-label:after, 
.cmsmasters-form-builder .check_parent input[type="radio"] + label:after {
	content:'';
	-webkit-transform:scale(0);
	-moz-transform:scale(0);
	transform:scale(0);
	.pos_abs(17px, 17px, 0px, auto);
	.op(0);
}

#fbuilder input[type="radio"] + .field_before:after, 
.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="radio"] + span.wpcf7-list-item-label:after, 
.cmsmasters-form-builder .check_parent input[type="radio"] + label:after {
	.bdrs(50%);
}

#fbuilder input[type="radio"]:checked + .field_before:after, 
#fbuilder input[type="checkbox"]:checked + .field_before:after, 
.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="checkbox"]:checked + span.wpcf7-list-item-label:after,
.cmsmasters-form-builder .check_parent input[type="checkbox"]:checked + label:after,
.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="radio"]:checked + span.wpcf7-list-item-label:after, 
.cmsmasters-form-builder .check_parent input[type="radio"]:checked + label:after {
	-webkit-transform:scale(1);
	-moz-transform:scale(1);
	transform:scale(1);
	.op(1);
}


/*-------------------------------------------------------------------------*/
/* Calculated Form Styles */
/*-------------------------------------------------------------------------*/


.page #fbuilder {
	
	h1:empty {
		.dn;
	}
	
	legend {
		margin:10px 0;
		.bd(0);
	}
	
	.reset-button, 
	input[type=button] {
		margin-bottom:10px;
		height:auto;
	}
	
	input[type=file] {
		height:auto;
	}
	
	.ui-slider .ui-slider-handle {
		z-index:1;
	}
	
	h1, h2 {
		margin:0;
	}
	
	fieldset {
		padding:0;
		margin:0;
		background:transparent;
		.bd(0);
	}
	
	.one_column, 
	.two_column, 
	.three_column {
		label {
			padding-top:6px;
		}
	}
	
	.fields,
	.fform {
		padding:0 0 15px;
	}
	
	.section_breaks {
		padding:25px 0 10px 0;
		
		label {
			padding:0;
		}
	}
	
	label {
		display:inline-block;
		padding-bottom:3px;
	}

	.section_break {
		.bd(0);
	}
	
	.uh_phone {
		margin:0 5px 0 0;
		
		&:last-child {
			margin:0;
		}
		
		input {
			height:30px;
			padding:5px;
		}
	}
	
	input[type="checkbox"] + .field_before,
	input[type="radio"] + .field_before {
		width: 17px;
		height: 19px;
	}
}


/*-------------------------------------------------------------------------*/
/* CSS3 Animations */
/*-------------------------------------------------------------------------*/

html.csstransitions {
	/* Custom Animations */
	@{input_text},
	textarea, 
	select {
		.trans(~'background-color .2s ease-in-out');
	}
	
	.cmsmasters_items_filter_wrap .cmsmasters_items_sort_but:before, 
	.top_line_nav > li.menu-item-has-children > a:before { 
		.trans(~'transform .2s ease-in-out');
	}
	
	input[type=search]:-moz-placeholder {
		.trans(~'opacity .3s ease-in-out');
	}
	
	.cmsmasters_toggles .cmsmasters_toggle_title:before, 
	.cmsmasters_tabs.tabs_mode_tab .cmsmasters_tabs_list_item:before {
		.trans(~'bottom .2s ease-in-out');
	}
	
	.cmsmasters_tabs.tabs_mode_tour .cmsmasters_tabs_list_item:before {
		.trans(~'right .2s ease-in-out');
	}
	
	.cmsmasters_tabs.tabs_mode_tour.tabs_pos_right .cmsmasters_tabs_list_item:before {
		.trans(~'left .2s ease-in-out');
	}
	
	.cmsmasters_breadcrumbs_inner a, 
	.cmsmasters_project_puzzle .project_inner a, 
	.cmsmasters_project_puzzle .project_inner a:before, 
	.cmsmasters_img_rollover, 
	.cmsmasters_img_wrap > a:before {
		.trans(~'opacity .3s ease-in-out');
	}
	
	.cmsmasters_project_puzzle .project_inner {
		.trans(~'opacity .3s ease-in-out, margin-top .3s ease-in-out');
	}
	
	.cmsmasters_profile_horizontal .profile_social_icons_list {
		.trans(~'opacity .3s ease-in-out, top .3s ease-in-out, visibility .3s ease-in-out');
	}
	
	/* Global Animations */
	a, 
	.owl-buttons > div, 
	.post_nav > span > span > span:before, 
	.cmsmasters_likes a:before, 
	.cmsmasters_comments a:before, 
	.header_mid button, 
	.img_placeholder_small, 
	.search_bar_wrap .search_button button, 
	.cmsmasters_resp_nav_toggle:before,
	.cmsmasters_profile_horizontal .cmsmasters_profile_subtitle, 
	.cmsmasters_profile_horizontal .cmsmasters_profile_content, 
	.cmsmasters_slider_project .cmsmasters_slider_project_content p, 
	.cmsmasters_slider_project .cmsmasters_slider_project_category,
	.cmsmasters_project_grid .cmsmasters_project_content p, 
	.cmsmasters_project_grid .cmsmasters_project_category, 
	.cmsmasters_sitemap_wrap .cmsmasters_sitemap > li > ul > li > ul li a:before {
		.trans(color .3s ease-in-out);
	}
	
	.cmsmasters_profile_horizontal .profile_inner a,
	.cmsmasters_project_grid .cmsmasters_likes a:before, 
	.cmsmasters_project_grid .cmsmasters_comments a:before, 
	.cmsmasters_slider_project .cmsmasters_likes a:before, 
	.cmsmasters_slider_project .cmsmasters_comments a:before, 
	.cmsmasters_slider_project a, 
	.cmsmasters_project_grid a {
		.trans(~'opacity .3s ease-in-out, color .3s ease-in-out');
	}
	
	.responsive_top_nav, 
	.widget_tag_cloud a, 
	.cmsmasters_items_filter_list li a, 
	.cmsmasters_items_sort_block .button {
		.trans(~'background-color .3s ease-in-out, color .3s ease-in-out');
	}
	
	.owl-pagination .owl-page, 
	.header_mid, 
	.header_bot {
		.trans(~'background-color .2s ease-in-out');
	}
	
	.cmsmasters_img_rollover .cmsmasters_open_post_link > span {
		.trans(~'right .2s ease-in-out, left .2s ease-in-out');
	}
	
	#fbuilder fieldset .pbNext, 
	#fbuilder fieldset .pbPrevious, 
	#fbuilder fieldset .reset-button, 
	.cmsmasters_button, 
	.button, 
	input[type=submit], 
	input[type=button], 
	button, 
	#slide_top, 
	.header_mid .search_wrap .search_bar_wrap .search_button, 
	.cmsmasters_post_timeline .cmsmasters_post_date, 
	.cmsmasters_hover_slider .cmsmasters_hover_slider_thumbs > li a:before, 
	.cmsmasters_content_slider .owl-buttons > div:before, 
	.cmsmasters_profile_horizontal .img_placeholder, 
	.cmsmasters_open_post_link > span:after, 
	.cmsmasters_open_post_link > span:before, 
	.cmsmasters_project_grid .project_outer .project_inner, 
	.cmsmasters_slider_project .cmsmasters_slider_project_outer .cmsmasters_slider_project_inner, 
	.cmsmasters_profile_horizontal .profile_inner, 
	.cmsmasters_open_post .cmsmasters_post_tags > a {
		.trans(~'background-color .3s ease-in-out');
	}
	
	.responsive_nav, 
	.cmsmasters_notice .notice_close, 
	#page .cmsmasters_social_icon {
		.trans(~'color .3s ease-in-out, background-color .3s ease-in-out');
	}
	
	nav > div > ul ul,
	nav > div > ul div {
		.trans(~'opacity .3s ease-in-out .1s, margin-top .3s ease-in-out .1s');
	}
	
	nav > div > ul ul ul {
		.trans(~'opacity .3s ease-in-out .1s, margin-left .3s ease-in-out .1s, margin-right .3s ease-in-out .1s');
	}
	
	nav > div > ul li a {
		.trans(~'background-color .3s ease-in-out, color .3s ease-in-out, border-color .3s ease-in-out');
	}
	
	nav > div > ul li a .nav_subtitle {
		.trans(~'color .3s ease-in-out');
	}
	
	.header_mid .search_field {
		.trans(~'opacity .3s ease-in-out, visibility .3s ease-in-out');
	}
	
	.header_top .header_top_but {
		.trans(~'color .3s ease-in-out, border-color .3s ease-in-out');
	}
	
	.cmsmasters_toggles .cmsmasters_toggle_plus span {
		.trans(~'background-color .3s ease-in-out, height .1s ease-in-out');
	}
	
	.cmsmasters_hover_slider_thumbs > li img {
		.trans(~'opacity .4s ease-in-out, width .3s ease-in-out, left .3s ease-in-out, right .3s ease-in-out');
	}
	
	.cmsmasters_hover_slider_items > li {
		.trans(~'opacity .5s ease-in-out, visibility .5s ease-in-out');
	}
	
	.cmsmasters_post_timeline .cmsmasters_post_date:before, 
	.cmsmasters_profile_horizontal .profile_inner:before,
	.cmsmasters_project_grid .project_outer .project_inner:before,
	.cmsmasters_slider_project .cmsmasters_slider_project_outer .cmsmasters_slider_project_inner:before, 
	ul.navigation ul ul li:first-child:before, 
	.cmsmasters_hover_slider_thumbs > li a {
		.trans(border-color .3s ease-in-out);
	}
	
	.cmsmasters_button, 
	.cmsmasters_button.cmsmasters_but_clear_styles:before, 
	.cmsmasters_button.cmsmasters_but_clear_styles:after {
		.trans(~'all .3s ease-in-out');
	}
	
	.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="checkbox"] + span.wpcf7-list-item-label:after,
	#fbuilder input[type="checkbox"] + .field_before:after, 
	#fbuilder input[type="radio"] + .field_before:after, 
	.cmsmasters-form-builder .check_parent input[type="checkbox"] + label:after,
	.wpcf7 form.wpcf7-form span.wpcf7-list-item input[type="radio"] + span.wpcf7-list-item-label:after, 
	.cmsmasters-form-builder .check_parent input[type="radio"] + label:after {
		.trans(~'all .2s ease-out');
	}
	
	.cmsmasters_icon_box.cmsmasters_icon_top:before,
	.cmsmasters_icon_box.cmsmasters_icon_box_top:before,
	.cmsmasters_icon_box.cmsmasters_icon_box_left_top:before,
	.cmsmasters_icon_box.cmsmasters_icon_box_left:before,
	.cmsmasters_icon_box.cmsmasters_icon_heading_left h1:before,
	.cmsmasters_icon_box.cmsmasters_icon_heading_left h2:before,
	.cmsmasters_icon_box.cmsmasters_icon_heading_left h3:before, 
	.cmsmasters_icon_box.cmsmasters_icon_heading_left h4:before, 
	.cmsmasters_icon_box.cmsmasters_icon_heading_left h5:before, 
	.cmsmasters_icon_box.cmsmasters_icon_heading_left h6:before {
		.trans(~'all 1s cubic-bezier(.55, .3, .5, 1.65)');
	}
	
	.cmsmasters_icon_list_items.cmsmasters_icon_list_type_block .cmsmasters_icon_list_item:before {
		.trans(~'opacity 5s ease-in-out, height .8s ease-in');
	}
	
	.cmsmasters_icon_list_items.cmsmasters_icon_list_type_block .cmsmasters_icon_list_item .cmsmasters_icon_list_icon, 
	.cmsmasters_icon_list_items.cmsmasters_icon_list_type_block .cmsmasters_icon_list_item .cmsmasters_icon_list_icon:after {
		.trans(~'all .5s ease-in-out');
	}
	
	.cmsmasters_icon_list_items.cmsmasters_icon_list_type_block .cmsmasters_icon_list_item .cmsmasters_icon_list_icon:before {
		.trans(~'color .4s ease-in-out, font-size .4s ease-in-out, line-height .4s ease-in');
	}
	
	.cmsmasters_clients_item img, 
	.cmsmasters_hover_slider .cmsmasters_hover_slider_thumbs a {
		.trans(~'all .5s ease-in-out');
	}
	
	.cmsmasters_profile .profile img {
		.trans(~'all .7s cubic-bezier(.55, .3, .5, 1.65)');
	}
	
	.cmsmasters_profile .profile .pl_social_list {
		.trans(~'opacity .5s ease-out, margin .5s ease-out');
	}
	
	.cmsmasters_project_grid figure {
		.trans(~'opacity .7s ease-in-out');
	}
	
	.cmsmasters_stats.stats_mode_bars.stats_type_horizontal.shortcode_animated .cmsmasters_stat_inner {
		-webkit-animation:bar_move 2s ease-in;
		-moz-animation:bar_move 2s ease-in;
		-ms-animation:bar_move 2s ease-in;
		-o-animation:bar_move 2s ease-in;
		animation:bar_move 2s ease-in;
	}
	
	.cmsmasters_stats.stats_mode_bars.stats_type_vertical.shortcode_animated .cmsmasters_stat_inner {
		-webkit-animation:bar_up 2s ease-in;
		-moz-animation:bar_up 2s ease-in;
		-ms-animation:bar_up 2s ease-in;
		-o-animation:bar_up 2s ease-in;
		animation:bar_up 2s ease-in;
	}
	
	.cmsmasters_stats.stats_mode_bars.stats_type_horizontal.shortcode_animated .cmsmasters_stat_title:before,
	.cmsmasters_stats.stats_mode_bars.shortcode_animated .cmsmasters_stat_inner .cmsmasters_stat_title {
		-webkit-animation:bar_title 2.2s ease-out;
		-moz-animation:bar_title 2.2s ease-out;
		-ms-animation:bar_title 2.2s ease-out;
		-o-animation:bar_title 2.2s ease-out;
		animation:bar_title 2.2s ease-out;
	}
	
	.cmsmasters_stats.stats_mode_bars.stats_type_vertical.shortcode_animated .cmsmasters_stat_inner:before {
		-webkit-animation:bar_icon 2.2s ease-out;
		-moz-animation:bar_icon 2.2s ease-out;
		-ms-animation:bar_icon 2.2s ease-out;
		-o-animation:bar_icon 2.2s ease-out;
		animation:bar_icon 2.2s ease-out;
	}
	
	.cmsmasters_stats.stats_mode_bars.shortcode_animated .cmsmasters_stat_counter_wrap {
		-webkit-animation:bar_inner 1s ease-in-out;
		-moz-animation:bar_inner 1s ease-in-out;
		-ms-animation:bar_inner 1s ease-in-out;
		-o-animation:bar_inner 1s ease-in-out;
		animation:bar_inner 1s ease-in-out;
	}
	
	
	@-webkit-keyframes autofill {
		to {
			color:inherit;
			background:transparent;
		}
	}

	@-webkit-keyframes bar_move {
		from {
			width: 0;
		}
		
		to {
			width: 100%;
		}
	}
	
	@-moz-keyframes bar_move {
		from {
			width: 0;
		}
		
		to {
			width: 100%;
		}
	}
	
	@-ms-keyframes bar_move {
		from {
			width: 0;
		}
		
		to {
			width: 100%;
		}
	}
	
	@-o-keyframes bar_move {
		from {
			width: 0;
		}
		
		to {
			width: 100%;
		}
	}
	
	@keyframes bar_move {
		from {
			width: 0;
		}
		
		to {
			width: 100%;
		}
	}
	
	
	@-webkit-keyframes bar_up {
		from {
			-webkit-transform: translate(0, 100%);
		}
		
		to {
			-webkit-transform: translate(0, 0%);
		}
	}
	
	@-moz-keyframes bar_up {
		from {
			-moz-transform: translate(0, 100%);
		}
		
		to {
			-moz-transform: translate(0, 0%);
		}
	}
	
	@-ms-keyframes bar_up {
		from {
			-ms-transform: translate(0, 100%);
		}
		
		to {
			-ms-transform: translate(0, 0%);
		}
	}
	
	@-o-keyframes bar_up {
		from {
			-o-transform: translate(0, 100%);
		}
		
		to {
			-o-transform: translate(0, 0%);
		}
	}
	@keyframes bar_up {
		from {
			transform: translate(0, 100%);
		}
		
		to {
			transform: translate(0, 0%);
		}
	}
	
	
	@-webkit-keyframes bar_title {
		from {
			margin-left: -200px;
		}
		
		to {
			margin-left: 0px;
		}
	}
	
	@-moz-keyframes bar_title {
		from {
			margin-left: -200px;
		}
		
		to {
			margin-left: 0px;
		}
	}
	
	@-ms-keyframes bar_title {
		from {
			margin-left: -200px;
		}
		
		to {
			margin-left: 0px;
		}
	}
	
	@-o-keyframes bar_title {
		from {
			margin-left: -200px;
		}
		
		to {
			margin-left: 0px;
		}
	}
	
	@keyframes bar_title {
		from {
			margin-left: -200px;
		}
		
		to {
			margin-left: 0px;
		}
	}
	
	
	@-webkit-keyframes bar_icon {
		from {
			margin-right: -200px;
		}
		
		to {
			margin-right: 0px;
		}
	}
	
	@-moz-keyframes bar_icon {
		from {
			margin-right: -200px;
		}
		
		to {
			margin-right: 0px;
		}
	}
	
	@-ms-keyframes bar_icon {
		from {
			margin-right: -200px;
		}
		
		to {
			margin-right: 0px;
		}
	}
	
	@-o-keyframes bar_icon {
		from {
			margin-right: -200px;
		}
		
		to {
			margin-right: 0px;
		}
	}
	
	@keyframes bar_icon {
		from {
			margin-right: -200px;
		}
		
		to {
			margin-right: 0px;
		}
	}
	
	
	@-webkit-keyframes bar_inner {
		from {
			opacity: 0;
		}
		
		to {
			opacity: 1;
		}
	}
	
	@-moz-keyframes bar_inner {
		from {
			opacity: 0;
		}
		
		to {
			opacity: 1;
		}
	}
	
	@-ms-keyframes bar_inner {
		from {
			opacity: 0;
		}
		
		to {
			opacity: 1;
		}
	}
	
	@-o-keyframes bar_inner {
		from {
			opacity: 0;
		}
		
		to {
			opacity: 1;
		}
	}
	
	@keyframes bar_inner {
		from {
			opacity: 0;
		}
		
		to {
			opacity: 1;
		}
	}
}

